import React, { useState, useEffect } from 'react';
import { Bitcoin, Wallet, RefreshCw, AlertCircle } from 'lucide-react';
import { usePayment } from '../../context/PaymentContext';
import { getCryptoPrice, getSavedCryptoAddress, validateTransactionHash } from '../../utils/cryptoUtils';
import { db } from '../../config/firebase';
import { collection, query, where, getDocs, addDoc, doc, getDoc } from 'firebase/firestore';
import { formatPrice } from '../../utils/currency';
import { validateShippingDetails } from '../../utils/shippingValidation';
import QRCode from 'qrcode.react';
import PaymentVerification from './PaymentVerification';
import { Order } from '../../types/order';
import { createOrder } from '../../utils/orderUtils';
import { CartItem } from '../../types/product';

interface CryptoPaymentProps {
  amount: number;
  currency: string;
  surchargeRate?: number;
  shippingDetails: {
    firstName: string;
    lastName: string;
    email: string;
    telephone: string;
    address: string;
    address2?: string;
    address3?: string;
    postcode: string;
    country: string;
  };
  items: CartItem[];
  taxRate: number;
}

export default function CryptoPayment({ amount, currency, surchargeRate = 0.5, shippingDetails, items, taxRate }: CryptoPaymentProps) {
  const { setPaymentMethod, processing } = usePayment();
  const [selectedCrypto, setSelectedCrypto] = useState(() => {
    // Map currency to default crypto
    switch (currency) {
      case 'USD': return 'BTC'; // Bitcoin for USD
      case 'EUR': return 'ETH'; // Ethereum for EUR
      case 'GBP': return 'XRP'; // Ripple for GBP
      default: return 'BTC'; // Default to Bitcoin
    }
  });
  const [cryptoAmount, setCryptoAmount] = useState<number | null>(null);
  const [walletAddress, setWalletAddress] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [order, setOrder] = useState<Order | null>(null);
  const [showVerification, setShowVerification] = useState(false);
  const MAX_RETRIES = 3;

  const cryptoOptions = [
    { id: 'BTC', name: 'Bitcoin' },
    { id: 'ETH', name: 'Ethereum' },
    { id: 'XRP', name: 'Ripple' }
  ];

  const surchargeAmount = (amount * surchargeRate) / 100;
  const totalWithSurcharge = amount + surchargeAmount;

  // Map currencies to their preferred crypto
  const currencyToCrypto = {
    USD: { crypto: 'BTC', name: 'Bitcoin' },
    EUR: { crypto: 'ETH', name: 'Ethereum' },
    GBP: { crypto: 'XRP', name: 'Ripple' }
  };

  // Auto-select crypto based on currency
  useEffect(() => {
    const preferredCrypto = currencyToCrypto[currency]?.crypto || 'BTC';
    setSelectedCrypto(preferredCrypto);
  }, [currency]);

  useEffect(() => {
    const fetchCryptoPrice = async () => {
      try {
        if (isProcessing) return;
        
        setLoading(true);
        setError(null);
        
        setPaymentMethod({ type: 'crypto', currency: selectedCrypto, processing: true });
        const price = await getCryptoPrice(selectedCrypto, currency);
        if (!price || price <= 0) {
          throw new Error('Invalid exchange rate received');
        }
        
        const savedAddress = await getSavedCryptoAddress(selectedCrypto);
        if (!savedAddress) {
          throw new Error('No wallet address configured for ' + selectedCrypto);
        }
        
        setCryptoAmount(totalWithSurcharge / price);
        setWalletAddress(savedAddress);
        setPaymentMethod({ type: 'crypto', currency: selectedCrypto, processing: false });
        setRetryCount(0);
      } catch (err) {
        const errorMessage = err instanceof Error ? err.message : 'Failed to fetch crypto price';
        setError(errorMessage);
        console.error('Crypto payment error:', err);
        setPaymentMethod({ type: 'crypto', processing: false });
        
        // Implement retry logic
        if (retryCount < MAX_RETRIES) {
          setRetryCount(prev => prev + 1);
          setTimeout(() => {
            fetchCryptoPrice();
          }, 2000 * Math.pow(2, retryCount)); // Exponential backoff
        }
      } finally {
        setLoading(false);
        setIsProcessing(false);
      }
    };

    fetchCryptoPrice();
  }, [selectedCrypto, amount, currency, isProcessing]);

  const handleCryptoSelect = (crypto: string) => {
    if (isProcessing) return;
    setIsProcessing(true);
    setError(null);
    setSelectedCrypto(crypto);
    setPaymentMethod({
      type: 'crypto',
      currency: crypto,
      processing: false
    });
  };
  
  const handlePaymentStart = async () => {
    setIsProcessing(true);
    setError(null);
    
    // Validate shipping details first
    const shippingErrors = validateShippingDetails(shippingDetails);
    if (Object.keys(shippingErrors).length > 0) {
      setError('Please complete shipping details');
      setIsProcessing(false);
      return;
    }
    setPaymentMethod({ type: 'crypto', currency: selectedCrypto, processing: true });

    if (!shippingDetails?.firstName || !shippingDetails?.lastName) {
      setError('Please provide your name');
      setIsProcessing(false);
      setPaymentMethod({ type: 'crypto', currency: selectedCrypto, processing: false });
      return;
    }

    if (!shippingDetails?.email) {
      setError('Please provide your email address');
      setIsProcessing(false);
      setPaymentMethod({ type: 'crypto', currency: selectedCrypto, processing: false });
      return;
    }

    try {
      if (!cryptoAmount || cryptoAmount <= 0) {
        throw new Error('Invalid cryptocurrency amount - please try again');
      }

      if (!walletAddress) {
        throw new Error('No wallet address available - please try again');
      }

      const newOrder = await createOrder({
        items,
        total: totalWithSurcharge, // Include crypto surcharge
        currency,
        customerEmail: shippingDetails.email,
        customerName: `${shippingDetails.firstName} ${shippingDetails.lastName}`,
        shippingAddress: shippingDetails,
        paymentMethod: {
          type: 'crypto',
          currency: selectedCrypto
        },
        taxRate
      });
      
      if (!newOrder?.id) {
        throw new Error('Unable to create order - please try again');
      }

      // Fetch the full order details
      const orderRef = doc(db, 'orders', order.id);
      const orderSnap = await getDoc(orderRef);

      if (!orderSnap.exists()) {
        throw new Error('Order creation failed - please try again');
        return;
      }

      setShowVerification(true);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'Failed to process payment';
      console.error('Payment start error:', errorMessage);
      setError(errorMessage);
      setOrder(null);
      setShowVerification(false);
    } finally {
      setIsProcessing(false);
      setPaymentMethod({ type: 'crypto', currency: selectedCrypto, processing: false });
    }
  };
  const handleRetry = () => {
    setError(null);
    setLoading(true);
    setRetryCount(0);
    setIsProcessing(false);
    setPaymentMethod({ type: 'crypto', currency: selectedCrypto, processing: false });
    window.location.reload();
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-8">
        <RefreshCw className="h-8 w-8 animate-spin text-yellow-400" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8">
        <p className="text-red-600">{error}</p>
        <button
          onClick={handleRetry}
          className="mt-4 px-4 py-2 bg-yellow-400 hover:bg-yellow-500 rounded-lg"
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="grid grid-cols-3 gap-4">
        {Object.entries(currencyToCrypto).map(([curr, { crypto, name }]) => (
          <button
            key={crypto}
            onClick={() => handleCryptoSelect(crypto)}
            disabled={isProcessing}
            className={`p-4 rounded-lg border-2 transition-colors ${
              selectedCrypto === crypto
                ? 'border-yellow-400 bg-yellow-50'
                : 'border-gray-200 hover:border-gray-300'
            }`}
          >
            <Bitcoin className="h-6 w-6 mx-auto mb-2" />
            <div className="text-sm font-medium">{name}</div>
            <div className="text-xs text-gray-500">for {curr}</div>
          </button>
        ))}
      </div>

      <div className="bg-gray-50 p-6 rounded-lg">
        <div className="flex items-center justify-between mb-4">
          <span className="text-sm text-gray-600">Amount to Pay:</span>
          <div className="text-right space-y-1">
            <div className="font-bold text-lg text-green-600">
              {cryptoAmount?.toFixed(8)} {selectedCrypto}
            </div>
            <div className="text-sm text-gray-600">
              {formatPrice(amount, currency)} + {surchargeRate}% fee ({formatPrice(surchargeAmount, currency)})
            </div>
            <div className="text-xs text-gray-500 mt-1">
              Exchange Rate: 1 {selectedCrypto} = {formatPrice(amount / (cryptoAmount || 1), currency)}
            </div>
          </div>
        </div>

        <div className="flex justify-center mb-4">
          <QRCode
            value={walletAddress}
            size={200}
            level="H"
            includeMargin={true}
          />
        </div>

        <div className="flex items-center space-x-2 bg-white p-3 rounded-lg border">
          <Wallet className="h-5 w-5 text-gray-400" />
          <div className="flex-1">
            {error && (
              <div className="mb-4 p-3 bg-red-50 text-red-700 rounded-lg flex items-center space-x-2">
                <AlertCircle className="h-5 w-5" />
                <span>{error}</span>
              </div>
            )}
            <input
              type="text"
              value={typeof walletAddress === 'string' ? walletAddress : walletAddress.address}
              readOnly
              className="w-full bg-transparent border-none focus:outline-none text-sm"
            />
            {selectedCrypto === 'XRP' && typeof walletAddress === 'object' && walletAddress.tag && (
              <div className="mt-2 text-sm text-gray-600">
                Destination Tag: {walletAddress.tag}
              </div>
            )}
          </div>
          <button
            onClick={() => {
              const textToCopy = typeof walletAddress === 'string' 
                ? walletAddress 
                : `Address: ${walletAddress.address}${walletAddress.tag ? `\nDestination Tag: ${walletAddress.tag}` : ''}`;
              navigator.clipboard.writeText(textToCopy);
            }}
            className="text-sm text-blue-600 hover:text-blue-800"
          >
            Copy
          </button>
        </div>

        <div className="mt-4 text-sm text-gray-500">
          <p>Send exactly the specified amount to complete your payment.</p>
          <p>Transaction typically confirms within 10-60 minutes.</p>
        </div>
      </div>

      <div className="text-center text-sm text-gray-600">
        <p>Exchange rate updates every 5 minutes</p>
        <p>Network fees are included in the displayed amount</p>
        
        <button
          onClick={handlePaymentStart}
          className="w-full mt-4 bg-yellow-400 hover:bg-yellow-500 text-black font-semibold py-2 px-4 rounded-lg"
          disabled={isProcessing || !cryptoAmount || !walletAddress}
        >
          {isProcessing ? 'Processing...' : "I've Sent the Payment"}
        </button>

        {showVerification && order && (
          <div className="mt-6 border-t pt-6">
            <PaymentVerification
              order={order}
              onVerificationSubmitted={() => {
                // Hide verification form after submission
                setShowVerification(false);
              }}
              selectedCrypto={selectedCrypto}
              cryptoAmount={cryptoAmount || 0}
            />
          </div>
        )}
        
      </div>
    </div>
  );
}
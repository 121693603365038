import { ThemeConfig } from '../types/theme';

export const defaultTheme: ThemeConfig = {
  id: 'default',
  name: 'Default Theme',
  description: 'Standard modern e-commerce theme',
  colors: {
    primary: '#EAB308', // yellow-500
    secondary: '#1E40AF', // blue-800
    accent: '#4F46E5', // indigo-600
    background: '#F3F4F6', // gray-100
    text: '#111827', // gray-900
    border: '#E5E7EB', // gray-200
  },
  isActive: true,
  lastModified: new Date().toISOString(),
  modifiedBy: 'system'
};
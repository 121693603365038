import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
//import { supabase } from '../config/supabase';
import { Product } from '../types/product';
import { UserProfile } from '../types/auth';

interface ImportResult {
  success: boolean;
  message: string;
  errors?: string[];
  totalProcessed?: number;
  successCount?: number;
  failureCount?: number;
}

const EXPORT_COLUMNS = [
  'id',
  'title',
  'description',
  'category',
  'price',
  'status',
  'rating',
  'stock_quantity',
  'stock_threshold',
  'stock_status',
  'shipping_cost',
  'sku',
  'metadata',
  'created_at',
  'updated_at',
  'created_by',
  'updated_by'
];

export async function exportProducts(userProfile: UserProfile | null): Promise<void> {
  try {
    const { data: products, error } = await supabase
      .from('products')
      .select('*')
      .order('title');

    if (error) throw error;

    const exportData = products.map(product => ({
      id: product.id,
      title: product.title,
      description: product.description,
      category: product.category,
      price: product.price,
      status: product.status,
      rating: product.rating || 0,
      stock_quantity: product.stock_quantity,
      stock_threshold: product.stock_threshold,
      stock_status: product.stock_status,
      shipping_cost: product.shipping_cost || 0,
      sku: product.sku || '',
      metadata: JSON.stringify(product.metadata || {}),
      created_at: product.created_at,
      updated_at: product.updated_at,
      created_by: product.created_by,
      updated_by: product.updated_by || userProfile?.email || 'system'
    }));

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData, { header: EXPORT_COLUMNS });
    XLSX.utils.book_append_sheet(wb, ws, 'Products');

    // Add export details in footer
    const footerData = [
      [''],
      ['Export Details'],
      [`Generated: ${new Date().toLocaleString()}`],
      [`Total Products: ${products.length}`],
      [`Generated By: ${userProfile?.email || 'system'}`]
    ];

    XLSX.utils.sheet_add_aoa(ws, footerData, { origin: -1 });

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `products_export_${new Date().toISOString().slice(0,10)}.xlsx`);
  } catch (error) {
    console.error('Export error:', error);
    throw error;
  }
}

export async function importProducts(file: File): Promise<ImportResult> {
  try {
    // First, get current user session
    const { data: { session } } = await supabase.auth.getSession();
    if (!session?.user) {
      throw new Error('Authentication required');
    }

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, { type: 'array' });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const rawProducts = XLSX.utils.sheet_to_json(worksheet);
    
    if (!Array.isArray(rawProducts) || rawProducts.length === 0) {
      return {
        success: false,
        message: 'No valid products found in import file',
        totalProcessed: 0,
        successCount: 0,
        failureCount: 0
      };
    }

    let successCount = 0;
    let failureCount = 0;
    const errors: string[] = [];
    const timestamp = new Date().toISOString();

    // Process products in batches of 100
    const BATCH_SIZE = 100;
    for (let i = 0; i < rawProducts.length; i += BATCH_SIZE) {
      const batch = rawProducts.slice(i, i + BATCH_SIZE);
      const productsToUpsert = batch.map((raw: any) => ({
        title: String(raw.title || ''),
        description: String(raw.description || ''),
        category: String(raw.category || ''),
        price: Number(raw.price) || 0,
        status: String(raw.status || 'active'),
        stock_quantity: Number(raw.stock_quantity) || 0,
        stock_threshold: Number(raw.stock_threshold) || 5,
        stock_status: calculateStockStatus(
          Number(raw.stock_quantity) || 0,
          Number(raw.stock_threshold) || 5
        ),
        shipping_cost: Number(raw.shipping_cost) || 0,
        sku: String(raw.sku || ''),
        metadata: tryParseJSON(raw.metadata, {}),
        updated_at: timestamp,
        updated_by: session.user.id
      }));

      const { error: upsertError } = await supabase
        .from('products')
        .upsert(productsToUpsert, {
          onConflict: 'sku',
          ignoreDuplicates: false
        });

      if (upsertError) {
        console.error('Batch import error:', upsertError);
        failureCount += batch.length;
        errors.push(`Failed to import batch: ${upsertError.message}`);
      } else {
        successCount += batch.length;
      }
    }

    return {
      success: failureCount === 0,
      message: `Imported ${successCount} products (${failureCount} failed)`,
      errors: errors.length > 0 ? errors : undefined,
      totalProcessed: rawProducts.length,
      successCount,
      failureCount
    };
  } catch (error) {
    console.error('Import error:', error);
    return {
      success: false,
      message: `Import failed: ${error instanceof Error ? error.message : 'Unknown error'}`,
      errors: [error instanceof Error ? error.message : 'Unknown error'],
      totalProcessed: 0,
      successCount: 0,
      failureCount: 0
    };
  }
}

function tryParseJSON(value: any, defaultValue: any) {
  if (!value) return defaultValue;
  try {
    if (typeof value === 'string') {
      return JSON.parse(value);
    }
    return value;
  } catch {
    return defaultValue;
  }
}

function calculateStockStatus(quantity: number, threshold: number): 'in-stock' | 'low-stock' | 'out-of-stock' {
  if (quantity <= 0) {
    return 'out-of-stock';
  }
  if (quantity <= threshold) {
    return 'low-stock';
  }
  return 'in-stock';
}
import { ref, listAll } from 'firebase/storage';
import { storage, auth } from '../config/firebase';
import { verifyAdminAccess } from './auth/permissions';
import { quotaManager } from './auth/quotaManager';

export async function testStorageConnection(): Promise<{
  success: boolean;
  message: string;
}> {
  try {
    console.log('Starting storage connection test...');
    
    // Check quota first
    const canProceed = await quotaManager.checkQuota('storage_test');
    if (!canProceed) {
      const cooldown = Math.ceil(quotaManager.getRemainingCooldown('storage_test') / 60000);
      return {
        success: false,
        message: `Rate limit exceeded. Please wait ${cooldown} minutes.`
      };
    }

    // Check network connectivity first
    if (!navigator.onLine) {
      return {
        success: false,
        message: 'No internet connection'
      };
    }

    // Check auth state
    const user = auth.currentUser;
    if (!user) {
      return {
        success: false,
        message: 'Please sign in to access storage'
      };
    }

    // Force token refresh with timeout
    try {
      const tokenPromise = user.getIdToken(true);
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Token refresh timeout')), 5000);
      });
      await Promise.race([tokenPromise, timeoutPromise]);
    } catch (error) {
      return {
        success: false,
        message: 'Failed to refresh authentication'
      };
    }

    // Verify admin access
    const hasAccess = await verifyAdminAccess();
    if (!hasAccess) {
      return {
        success: false,
        message: 'Admin access required - please sign in as admin'
      };
    }

    // Test storage access by listing root directory
    const rootRef = ref(storage);
    try {
      await Promise.race([
        listAll(rootRef),
        new Promise((_, reject) => 
          setTimeout(() => reject(new Error('Storage operation timeout')), 10000)
        )
      ]);
    } catch (error) {
      if (error.message === 'Storage operation timeout') {
        return {
          success: false,
          message: 'Storage operation timed out - please try again'
        };
      }
      throw error;
    }

    // Verify required folders exist
    const requiredFolders = ['theme-headers', 'theme-backgrounds', 'products', 'decorative'];
    const missingFolders = [];

    for (const folder of requiredFolders) {
      try {
        const folderRef = ref(storage, folder);
        await Promise.race([
          listAll(folderRef),
          new Promise((_, reject) => 
            setTimeout(() => reject(new Error('Folder check timeout')), 5000)
          )
        ]);
      } catch {
        missingFolders.push(folder);
      }
    }

    if (missingFolders.length > 0) {
      return {
        success: false,
        message: `Missing required folders: ${missingFolders.join(', ')}`
      };
    }

    return {
      success: true,
      message: 'Storage connection successful'
    };
  } catch (error) {
    console.error('Storage connection error:', error);
    
    if (error?.code === 'auth/quota-exceeded') {
      quotaManager.handleQuotaExceeded('storage_test');
      const cooldown = Math.ceil(quotaManager.getRemainingCooldown('storage_test') / 60000);
      return {
        success: false,
        message: `Rate limit exceeded. Please wait ${cooldown} minutes.`
      };
    }
    
    if (error instanceof Error) {
      if (error.message?.includes('Failed to fetch')) {
        return {
          success: false,
          message: 'Network connection failed'
        };
      }

      if (error.message?.includes('not authorized')) {
        return {
          success: false,
          message: 'Storage access denied - please check permissions'
        };
      }
    }

    return {
      success: false,
      message: error instanceof Error ? error.message : 'Connection failed'
    };
  }
}
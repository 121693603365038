import { Product } from '../../../types/product';
import { removeStopWords, sanitizeText } from '../utils/textUtils';

const COMMON_WORDS = new Set(['the', 'and', 'or', 'in', 'on', 'at', 'to', 'for', 'with']);

export function generateKeywords(product: Product): string[] {
  try {
    const keywords = new Set<string>();

    // Process title words
    const titleWords = removeStopWords(product.title)
      .toLowerCase()
      .split(' ')
      .filter(word => !COMMON_WORDS.has(word) && word.length > 2);
    titleWords.forEach(word => keywords.add(word));

    // Process category
    const categoryWords = removeStopWords(product.category)
      .toLowerCase()
      .split(' ')
      .filter(word => !COMMON_WORDS.has(word) && word.length > 2);
    categoryWords.forEach(word => keywords.add(word));

    // Process tags
    product.tags?.forEach(tag => {
      const tagWords = removeStopWords(tag)
        .toLowerCase()
        .split(' ')
        .filter(word => !COMMON_WORDS.has(word) && word.length > 2);
      tagWords.forEach(word => keywords.add(word));
    });

    // Process specifications
    Object.entries(product.specifications || {}).forEach(([key, value]) => {
      const cleanKey = removeStopWords(key).toLowerCase();
      if (cleanKey.length > 2) keywords.add(cleanKey);

      if (typeof value === 'string') {
        const valueWords = removeStopWords(value)
          .toLowerCase()
          .split(' ')
          .filter(word => !COMMON_WORDS.has(word) && word.length > 2);
        valueWords.forEach(word => keywords.add(word));
      }
    });

    // Sort and filter keywords
    return Array.from(keywords)
      .filter(keyword => keyword.length >= 3)
      .sort((a, b) => {
        const aInTitle = product.title.toLowerCase().includes(a);
        const bInTitle = product.title.toLowerCase().includes(b);
        if (aInTitle !== bInTitle) return bInTitle ? 1 : -1;
        return b.length - a.length;
      })
      .slice(0, 20);
  } catch (error) {
    throw new Error('Failed to generate SEO keywords: ' + (error instanceof Error ? error.message : 'Unknown error'));
  }
}
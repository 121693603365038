import React, { useState } from 'react';
import { AlertCircle, Database, RefreshCw, Code } from 'lucide-react';
import { useFirebaseConfig } from '../../hooks/useFirebaseConfig';

export default function DatabaseConfig() {
  const { status, error, testConnection, saveConfiguration, defaultConfig } = useFirebaseConfig();
  const [configCode, setConfigCode] = useState(`// Firebase Configuration
const firebaseConfig = {
  apiKey: "${defaultConfig.apiKey}",
  authDomain: "${defaultConfig.authDomain}",
  projectId: "${defaultConfig.projectId}",
  storageBucket: "${defaultConfig.storageBucket}",
  messagingSenderId: "${defaultConfig.messagingSenderId}",
  appId: "${defaultConfig.appId}",
  measurementId: "${defaultConfig.measurementId}"
};`);

  const handleTestConnection = async () => {
    await testConnection();
  };

  const handleSaveConfiguration = async () => {
    try {
      const configMatch = configCode.match(/const\s+firebaseConfig\s*=\s*({[\s\S]*?});/);
      if (!configMatch) {
        throw new Error('Invalid configuration format');
      }
      
      const configObject = eval('(' + configMatch[1] + ')');
      if (!configObject.apiKey || !configObject.projectId) {
        throw new Error('Missing required configuration fields');
      }

      await saveConfiguration();
      alert('Configuration saved successfully. Please refresh the page for changes to take effect.');
    } catch (err) {
      alert('Error saving configuration: ' + (err instanceof Error ? err.message : 'Invalid format'));
    }
  };

  return (
    <div className="max-w-4xl">
      <div className="flex items-center space-x-2 mb-6">
        <Database className="h-6 w-6 text-gray-700" />
        <div>
          <h2 className="text-xl font-bold">Firebase Configuration</h2>
          <p className="text-sm text-gray-600">Paste your Firebase configuration code below</p>
        </div>
      </div>

      <div className="bg-white rounded-lg border p-6 space-y-6">
        <div>
          <div className="flex items-center space-x-2 mb-2">
            <Code className="h-5 w-5 text-gray-500" />
            <label className="block text-sm font-medium text-gray-700">
              Configuration Code
            </label>
          </div>
          <textarea
            value={configCode}
            onChange={(e) => setConfigCode(e.target.value)}
            className="w-full h-64 font-mono text-sm p-4 border rounded-lg focus:ring-2 ring-yellow-400"
            placeholder="Paste your Firebase configuration here..."
          />
        </div>

        <div className="flex space-x-4">
          <button
            onClick={handleTestConnection}
            disabled={status === 'testing'}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
              status === 'testing'
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-yellow-400 hover:bg-yellow-500'
            }`}
          >
            <RefreshCw className={`h-5 w-5 ${
              status === 'testing' ? 'animate-spin' : ''
            }`} />
            <span>
              {status === 'testing' ? 'Testing...' : 'Test Connection'}
            </span>
          </button>

          <button
            onClick={handleSaveConfiguration}
            disabled={status !== 'success'}
            className={`px-4 py-2 rounded-lg ${
              status !== 'success'
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-green-500 hover:bg-green-600 text-white'
            }`}
          >
            Save Configuration
          </button>
        </div>
      </div>

      {status === 'success' && (
        <div className="bg-green-50 text-green-800 p-4 rounded-lg flex items-start space-x-2">
          <AlertCircle className="h-5 w-5 mt-0.5" />
          <span>Connection successful! Firebase is accessible and responding.</span>
        </div>
      )}

      {status === 'error' && (
        <div className="bg-red-50 text-red-800 p-4 rounded-lg flex items-start space-x-2">
          <AlertCircle className="h-5 w-5 mt-0.5" />
          <span>{error}</span>
        </div>
      )}

      <div className="border-t pt-4">
        <h3 className="font-medium mb-2">Connection Details</h3>
        <div className="grid grid-cols-2 gap-4 text-sm">
          <div>
            <span className="text-gray-500">Status:</span>
            <span className={`ml-2 ${
              status === 'success' ? 'text-green-600' : 'text-gray-900'
            }`}>
              {status === 'success' ? 'Connected' : 'Not Connected'}
            </span>
          </div>
          <div>
            <span className="text-gray-500">Last Tested:</span>
            <span className="ml-2 text-gray-900">
              {status === 'success' 
                ? new Date().toLocaleString()
                : 'Never'
              }
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
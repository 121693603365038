import { db } from '../config/firebase';
import { db } from '../config/firebase';
import { collection, addDoc, updateDoc, doc, increment } from 'firebase/firestore';
import { CartItem } from '../types/product';
import { shouldApplyFreeShipping } from './currency';
import { getSiteSettings } from './settings';

export interface CreateOrderParams {
  items: CartItem[];
  total: number;
  currency: string;
  customerEmail: string;
  customerName: string;
  shippingAddress: {
    firstName: string;
    lastName: string;
    address: string;
    postcode: string;
    country: string;
    telephone: string;
    address2?: string;
    address3?: string;
  };
  paymentMethod: {
    type: 'credit-card' | 'crypto';
    currency?: string;
  };
  taxRate: number;
}

export async function createOrder(params: CreateOrderParams): Promise<{ id: string }> {
  if (!params.items?.length) {
    throw new Error('No items in cart');
  }

  if (!params.customerEmail || !params.customerName) {
    throw new Error('Missing customer information');
  }

  if (!params.shippingAddress || !params.shippingAddress.address) {
    throw new Error('Missing shipping address');
  }

  const orderNumber = generateOrderNumber();
  const timestamp = new Date().toISOString();

  // Calculate subtotal for free shipping check
  const subtotal = params.items.reduce((total, item) => 
    total + (item.price * item.quantity), 0);

  if (subtotal <= 0) {
    throw new Error('Invalid order total');
  }

  // Get shipping total - apply free shipping if eligible
  const settings = await getSiteSettings();
  const freeShippingThreshold = settings?.shipping?.freeThreshold ?? 100;
  
  const qualifiesForFreeShipping = shouldApplyFreeShipping(subtotal, freeShippingThreshold);
  const shippingTotal = qualifiesForFreeShipping ? 0 : 
    params.items.reduce((total, item) => 
      total + ((item.shipping_cost || 0) * item.quantity), 0);

  try {
    // Create order document
    const orderRef = await addDoc(collection(db, 'orders'), {
      orderNumber,
      customerEmail: params.customerEmail,
      customerName: params.customerName,
      telephone: params.shippingAddress.telephone,
      status: params.paymentMethod.type === 'crypto' ? 'payment_pending_verification' : 'pending',
      payment: {
        method: params.paymentMethod.type,
        status: params.paymentMethod.type === 'crypto' ? 'pending' : 'completed',
        transactionId: params.paymentMethod.type === 'crypto' ? '' : crypto.randomUUID(),
        amount: params.total,
        currency: params.currency,
        lastUpdated: timestamp,
        ...(params.paymentMethod.type === 'credit-card' && {
          cardLast4: '****',
          cardType: 'Unknown',
          cardholderName: params.customerName
        }),
        ...(params.paymentMethod.type === 'crypto' && {
          cryptoCurrency: params.paymentMethod.currency,
          cryptoAmount: 0,
          exchangeRate: 0
        })
      },
      items: params.items.map(item => ({
        id: crypto.randomUUID(),
        productId: item.id,
        title: item.title,
        price: item.price,
        shippingCost: item.shipping_cost,
        quantity: item.quantity,
        subtotal: item.price * item.quantity
      })),
      subtotal: params.total / (1 + params.taxRate / 100),
      tax: params.total - (params.total / (1 + params.taxRate / 100)),
      shippingCost: shippingTotal,
      total: params.total,
      currency: params.currency,
      shippingAddress: {
        ...params.shippingAddress,
        telephone: params.shippingAddress.telephone || ''
      },
      billingAddress: params.shippingAddress,
      shippingDetails: {
        method: 'standard',
        status: 'pending',
        estimatedDelivery: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString()
      },
      notes: [],
      history: [{
        action: 'Order created',
        timestamp,
        performedBy: params.customerEmail,
        details: `Order created with ${params.paymentMethod.type} payment method`
      }],
      createdAt: timestamp,
      updatedAt: timestamp
    });

    // Update product stock levels
    for (const item of params.items) {
      const productRef = doc(db, 'products', item.id);
      await updateDoc(productRef, {
        'stock.quantity': increment(-item.quantity),
        updatedAt: timestamp
      });
    }

    return { id: orderRef.id };
  } catch (error) {
    console.error('Error creating order:', error);
    throw new Error('Failed to process order - please try again');
  }
}

function generateOrderNumber(): string {
  const timestamp = Date.now().toString().slice(-6);
  const random = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
  return `ORD-${timestamp}${random}`;
}
import { sanitizeText } from '../utils/textUtils';

export function generateDescription(description: string, specs: Record<string, any>): string {
  try {
    let seoDescription = sanitizeText(description);

    const keySpecs = Object.entries(specs)
      .slice(0, 2)
      .map(([key, value]) => `${sanitizeText(key)}: ${sanitizeText(String(value))}`)
      .join(', ');

    if (keySpecs) {
      seoDescription = `${seoDescription} ${keySpecs}`;
    }

    if (seoDescription.length > 160) {
      seoDescription = seoDescription.substring(0, 157) + '...';
    }

    return seoDescription;
  } catch (error) {
    throw new Error('Failed to generate SEO description: ' + (error instanceof Error ? error.message : 'Unknown error'));
  }
}
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Upload, AlertCircle } from 'lucide-react';
import { storage } from '../../config/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../../context/AuthContext';
import { validateImage } from '../../utils/imageValidation';

interface ImageUploaderProps {
  onUploadComplete: (url: string) => void;
  folder?: string;
}

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
const ACCEPTED_TYPES = ['image/jpeg', 'image/png', 'image/webp'];

export default function ImageUploader({ onUploadComplete, folder = 'theme-images' }: ImageUploaderProps) {
  const { userProfile } = useAuth();
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    if (!userProfile) {
      setError('Admin access required for uploading images');
      setUploading(false);
      return;
    }

    if (acceptedFiles.length === 0) {
      setUploading(false);
      return;
    }

    const file = acceptedFiles[0];
    if (!file) return;
    console.log('Starting upload for file:', file.name);

    setUploading(true);
    setError(null);
    setProgress(0);

    if (file.size > MAX_FILE_SIZE) {
      setError('File size must not exceed 5MB');
      setUploading(false);
      return;
    }

    if (!ACCEPTED_TYPES.includes(file.type)) {
      setError('Only JPEG, PNG and WebP images are allowed');
      setUploading(false);
      return;
    }

    try {
      // Add folder to file for validation
      const fileWithMeta = file;
      (fileWithMeta as any).folder = folder;

      // Validate image dimensions
      const validation = await validateImage(fileWithMeta);
      if (!validation.isValid) {
        setError(validation.errors[0]);
        setUploading(false);
        return;
      }

      // Generate safe filename
      const timestamp = Date.now();
      const safeFilename = `${timestamp}_${file.name.replace(/[^a-zA-Z0-9.-]/g, '_')}`;
      const path = `${folder}/${safeFilename}`;

      // Upload file to Firebase Storage
      const storageRef = ref(storage, path);
      await uploadBytes(storageRef, file);
      const url = await getDownloadURL(storageRef);

      onUploadComplete(url);
      setUploading(false);
      setProgress(0);
      setRetryCount(0);

    } catch (error) {
      console.error('Upload error:', error);
      
      if (retryCount < MAX_RETRIES) {
        setTimeout(() => {
          setRetryCount(prev => prev + 1);
          onDrop([file]); // Retry upload
        }, 1000 * Math.pow(2, retryCount)); // Exponential backoff
      } else {
        setError('Failed to upload image after multiple attempts');
        setUploading(false);
      }
    }
  }, [folder, onUploadComplete, retryCount]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
      'image/webp': ['.webp']
    },
    maxSize: MAX_FILE_SIZE,
    multiple: false
  });

  return (
    <div className="space-y-4">
      <div
        {...getRootProps()}
        className={`border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors ${
          isDragActive ? 'border-yellow-400 bg-yellow-50' : 'border-gray-300'
        }`}
      >
        <input {...getInputProps()} />
        <Upload className="mx-auto h-12 w-12 text-gray-400" />
        <p className="mt-2 text-sm text-gray-600">
          {uploading
            ? `Uploading... ${progress}%`
            : 'Drag & drop an image here, or click to select'}
        </p>
        <p className="text-xs text-gray-500 mt-1">
          JPG, PNG, or WebP, max 5MB
        </p>
      </div>

      {error && (
        <div className="flex items-center space-x-2 text-red-600 text-sm">
          <AlertCircle className="h-4 w-4" />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
}
interface ShippingDetails {
  firstName: string;
  lastName: string;
  email: string;
  telephone: string;
  address: string;
  address2?: string;
  address3?: string;
  postcode: string;
  country: string;
}

interface ValidationErrors {
  [key: string]: string;
}

export function validateShippingDetails(details: ShippingDetails): ValidationErrors {
  const errors: ValidationErrors = {};

  if (!details.firstName?.trim()) {
    errors.firstName = 'First name is required';
  }

  if (!details.lastName?.trim()) {
    errors.lastName = 'Last name is required';
  }

  if (!details.email?.trim()) {
    errors.email = 'Email is required';
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(details.email)) {
    errors.email = 'Please enter a valid email address';
  }

  if (!details.address?.trim()) {
    errors.address = 'Address is required';
  }

  if (!details.telephone?.trim()) {
    errors.telephone = 'Telephone number is required';
  }

  if (!details.postcode?.trim()) {
    errors.postcode = 'Postcode is required';
  } else {
    // Only validate UK postcodes if country is UK
    if (details.country.toLowerCase().includes('united kingdom')) {
      const postcodeRegex = /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i;
      if (!postcodeRegex.test(details.postcode)) {
        errors.postcode = 'Invalid UK postcode format';
      }
    }
  }

  if (!details.country?.trim()) {
    errors.country = 'Country is required';
  }

  return errors;
}

export function isShippingComplete(details: ShippingDetails): boolean {
  return Boolean(
    details.firstName?.trim() &&
    details.lastName?.trim() &&
    details.email?.trim() &&
    details.telephone?.trim() &&
    details.address?.trim() &&
    details.postcode?.trim() &&
    details.country?.trim()
  );
}
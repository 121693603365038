import { MAX_FILE_SIZE, MIN_DIMENSIONS, MAX_DIMENSIONS } from '../config/constants';

export interface ImageValidationResult {
  isValid: boolean;
  errors: string[];
  metadata?: {
    width: number;
    height: number;
    format: string;
    size: number;
    isExternalUrl?: boolean;
  };
}

export async function validateImageUrl(url: string): Promise<ImageValidationResult> {
  try {
    if (!url.trim()) {
      return {
        isValid: false,
        errors: ['Image URL is required'],
        metadata: undefined
      };
    }

    // Convert Dropbox URL to direct image URL if needed
    let imageUrl = url;
    if (url.includes('dropbox.com')) {
      const directUrl = url
        .replace('www.dropbox.com', 'dl.dropboxusercontent.com')
        .split('?')[0] + '?raw=1';
      imageUrl = directUrl;
    }

    if (!url.startsWith('http')) {
      return {
        isValid: false,
        errors: ['Invalid URL format'],
        metadata: undefined
      };
    }

    const img = new Image();
    const loadPromise = new Promise<void>((resolve, reject) => {
      img.onload = () => resolve();
      img.onerror = () => reject(new Error('Failed to load image'));
      // Add timeout to prevent hanging
      setTimeout(() => reject(new Error('Image load timeout')), 10000);
    });

    // Force load to start
    img.src = imageUrl;
    img.crossOrigin = 'anonymous';

    await loadPromise;

    return {
      isValid: true,
      errors: [],
      metadata: {
        width: img.width,
        height: img.height,
        format: imageUrl.split('.').pop()?.toLowerCase() || 'unknown',
        size: 0,
        isExternalUrl: true
      }
    };
  } catch (error) {
    return {
      isValid: false,
      errors: [error instanceof Error ? error.message : 'Invalid image URL'],
      metadata: undefined
    };
  }
}

export async function validateImage(file: File): Promise<ImageValidationResult> {
  const errors: string[] = [];
  
  // Get folder from file metadata
  const folder = (file as any).folder;
  if (!folder) {
    return {
      isValid: false,
      errors: ['Missing folder information']
    };
  }

  // Validate file type
  const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
  if (!validTypes.includes(file.type)) {
    return {
      isValid: false,
      errors: ['Invalid file type. Only JPG, PNG and WebP images are allowed.']
    };
  }

  // Validate file size
  if (file.size > MAX_FILE_SIZE) {
    return {
      isValid: false,
      errors: [`File size must not exceed ${MAX_FILE_SIZE / (1024 * 1024)}MB`]
    };
  }

  // Validate image dimensions and check for corruption
  try {
    const dimensions = await getImageDimensions(file);
    
    // Different validation rules for header images
    if (folder.includes('theme-headers')) {
      if (dimensions.width < 1200 || dimensions.height < 100) {
        errors.push(`Header image must be at least 1200x100 pixels (got ${dimensions.width}x${dimensions.height})`);
        return { isValid: false, errors };
      }
      if (dimensions.width > 2400 || dimensions.height > 320) {
        errors.push(`Header image cannot exceed 2400x320 pixels (got ${dimensions.width}x${dimensions.height})`);
        return { isValid: false, errors };
      }
    } else {
      // Regular image validation
      if (dimensions.width < MIN_DIMENSIONS || dimensions.height < MIN_DIMENSIONS) {
        errors.push(`Image dimensions must be at least ${MIN_DIMENSIONS}x${MIN_DIMENSIONS} pixels`);
        return { isValid: false, errors };
      }
      if (dimensions.width > MAX_DIMENSIONS || dimensions.height > MAX_DIMENSIONS) {
        errors.push(`Image dimensions cannot exceed ${MAX_DIMENSIONS}x${MAX_DIMENSIONS} pixels`);
        return { isValid: false, errors };
      }
    }

    return {
      isValid: errors.length === 0,
      errors,
      metadata: {
        width: dimensions.width,
        height: dimensions.height,
        format: file.type.split('/')[1],
        size: file.size
      }
    };
  } catch (error) {
    errors.push('Invalid or corrupted image file');
    return { isValid: false, errors };
  }
}

function getImageDimensions(file: File): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = () => reject(new Error('Failed to load image'));
    img.src = URL.createObjectURL(file);
  });
}
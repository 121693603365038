import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { useTheme } from '../context/ThemeContext';
import AdminLayout from '../components/admin/AdminLayout';
import Header from './Header';
import PageIdentifier from './PageIdentifier';
import HomePage from '../pages/HomePage';
import CheckoutPage from '../pages/CheckoutPage';
import AdminProductsPage from '../pages/AdminProductsPage';
import AdminUsersPage from '../pages/AdminUsersPage';
import AdminCategoriesPage from '../pages/AdminCategoriesPage';
import AdminSettingsPage from '../pages/AdminSettingsPage';
import AdminOrdersPage from '../pages/AdminOrdersPage';
import AdminOrderDetailPage from '../pages/AdminOrderDetailPage';
import ImageManager from './admin/images/ImageManager';
import AdminThemesPage from '../pages/AdminThemesPage';
import ProductDetailPage from '../pages/ProductDetailPage';
import AdminDashboard from '../pages/AdminDashboard';
import PaymentSuccessPage from '../pages/PaymentSuccessPage';
import ProtectedRoute from './ProtectedRoute';

export default function AppContent() {
  const { currentTheme } = useTheme();
  const headerImage = currentTheme.assets?.headerImage;

  return (
    <div className="min-h-screen theme-background">
      {headerImage && (
        <div className="w-full h-80 relative overflow-hidden mb-8">
          <img 
            src={headerImage}
            alt="Header"
            className="w-full h-full object-cover"
            onError={(e) => {
              console.error('Header image failed to load:', e);
              e.currentTarget.style.display = 'none';
            }}
          />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-white/20 to-white pointer-events-none" />
        </div>
      )}
      <Header />
      <main className="max-w-7xl mx-auto px-4 py-8">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/product/:id" element={<ProductDetailPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/payment-success" element={<PaymentSuccessPage />} />
          <Route
            path="/admin"
            element={
              <ProtectedRoute requireAdmin>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/products"
            element={
              <ProtectedRoute requireAdmin>
                <AdminProductsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/users"
            element={
              <ProtectedRoute requireAdmin>
                <AdminUsersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/categories"
            element={
              <ProtectedRoute requireAdmin>
                <AdminCategoriesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/orders"
            element={
              <ProtectedRoute requireAdmin>
                <AdminOrdersPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/orders/:id"
            element={
              <ProtectedRoute requireAdmin>
                <AdminOrderDetailPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/themes"
            element={
              <ProtectedRoute requireAdmin>
                <AdminThemesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/images"
            element={
              <ProtectedRoute requireAdmin>
                <AdminLayout>
                  <ImageManager />
                </AdminLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin/settings"
            element={
              <ProtectedRoute requireAdmin>
                <AdminSettingsPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>
      <PageIdentifier />
    </div>
  );
}
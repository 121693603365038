import { Product } from '../../types/product';
import { SEOContent } from './types';
import { generateTitle } from './generators/titleGenerator';
import { generateDescription } from './generators/descriptionGenerator';
import { generateKeywords } from './generators/keywordGenerator';
import { generateRelatedTerms } from './generators/relatedTermsGenerator';

export function generateSEO(product: Product): SEOContent {
  try {
    if (!product) {
      throw new Error('Product is required for SEO generation');
    }

    if (!product.title || !product.description || !product.category) {
      throw new Error('Product must have title, description, and category');
    }

    const seoTitle = generateTitle(product.title, product.category);
    const seoDescription = generateDescription(product.description, product.specifications || {});
    const keywords = generateKeywords(product);
    const relatedTerms = generateRelatedTerms(product);

    return {
      title: seoTitle,
      description: seoDescription,
      keywords,
      relatedTerms,
      lastGenerated: new Date().toISOString()
    };
  } catch (error) {
    console.error('SEO generation failed:', error);
    throw new Error('Failed to generate SEO content: ' + (error instanceof Error ? error.message : 'Unknown error'));
  }
}
import React, { useState, useEffect, useCallback } from 'react';
import { Product } from '../types/product';
import { getProductMainImage, getProductImage } from '../utils/product';
import { cn } from '../utils/cn';

interface ProductGalleryProps {
  product: Product;
}

export default function ProductGallery({ product }: ProductGalleryProps) {
  const [selectedImage, setSelectedImage] = useState<string>(getProductMainImage(product));
  const [hoveredImage, setHoveredImage] = useState<string | null>(null);

  // Cleanup function for image URLs
  useEffect(() => {
    return () => {
      // Cleanup any object URLs when component unmounts
      if (selectedImage?.startsWith('blob:')) {
        URL.revokeObjectURL(selectedImage);
      }
      if (hoveredImage?.startsWith('blob:')) {
        URL.revokeObjectURL(hoveredImage);
      }
    };
  }, [selectedImage, hoveredImage]);

  // Memoize image array to prevent unnecessary recalculations
  const allImages = Array.from({ length: 9 }, (_, i) => {
    const slot = product[`imageSlot${i + 1}` as keyof Product];
    return slot && typeof slot === 'object' && 'url' in slot && slot.url ? slot : null;
  }).filter(Boolean); // Only keep slots that have valid URLs

  return (
    <div className="space-y-4">
      <div className="relative aspect-square bg-white rounded-lg overflow-hidden">
        <img
          src={hoveredImage || selectedImage || getProductImage(product)}
          alt={product.title}
          className={cn(
            "w-full h-full object-contain transition-all duration-300",
            hoveredImage ? "scale-105" : "scale-100"
          )}
        />
      </div>
      
      {allImages.length > 0 && (
      <div className="grid grid-cols-5 sm:grid-cols-7 md:grid-cols-9 gap-2">
        {allImages.map((slot, index) => slot && (
          <button
            key={index}
            onClick={() => setSelectedImage(slot.url)}
            onMouseEnter={() => setHoveredImage(slot.url)}
            onMouseLeave={() => setHoveredImage(null)}
            className={`relative aspect-square rounded-lg overflow-hidden border-2 transition-all ${
              selectedImage === slot.url
                ? 'border-yellow-400 shadow-md'
                : 'border-transparent hover:border-gray-200'
            }`}
          >
            <img
              src={slot.url}
              alt={`${product.title} view ${index + 1}`}
              className="w-full h-full object-contain"
            />
            {slot.isFeatured && (
              <span className="absolute bottom-0 left-0 right-0 bg-yellow-400 text-xs text-black text-center py-0.5">
                Main
              </span>
            )}
          </button>
        ))}
      </div>
      )}
    </div>
  );
}
import React from 'react';
import { format } from 'date-fns';
import { Order } from '../../../types/order';

interface OrderTimelineProps {
  order: Order;
}

export default function OrderTimeline({ order }: OrderTimelineProps) {
  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h3 className="text-lg font-semibold mb-4">Order Timeline</h3>
      
      <div className="relative">
        <div className="absolute left-4 top-0 bottom-0 w-0.5 bg-gray-200" />
        
        <div className="space-y-6">
          {order.history.map((event, index) => (
            <div key={index} className="relative flex items-start">
              <div className="absolute left-4 -translate-x-1/2 w-2.5 h-2.5 rounded-full bg-blue-600" />
              
              <div className="ml-8">
                <div className="flex items-center space-x-2">
                  <span className="text-sm font-medium text-gray-900">
                    {event.action}
                  </span>
                  <span className="text-sm text-gray-500">
                    by {event.performedBy}
                  </span>
                </div>
                
                <time className="block text-xs text-gray-500">
                  {format(new Date(event.timestamp), 'PPp')}
                </time>
                
                {event.details && (
                  <p className="mt-1 text-sm text-gray-600">
                    {event.details}
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
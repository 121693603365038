import React, { useState, useEffect } from 'react';
import { Paintbrush, Calendar, Save, AlertCircle, Database } from 'lucide-react';
import { ThemeConfig } from '../../types/theme';
import { useTheme } from '../../context/ThemeContext';
import { auth } from '../../config/firebase';
import { verifyAdminAccess } from '../../utils/auth/permissions';
import { withNetworkRetry } from '../../utils/networkRetry';
import { quotaManager } from '../../utils/auth/quotaManager';
import { useAuth } from '../../context/AuthContext';
import { defaultTheme } from '../../themes/default';
import { christmasTheme } from '../../themes/christmas';
import { halloweenTheme } from '../../themes/halloween';
import { valentinesTheme } from '../../themes/valentines';
import { format } from 'date-fns';
import ImageUploader from './ImageUploader';

const availableThemes = [
  defaultTheme,
  christmasTheme,
  halloweenTheme,
  valentinesTheme
];

export default function ThemeManager() {
  const { currentTheme, setTheme } = useTheme();
  const { userProfile } = useAuth();
  const [selectedTheme, setSelectedTheme] = useState<ThemeConfig | null>(null);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [previewMode, setPreviewMode] = useState(false);
  const [headerImageUrl, setHeaderImageUrl] = useState('');
  const [showHeaderUploader, setShowHeaderUploader] = useState(false);
  const [showBackgroundUploader, setShowBackgroundUploader] = useState(false);
  const isChristmasTheme = selectedTheme?.id === 'christmas';
  const isHalloweenTheme = selectedTheme?.id === 'halloween';
  const isValentinesTheme = selectedTheme?.id === 'valentines';

  const handleHeaderImageUpload = async (url: string) => {
    try {
      setError(null);
      
      if (!selectedTheme) {
        throw new Error('Please select a theme first');
      }

      setSelectedTheme(prev => prev ? {
        ...prev,
        assets: {
          ...prev.assets,
          headerImage: url
        }
      } : null);
      
      setHeaderImageUrl(url);
      setShowHeaderUploader(false);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to update header image');
    }
  };

  useEffect(() => {
    setSelectedTheme(currentTheme);
    setHeaderImageUrl(currentTheme.assets?.headerImage || '');
  }, [currentTheme?.id]);

  useEffect(() => {
    if (!selectedTheme) return;

    // Debounce theme preview changes
    const timeoutId = setTimeout(() => {
      if (previewMode) {
        const root = document.documentElement;
        // Set theme colors
        Object.entries(selectedTheme.colors).forEach(([key, value]) => {
          root.style.setProperty(`--theme-${key}`, value);
        });
        
        // Set background image
        const backgroundImage = selectedTheme.assets?.backgroundImage || '';
        root.style.setProperty(
          '--theme-background-image',
          backgroundImage ? `url(${backgroundImage})` : 'none'
        );
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [previewMode, selectedTheme]);

  const handleThemeChange = async (themeId: string) => {
    try {
      setError(null);
      
      // Check quota limits
      const canProceed = await quotaManager.checkQuota('theme_change');
      if (!canProceed) {
        const cooldown = quotaManager.getRemainingCooldown('theme_change');
        throw new Error(`To ensure system stability, theme changes are temporarily limited. Please wait ${Math.ceil(cooldown / 60000)} minutes before making more changes.`);
        return;
      }

      // Prevent duplicate theme selection
      if (selectedTheme?.id === themeId) {
        return;
      }
      
      // Don't reapply the same theme unless assets changed
      if (selectedTheme?.id === themeId && 
          JSON.stringify(selectedTheme?.assets) === JSON.stringify(currentTheme?.assets)) {
        return;
      }

      const theme = availableThemes.find(t => t.id === themeId);
      if (!theme) {
        throw new Error('Invalid theme selection');
      }

      const newTheme = {
        ...theme,
        assets: {
          ...theme.assets,
          ...(themeId === currentTheme?.id ? currentTheme.assets : {})
        },
        isActive: true,
        lastModified: new Date().toISOString(),
        modifiedBy: auth.currentUser?.email || 'system'
      };
      
      setSelectedTheme(newTheme);
      setHeaderImageUrl(newTheme.assets?.headerImage || '');
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Failed to change theme');
      console.error('Theme change error:', error);
    }
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      setError(null);
      setSuccess(false);

      // Create theme object with current state
      const themeToSave = selectedTheme ? {
        ...selectedTheme,
        modifiedBy: userProfile?.email || 'system',
        lastModified: new Date().toISOString(),
        assets: {
          ...selectedTheme.assets,
          headerImage: headerImageUrl,
          backgroundImage: selectedTheme.assets?.backgroundImage || ''
        }
      } : null;

      if (!themeToSave) {
        throw new Error('No theme selected');
      }

      await withNetworkRetry(
        () => setTheme(themeToSave),
        {
          maxAttempts: 3,
          baseDelay: 1000,
          maxDelay: 5000,
          onRetry: (attempt, error) => {
            console.warn(`Theme save attempt ${attempt} failed:`, error);
            setError(`Save attempt ${attempt} failed. Retrying...`);
          }
        }
      );

      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
    } catch (error) {
      let errorMessage = error instanceof Error ? error.message : 'Failed to save theme changes';
      
      // Add helpful message for rate limit errors
      if (errorMessage.includes('rate limit')) {
        errorMessage += ' This helps prevent system overload. Please try again later.';
      }
      
      setError(errorMessage);
    } finally {
      setSaving(false);
      setPreviewMode(false);
    }
  };

  // Add rate limit warning
  const quotaStatus = quotaManager.getQuotaStatus('theme_change');
  const showQuotaWarning = quotaStatus.remainingAttempts <= 5;
  
  return (
    <div className="max-w-4xl">
      {showQuotaWarning && (
        <div className="mb-4 p-3 bg-yellow-50 text-yellow-800 rounded-lg">
          <p className="text-sm">
            Warning: {quotaStatus.remainingAttempts} theme changes remaining. 
            Changes will be limited for {Math.ceil(quotaStatus.windowResetIn / 60000)} minutes.
          </p>
        </div>
      )}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <Database className="h-6 w-6 text-gray-700" />
          <div>
            <h2 className="text-xl font-bold">Theme Settings</h2>
            {previewMode && (
              <p className="text-sm text-yellow-600">Preview Mode Active</p>
            )}
          </div>
        </div>
        <button
          onClick={handleSave}
          disabled={saving}
          className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
            saving
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-yellow-400 hover:bg-yellow-500'
          }`}
        >
          <Save className="h-5 w-5" />
          <span>{saving ? 'Saving...' : 'Save Changes'}</span>
        </button>
      </div>

      <div className="space-y-6">
        {/* Preview Toggle */}
        <div className="flex items-center space-x-2 bg-gray-50 p-4 rounded-lg">
          <input
            type="checkbox"
            id="preview"
            checked={previewMode}
            onChange={(e) => setPreviewMode(e.target.checked)}
            className="h-4 w-4 text-yellow-400 focus:ring-yellow-400 border-gray-300 rounded"
          />
          <label htmlFor="preview" className="text-sm text-gray-600">
            Enable live preview
          </label>
        </div>

        {/* Theme Selection */}
        <div className="grid grid-cols-2 gap-4">
          {availableThemes.map((theme) => (
            <div
              key={theme.id}
              className={`p-4 rounded-lg border-2 cursor-pointer transition-colors ${
                (selectedTheme || currentTheme)?.id === theme.id
                  ? 'border-yellow-400 bg-yellow-50'
                  : 'border-gray-200 hover:border-gray-300'
              }`}
              onClick={() => handleThemeChange(theme.id)}
            >
              {theme.assets?.backgroundImage && (
                <div className="mb-4 relative h-32 rounded-lg overflow-hidden">
                  <img
                    src={theme.assets.backgroundImage}
                    alt={theme.name}
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      console.error('Theme background failed to load:', e);
                      e.currentTarget.style.display = 'none';
                    }}
                  />
                </div>
              )}
              <div className="flex items-center justify-between mb-2">
                <h3 className="font-medium">{theme.name}</h3>
              </div>
              <p className="text-sm text-gray-600 mb-4">{theme.description}</p>
              <div className="flex space-x-2">
                {Object.entries(theme.colors).map(([key, value]) => (
                  <div
                    key={key}
                    className="w-6 h-6 rounded-full"
                    style={{ backgroundColor: value }}
                    title={`${key}: ${value}`}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>


        {/* Background Image URL */}
        <div className="bg-white p-6 rounded-lg border space-y-6">
          <h3 className="font-medium">Theme Images</h3>
          
          {/* Background Image */}
          <div className="space-y-4">
            <label className="block text-sm font-medium text-gray-700">
              Header Image URL
            </label>
            <p className="text-xs text-gray-500 mb-2">
              Required dimensions: 1200x100 to 2400x320 pixels
            </p>
            <div className="space-y-2">
              <input
                type="url"
                value={headerImageUrl}
                onChange={(e) => setHeaderImageUrl(e.target.value)}
                placeholder="Enter header image URL"
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
              />
              <button
                onClick={() => setShowHeaderUploader(!showHeaderUploader)}
                className="text-sm text-blue-600 hover:text-blue-800"
              >
                Or upload an image
              </button>
              {showHeaderUploader && (
                <ImageUploader
                  onUploadComplete={handleHeaderImageUpload}
                  folder="theme-headers"
                />
              )}
            </div>

            <label className="block text-sm font-medium text-gray-700">
              Background Image URL
            </label>
            <p className="text-xs text-gray-500 mb-2">
              Required dimensions: 600x600 to 4000x4000 pixels
            </p>
            <div className="space-y-2">
              <input
                type="url"
                value={(selectedTheme || currentTheme)?.assets?.backgroundImage || ''}
                onChange={(e) => setSelectedTheme({
                  ...selectedTheme,
                  assets: {
                    ...(selectedTheme?.assets || {}),
                    backgroundImage: e.target.value
                  }
                })}
                placeholder="Enter background image URL"
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
              />
              <button
                onClick={() => setShowBackgroundUploader(!showBackgroundUploader)}
                className="text-sm text-blue-600 hover:text-blue-800"
              >
                Or upload an image
              </button>
              {showBackgroundUploader && (
                <ImageUploader
                  onUploadComplete={(url) => {
                    setSelectedTheme({
                      ...selectedTheme,
                      assets: {
                        ...selectedTheme.assets,
                        backgroundImage: url
                      }
                    });
                    setShowBackgroundUploader(false);
                  }}
                  folder="theme-backgrounds"
                />
              )}
            </div>
          </div>

          {/* Theme-specific Decorative Images */}
          <div className="space-y-4">
            <label className="block text-sm font-medium text-gray-700">
              Decorative Elements
            </label>
            <p className="text-xs text-gray-500 mb-2">
              Required dimensions: 600x600 to 4000x4000 pixels
            </p>
            {selectedTheme?.id === 'halloween' && (
              <>
                <input
                  type="url"
                  value={selectedTheme?.assets?.pumpkinImage || ''}
                  onChange={(e) => setSelectedTheme({
                    ...selectedTheme,
                    assets: {
                      ...(selectedTheme?.assets || {}),
                      pumpkinImage: e.target.value
                    }
                  })}
                  placeholder="Enter pumpkin image URL"
                  className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                />
                <input
                  type="url"
                  value={selectedTheme.assets?.ghostImage || ''}
                  onChange={(e) => setSelectedTheme({
                    ...selectedTheme,
                    assets: {
                      ...selectedTheme.assets,
                      ghostImage: e.target.value
                    }
                  })}
                  placeholder="Enter ghost image URL"
                  className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                />
              </>
            )}
            {selectedTheme?.id === 'christmas' && (
              <>
                <input
                  type="url"
                  value={selectedTheme?.assets?.hollyImage || ''}
                  onChange={(e) => setSelectedTheme({
                    ...selectedTheme,
                    assets: {
                      ...(selectedTheme?.assets || {}),
                      hollyImage: e.target.value
                    }
                  })}
                  placeholder="Enter holly image URL"
                  className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                />
                <input
                  type="url"
                  value={selectedTheme.assets?.ornamentImage || ''}
                  onChange={(e) => setSelectedTheme({
                    ...selectedTheme,
                    assets: {
                      ...selectedTheme.assets,
                      ornamentImage: e.target.value
                    }
                  })}
                  placeholder="Enter ornament image URL"
                  className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                />
              </>
            )}
            {selectedTheme?.id === 'valentines' && (
              <>
                <input
                  type="url"
                  value={selectedTheme?.assets?.heartImage || ''}
                  onChange={(e) => setSelectedTheme({
                    ...selectedTheme,
                    assets: {
                      ...(selectedTheme?.assets || {}),
                      heartImage: e.target.value
                    }
                  })}
                  placeholder="Enter heart image URL"
                  className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                />
                <input
                  type="url"
                  value={selectedTheme.assets?.cupidImage || ''}
                  onChange={(e) => setSelectedTheme({
                    ...selectedTheme,
                    assets: {
                      ...selectedTheme.assets,
                      cupidImage: e.target.value
                    }
                  })}
                  placeholder="Enter cupid image URL"
                  className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                />
              </>
            )}
          </div>

          {/* Image Preview */}
          {selectedTheme?.assets?.backgroundImage && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Background Preview
              </label>
              <div className="relative h-48 rounded-lg overflow-hidden">
                <img
                  src={selectedTheme?.assets?.backgroundImage}
                  alt="Theme background preview"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
          )}
        </div>
        {/* Save Button */}
        <div className="flex justify-end">
          <button
            onClick={handleSave}
            disabled={saving}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
              saving
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-yellow-400 hover:bg-yellow-500'
            }`}
          >
            <Save className="h-5 w-5" />
            <span>{saving ? 'Saving...' : 'Save Changes'}</span>
          </button>
        </div>

        {error && (
          <div className="bg-red-50 text-red-700 p-4 rounded-lg flex items-center space-x-2">
            <AlertCircle className="h-5 w-5" />
            <span>{error}</span>
          </div>
        )}

        {success && (
          <div className="bg-green-50 text-green-700 p-4 rounded-lg flex items-center space-x-2">
            <AlertCircle className="h-5 w-5" />
            <span>Theme settings saved successfully</span>
          </div>
        )}
      </div>
    </div>
  );
}
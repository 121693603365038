import React from 'react';
import { Truck } from 'lucide-react';
import { formatPrice } from '../utils/currency';
import { useSite } from '../context/SiteContext';
import { shouldApplyFreeShipping } from '../utils/currency';
import { CartItem } from '../types/product';

interface ShippingSummaryProps {
  items: CartItem[];
}

export default function ShippingSummary({ items }: ShippingSummaryProps) {
  const { currency, settings } = useSite();
  
  const subtotal = items.reduce((total, item) => 
    total + (item.price * item.quantity), 0);
  
  const freeShippingThreshold = settings?.shipping?.freeThreshold || 100;
  const isFreeShipping = shouldApplyFreeShipping(subtotal, freeShippingThreshold);
  
  const totalShipping = isFreeShipping ? 0 : items.reduce((total, item) => {
    const cost = item.shipping_cost && !isNaN(item.shipping_cost) ? item.shipping_cost : 0;
    return total + (cost * item.quantity);
  }, 0);

  return (
    <div className="flex items-center justify-between text-sm text-gray-600">
      <div className="flex items-center">
        <Truck className="h-4 w-4 mr-2" />
        <span>Shipping:</span>
      </div>
      <div className="font-medium">
        {isFreeShipping ? (
          <span className="text-green-600">Free Shipping</span>
        ) : (
          formatPrice(totalShipping, currency)
        )}
      </div>
    </div>
  );
}
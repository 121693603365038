import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Star, ArrowLeft } from 'lucide-react';
import { Product } from '../types/product';
import { useCart } from '../context/CartContext';
import { useSite } from '../context/SiteContext';
import { getProductImage } from '../utils/product';
import { formatPrice, getPriceBreakdown } from '../utils/currency';
import ProductGallery from './ProductGallery';

interface ProductDetailProps {
  product: Product;
  onBack: () => void;
}

export default function ProductDetail({ product, onBack }: ProductDetailProps) {
  const { dispatch } = useCart();
  const { currency, settings } = useSite(); 
  const cartEnabled = settings?.cartSystem?.enabled ?? false;
  const cartOptions = settings?.cartSystem?.options ?? {
    showPrices: false,
    showStock: false,
    showAddToCart: false,
    showBuyNow: false,
    showShipping: false,
    showRatings: false,
    showProductLink: false,
    productLinkText: ''
  };
  const navigate = useNavigate();
  const taxRate = settings?.tax?.rate || 20;
  const priceBreakdown = getPriceBreakdown(product.price, currency, taxRate);

  const addToCart = () => {
    dispatch({ type: 'ADD_TO_CART', payload: product });
  };

  const handleBuyNow = () => {
    dispatch({ type: 'ADD_TO_CART', payload: product });
    navigate('/checkout');
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <button
        onClick={onBack}
        className="flex items-center text-gray-600 hover:text-gray-900 mb-6"
      >
        <ArrowLeft className="h-5 w-5 mr-2" />
        Back to Products
      </button>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="space-y-4">
          <ProductGallery product={product} />
        </div>

        <div className="space-y-6">
          <div>
            <h1 className="text-3xl font-bold mb-2">{product.title}</h1>
            {cartEnabled && cartOptions?.showRatings && <div className="flex items-center space-x-2">
              <div className="flex">
                {[...Array(5)].map((_, i) => (
                  <Star
                    key={i}
                    className={`h-5 w-5 ${
                      i < product.rating
                        ? 'text-yellow-400 fill-current'
                        : 'text-gray-300'
                    }`}
                  />
                ))}
              </div>
              <span className="text-gray-600">({product.rating} out of 5)</span>
            </div>}
          </div>

          {cartEnabled && cartOptions?.showPrices && <div className="border-t border-b py-4">
            <div className="space-y-1">
              <div className="text-3xl font-bold text-gray-900">
                {priceBreakdown.formattedTotal} 
                <span className="text-sm text-gray-500">inc. VAT</span>
              </div>
              <div className="text-sm text-gray-600">
                {priceBreakdown.formattedBase} + {priceBreakdown.formattedTax} VAT ({taxRate}%)
              </div>
            </div>
          </div>}

          <div>
            <h2 className="font-semibold text-lg mb-2">About this item</h2>
            <p className="text-gray-600 leading-relaxed">{product.description}</p>
          </div>

          {cartEnabled && (
            <div className="space-y-3">
              {cartOptions.showAddToCart && (
                <button
                  onClick={addToCart}
                  className="w-full bg-yellow-400 hover:bg-yellow-500 text-black font-semibold py-3 px-6 rounded-lg transition-colors"
                >
                  Add to Cart
                </button>
              )}
              {cartOptions.showBuyNow && (
                <button
                  onClick={handleBuyNow}
                  className="w-full bg-yellow-400 hover:bg-yellow-500 text-black font-semibold py-3 px-6 rounded-lg transition-colors"
                >
                  Buy Now
                </button>
              )}
              {cartOptions.showProductLink && product.productLinkHref && (
                <a
                  href={product.productLinkHref}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-lg transition-colors text-center inline-block"
                >
                  {cartOptions.productLinkText || 'View Details'}
                </a>
              )}
            </div>
          )}

          <div className="border-t pt-4">
            <div className="grid grid-cols-2 gap-4 text-sm">
              <div>
                <span className="text-gray-500">Category:</span>
                <span className="ml-2 text-gray-900">{product.category}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ); 
}
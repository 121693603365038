import { ThemeConfig } from '../types/theme';

export const christmasTheme: ThemeConfig = {
  id: 'christmas',
  name: 'Christmas Theme',
  description: 'Festive Christmas theme with snow effects',
  colors: {
    primary: '#DC2626', // red-600
    secondary: '#059669', // green-600
    accent: '#FEE2E2', // red-100
    background: '#FFFFFF',
    text: '#111827', // gray-900
    border: '#FEE2E2', // red-100
  },
  assets: {
    backgroundImage: 'https://images.unsplash.com/photo-1543589077-47d81606c1bf?auto=format&fit=crop&q=80&w=2000',
    headerImage: 'https://images.unsplash.com/photo-1512389142860-9c449e58a543?auto=format&fit=crop&q=80&w=2000',
    overlayEffect: 'snow',
    animations: ['snowfall', 'twinkle']
  },
  isActive: false,
  lastModified: new Date().toISOString(),
  modifiedBy: 'system'
};
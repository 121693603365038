import React, { useState } from 'react';
import { Product } from '../../types/product';
import { Search, ArrowUpDown, Edit, ChevronDown, ChevronRight } from 'lucide-react';
import { formatPrice } from '../../utils/currency';
import ProductEditor from './ProductEditor';
import { useSite } from '../../context/SiteContext';
import BulkSEOOptimizer from './BulkSEOOptimizer';

interface AdminProductListProps {
  products: Product[];
}

interface CategoryGroup {
  name: string;
  products: Product[];
}

export default function AdminProductList({ products }: AdminProductListProps) {
  const { currency } = useSite();
  const [searchTerm, setSearchTerm] = useState('');
  const [sortField, setSortField] = useState<keyof Product>('title');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [editingProduct, setEditingProduct] = useState<Product | null>(null);
  const [expandedCategories, setExpandedCategories] = useState<Set<string>>(new Set());

  const handleSort = (field: keyof Product) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const toggleCategory = (category: string) => {
    const newExpanded = new Set(expandedCategories);
    if (newExpanded.has(category)) {
      newExpanded.delete(category);
    } else {
      newExpanded.add(category);
    }
    setExpandedCategories(newExpanded);
  };

  // Group and sort products by category
  const categorizedProducts = products
    .filter(product =>
      product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.id.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .reduce((acc: CategoryGroup[], product) => {
      const group = acc.find(g => g.name === product.category);
      if (group) group.products.push(product);
      else acc.push({ name: product.category, products: [product] });
      return acc;
    }, [])
    .sort((a, b) => a.name.localeCompare(b.name));

  const filteredProducts = products
    .filter(product =>
      product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.id.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
      const modifier = sortDirection === 'asc' ? 1 : -1;
      
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue) * modifier;
      }
      return ((aValue as number) - (bValue as number)) * modifier;
    });

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <BulkSEOOptimizer />
      <div className="h-8" />
      <h2 className="text-xl font-bold mb-4">Products</h2>
      
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border rounded-lg"
        />
        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead>
            <tr className="border-b">
              <th className="py-2 text-left">
                <button 
                  onClick={() => handleSort('sku')}
                  className="flex items-center space-x-1">
                  <span>SKU</span>
                  <ArrowUpDown className="h-4 w-4" />
                </button>
              </th>
              <th className="py-2 text-left">
                <button
                  onClick={() => handleSort('title')}
                  className="flex items-center space-x-1"
                >
                  <span>Name</span>
                  <ArrowUpDown className="h-4 w-4" />
                </button>
              </th>
              <th className="py-2 text-left">
                <button
                  onClick={() => handleSort('price')}
                  className="flex items-center space-x-1"
                >
                  <span>Price</span>
                  <ArrowUpDown className="h-4 w-4" />
                </button>
              </th>
              <th className="py-2 text-left">Status</th>
              <th className="py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {categorizedProducts.map((category) => (
              <React.Fragment key={category.name}>
                <tr className="bg-gray-50">
                  <td colSpan={5} className="py-3 px-4">
                    <button
                      onClick={() => toggleCategory(category.name)}
                      className="flex items-center space-x-2 font-medium text-gray-700"
                    >
                      {expandedCategories.has(category.name) ? (
                        <ChevronDown className="h-4 w-4" />
                      ) : (
                        <ChevronRight className="h-4 w-4" />
                      )}
                      <span>{category.name}</span>
                      <span className="text-gray-500 text-sm">
                        ({category.products.length} products)
                      </span>
                    </button>
                  </td>
                </tr>
                {expandedCategories.has(category.name) && category.products
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .map((product) => (
                    <tr key={product.id} className="border-b hover:bg-gray-50">
                      <td className="py-2 px-4">{product.sku || 'Not Set'}</td>
                      <td className="py-2 px-4">{product.title}</td>
                      <td className="py-2 px-4">{formatPrice(product.price, currency)}</td>
                      <td className="py-2 px-4">
                        <span className={`px-2 py-1 rounded-full text-xs ${
                          product.status === 'active' 
                            ? 'bg-green-100 text-green-800'
                            : 'bg-red-100 text-red-800'
                        }`}>
                          {product.status || 'active'}
                        </span>
                      </td>
                      <td className="py-2 px-4">
                        <button
                          onClick={() => setEditingProduct(product)}
                          className="text-gray-600 hover:text-gray-900"
                        >
                          <Edit className="h-5 w-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </React.Fragment> 
            ))} 
          </tbody>
        </table>
      </div>
      {editingProduct && (
        <ProductEditor
          product={editingProduct}
          onClose={() => setEditingProduct(null)}
        />
      )}
    </div>
  );
}
import React, { useState } from 'react';
import { Download, X, AlertCircle } from 'lucide-react';
import { format } from 'date-fns';
import { exportUsers, ExportOptions, ExportProgress } from '../../utils/userExport';
import { saveAs } from 'file-saver';
import { UserProfile } from '../../types/auth';

const AVAILABLE_COLUMNS: { key: keyof UserProfile; label: string }[] = [
  { key: 'id', label: 'User ID' },
  { key: 'name', label: 'Full Name' },
  { key: 'email', label: 'Email' },
  { key: 'registrationDate', label: 'Registration Date' },
  { key: 'lastLoginDate', label: 'Last Login' },
  { key: 'status', label: 'Account Status' },
  { key: 'role', label: 'User Role' },
  { key: 'address', label: 'Address' },
  { key: 'address2', label: 'Address Line 2' },
  { key: 'address3', label: 'Address Line 3' },
  { key: 'postcode', label: 'Postcode' },
  { key: 'telephone', label: 'Telephone' }
];

export default function UserExport() {
  const [isExporting, setIsExporting] = useState(false);
  const [progress, setProgress] = useState<ExportProgress | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [options, setOptions] = useState<ExportOptions>({
    dateRange: undefined,
    status: 'all',
    role: 'all',
    columns: ['id', 'name', 'email', 'registrationDate', 'status', 'role']
  });

  const handleExport = async () => {
    if (isExporting) return;
    setIsExporting(true);
    setError(null);

    try {
      const blob = await exportUsers(options, setProgress);
      const fileName = `users_export_${format(new Date(), 'yyyy-MM-dd_HHmm')}.xlsx`;
      saveAs(blob, fileName);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Export failed');
    } finally {
      setIsExporting(false);
    }
  };

  const toggleColumn = (column: keyof UserProfile) => {
    setOptions(prev => ({
      ...prev,
      columns: prev.columns.includes(column)
        ? prev.columns.filter(col => col !== column)
        : [...prev.columns, column]
    }));
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-bold">Export Users</h2>
        <button
          onClick={handleExport}
          disabled={isExporting || options.columns.length === 0}
          className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
            isExporting || options.columns.length === 0
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-yellow-400 hover:bg-yellow-500'
          }`}
        >
          <Download className="h-5 w-5" />
          <span>{isExporting ? 'Exporting...' : 'Export Users'}</span>
        </button>
      </div>

      <div className="space-y-6">
        {/* Date Range Filter */}
        <div>
          <h3 className="font-medium mb-2">Date Range</h3>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm text-gray-600 mb-1">Start Date</label>
              <input
                type="date"
                onChange={(e) => setOptions(prev => ({
                  ...prev,
                  dateRange: {
                    start: new Date(e.target.value),
                    end: prev.dateRange?.end || new Date()
                  }
                }))}
                className="w-full p-2 border rounded-lg"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600 mb-1">End Date</label>
              <input
                type="date"
                onChange={(e) => setOptions(prev => ({
                  ...prev,
                  dateRange: {
                    start: prev.dateRange?.start || new Date(),
                    end: new Date(e.target.value)
                  }
                }))}
                className="w-full p-2 border rounded-lg"
              />
            </div>
          </div>
        </div>

        {/* Status and Role Filters */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block font-medium mb-2">Account Status</label>
            <select
              value={options.status}
              onChange={(e) => setOptions(prev => ({
                ...prev,
                status: e.target.value as typeof options.status
              }))}
              className="w-full p-2 border rounded-lg"
            >
              <option value="all">All Statuses</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </select>
          </div>
          <div>
            <label className="block font-medium mb-2">User Role</label>
            <select
              value={options.role}
              onChange={(e) => setOptions(prev => ({
                ...prev,
                role: e.target.value as typeof options.role
              }))}
              className="w-full p-2 border rounded-lg"
            >
              <option value="all">All Roles</option>
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
          </div>
        </div>

        {/* Column Selection */}
        <div>
          <h3 className="font-medium mb-2">Select Columns to Export</h3>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
            {AVAILABLE_COLUMNS.map(({ key, label }) => (
              <label
                key={key}
                className="flex items-center space-x-2 p-2 hover:bg-gray-50 rounded"
              >
                <input
                  type="checkbox"
                  checked={options.columns.includes(key)}
                  onChange={() => toggleColumn(key)}
                  className="rounded text-yellow-400 focus:ring-yellow-400"
                />
                <span>{label}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Progress Indicator */}
        {progress && progress.status === 'processing' && (
          <div className="bg-blue-50 p-4 rounded-lg">
            <div className="flex items-center justify-between mb-2">
              <span className="font-medium text-blue-700">Exporting users...</span>
              <span className="text-sm text-blue-600">
                {progress.processed} / {progress.total}
              </span>
            </div>
            <div className="w-full bg-blue-200 rounded-full h-2">
              <div
                className="bg-blue-600 rounded-full h-2 transition-all duration-300"
                style={{
                  width: `${(progress.processed / progress.total) * 100}%`
                }}
              />
            </div>
          </div>
        )}

        {/* Error Message */}
        {error && (
          <div className="bg-red-50 text-red-700 p-4 rounded-lg flex items-start space-x-2">
            <AlertCircle className="h-5 w-5 mt-0.5 flex-shrink-0" />
            <div>
              <h4 className="font-medium">Export failed</h4>
              <p className="text-sm">{error}</p>
            </div>
            <button
              onClick={() => setError(null)}
              className="ml-auto text-red-500 hover:text-red-700"
            >
              <X className="h-5 w-5" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
import React from 'react';
import { Star } from 'lucide-react';
import { Product } from '../types/product';
import { useCart } from '../context/CartContext';
import { useTheme } from '../context/ThemeContext';
import { useSite } from '../context/SiteContext';
import { getProductImage } from '../utils/product';
import { formatPrice, getPriceBreakdown } from '../utils/currency';
import { cn } from '../utils/cn';

interface ProductCardProps {
  product: Product;
}

export default function ProductCard({ product }: ProductCardProps) {
  const { dispatch } = useCart();
  const { currency, settings } = useSite();
  const { currentTheme } = useTheme();
  const taxRate = settings?.tax?.rate ?? 20;
  const priceBreakdown = getPriceBreakdown(product.price, currency, taxRate);
  const cartEnabled = settings?.cartSystem?.enabled ?? true;
  const cartOptions = settings?.cartSystem?.options ?? {};

  const stockInfo = product.stock || {
    quantity: 10,
    threshold: 5,
    status: product.stock?.status || 'in-stock',
    lastUpdated: new Date().toISOString()
  };

  const handleAddToCart = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch({ type: 'ADD_TO_CART', payload: product });
  };

  const isChristmasTheme = currentTheme.id === 'christmas';
  const isHalloweenTheme = currentTheme.id === 'halloween';
  const isValentinesTheme = currentTheme.id === 'valentines';

  return (
    <div className={cn(
      "bg-white p-4 rounded-lg shadow-md flex flex-col relative",
      "transition-all duration-300",
      isChristmasTheme && "christmas-card",
      isHalloweenTheme && "halloween-card",
      isValentinesTheme && "valentines-card"
    )}>
      {/* Top Decorative Element */}
      {isChristmasTheme && currentTheme.assets?.hollyImage && (
        <img 
          src={currentTheme.assets?.hollyImage}
          alt="Top decoration"
          className="w-[calc(100%+2rem)] h-16 -mx-4 -mt-4 mb-4 object-cover"
        />
      )}
      {isHalloweenTheme && currentTheme.assets?.pumpkinImage && (
        <img 
          src={currentTheme.assets.pumpkinImage}
          alt="Top decoration"
          className="w-[calc(100%+2rem)] h-16 -mx-4 -mt-4 mb-4 object-cover"
        />
      )}
      {isValentinesTheme && currentTheme.assets?.heartImage && (
        <img 
          src={currentTheme.assets.heartImage}
          alt="Top decoration"
          className="w-[calc(100%+2rem)] h-16 -mx-4 -mt-4 mb-4 object-cover"
        />
      )}
      
      {/* Product Status Badge */}

      {(product.status === 'inactive' || (stockInfo.status === 'out-of-stock' && stockInfo.quantity <= 0)) && (
        <div className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded text-xs">
          {product.status === 'inactive' ? 'INACTIVE' : stockInfo.quantity <= 0 ? 'OUT OF STOCK' : ''}
        </div>
      )}
      
      {/* Stock Status Badge */}
      {stockInfo.status === 'low-stock' && stockInfo.quantity > 0 && stockInfo.quantity <= stockInfo.threshold && (
        <div className="absolute top-2 right-2 bg-yellow-500 text-white px-2 py-1 rounded text-xs">
          LOW STOCK
        </div>
      )}
      
      {/* Product Image */}
      <img 
        src={getProductImage(product)}
        alt={product.title}
        className="w-full h-48 object-contain mb-4"
      />
      
      {/* Product Title */}
      <h3 className="text-lg font-semibold line-clamp-2 mb-2">{product.title}</h3>
      
      {/* Ratings */}
      {cartEnabled && cartOptions?.showRatings && (
        <div className="flex items-center mb-2">
        {[...Array(5)].map((_, i) => (
          <Star
            key={i}
            className={`h-4 w-4 ${
              i < product.rating
                ? 'text-yellow-400 fill-current'
                : 'text-gray-300'
            }`}
          />
        ))}
        </div>
      )}
      
      {/* Product Description */}
      <p className="text-gray-600 text-sm line-clamp-3 mb-4 flex-grow">
        {product.description}
      </p>
      
      {/* Price and Cart Section */}
      {cartEnabled && cartOptions?.showPrices && (
        <div className="mt-auto">
        <div className="space-y-1">
          <div className="text-xl font-bold">
            {priceBreakdown.formattedTotal}
            <span className="text-xs text-gray-500 ml-1">inc. VAT</span>
          </div>
          <div className="text-xs text-gray-500">
            {priceBreakdown.formattedBase} + {priceBreakdown.formattedTax} VAT
            ({taxRate}%)
          </div>
        </div>
        
        {/* Add to Cart Button */}
        {cartEnabled && cartOptions?.showAddToCart && (
        <button
          disabled={product.status === 'inactive' || stockInfo.quantity === 0}
          onClick={handleAddToCart}
          className={`w-full font-semibold py-2 px-4 rounded-lg transition-colors ${
            product.status === 'inactive' || stockInfo.quantity === 0
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-yellow-400 hover:bg-yellow-500 text-black'
          }`}
        >
          {product.status === 'inactive' || stockInfo.quantity === 0
            ? 'Out of Stock'
            : 'Add to Cart'}
        </button>
        )}
        
        {/* Product Link */}
        {cartEnabled && cartOptions?.showProductLink && product.productLinkHref && (
          <a
            href={product.productLinkHref}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-2 w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-lg text-center inline-block"
          >
            {cartOptions?.productLinkText || 'View Details'}
          </a>
        )}
      </div>
      )}
 
      {/* Bottom Decorative Element */}
      {isChristmasTheme && currentTheme.assets?.hollyImage && (
        <img 
          src={currentTheme.assets?.ornamentImage}
          alt="Bottom decoration"
          className="w-[calc(100%+2rem)] h-16 -mx-4 -mb-4 mt-4 object-cover"
        />
      )}
      {isHalloweenTheme && currentTheme.assets?.pumpkinImage && (
        <img 
          src={currentTheme.assets?.ghostImage}
          alt="Bottom decoration"
          className="w-[calc(100%+2rem)] h-16 -mx-4 -mb-4 mt-4 object-cover"
        />
      )}
      {isValentinesTheme && currentTheme.assets?.heartImage && (
        <img 
          src={currentTheme.assets?.cupidImage}
          alt="Bottom decoration"
          className="w-[calc(100%+2rem)] h-16 -mx-4 -mb-4 mt-4 object-cover"
        />
      )}
    </div>
  );
}
import React, { useState, useMemo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../context/CartContext';
import { useSite } from '../context/SiteContext';
import { useAuth } from '../context/AuthContext';
import { CreditCard, Bitcoin, Package, Trash2, AlertCircle } from 'lucide-react';
import { getProductImage } from '../utils/product';
import { formatPrice, shouldApplyFreeShipping } from '../utils/currency';
import { calculateTaxBreakdown, getTaxRate } from '../utils/tax';
import CreditCardForm from '../components/payment/CreditCardForm';
import CryptoPayment from '../components/payment/CryptoPayment';
import { usePayment } from '../context/PaymentContext';
import ShippingSummary from '../components/ShippingSummary';

export default function CheckoutPage() {
  const { state, dispatch } = useCart();
  const { currency, settings } = useSite();
  const { userProfile } = useAuth();
  const { paymentMethod, setPaymentMethod } = usePayment();
  const cryptoSurchargeRate = settings?.cryptoPayments?.surchargeRate ?? 0.5;
  const navigate = useNavigate();
  const [paymentType, setPaymentType] = useState<'card' | 'crypto'>('card');
  const taxRate = getTaxRate(settings);
  const freeShippingThreshold = settings?.shipping?.freeThreshold ?? 100;
  
  const subtotal = useMemo(() => {
    return state.items.reduce((total, item) => 
      total + (item.price * item.quantity), 0);
  }, [state.items]);
  
  const qualifiesForFreeShipping = shouldApplyFreeShipping(subtotal, freeShippingThreshold);
  
  const shippingTotal = state.items.reduce((total, item) => 
    total + ((item.shipping_cost || 0) * item.quantity), 0);

  const priceBreakdown = useMemo(() => {
    return state.items.map(item => ({
      ...item,
      breakdown: calculateTaxBreakdown(item.price * item.quantity, taxRate)
    }));
  }, [state.items, taxRate]);

  const totals = useMemo(() => {
    const baseAmount = subtotal;
    const taxAmount = Math.round((baseAmount * (taxRate / 100)) * 100) / 100;
    const totalWithTax = Math.round((baseAmount + taxAmount) * 100) / 100;
    const shippingCost = qualifiesForFreeShipping ? 0 : shippingTotal;
    
    return {
      baseAmount,
      taxAmount,
      totalAmount: totalWithTax,
      finalTotal: totalWithTax + shippingCost
    };
  }, [subtotal, taxRate, qualifiesForFreeShipping, shippingTotal]);

  const [shippingAddress, setShippingAddress] = useState({
    firstName: '',
    lastName: '',
    email: '',
    telephone: userProfile?.telephone || '',
    address: '',
    address2: '',
    address3: '',
    postcode: '',
    country: ''
  });

  // Auto-fill form for logged-in users
  useEffect(() => {
    if (userProfile) {
      const [firstName, ...lastNameParts] = userProfile.name.split(' ');
      setShippingAddress({
        firstName: firstName || '',
        lastName: lastNameParts.join(' ') || '',
        email: userProfile.email,
        telephone: userProfile?.telephone || '',
        address: userProfile?.address || '',
        address2: userProfile?.address2 || '',
        address3: userProfile?.address3 || '',
        postcode: userProfile?.postcode || '',
        country: userProfile?.country || ''
      });
    }
  }, [userProfile?.email, userProfile?.name, userProfile?.telephone, userProfile?.address, 
      userProfile?.address2, userProfile?.address3, userProfile?.postcode, userProfile?.country]);

  const [shippingErrors, setShippingErrors] = useState<Record<string, string>>({});

  const removeItem = (id: string) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: id });
  };

  const handleShippingChange = (field: string, value: string) => {
    setShippingAddress(prev => ({ ...prev, [field]: value }));
    if (shippingErrors[field]) {
      setShippingErrors(prev => ({ ...prev, [field]: '' }));
    }
  };

  if (state.items.length === 0) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-gray-900">Your cart is empty</h2>
        <button
          onClick={() => navigate('/')}
          className="mt-4 text-blue-600 hover:text-blue-800"
        >
          Continue Shopping
        </button>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
      <div className="lg:col-span-2 space-y-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-bold mb-4">Shipping Address</h2>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                First Name
              </label>
              <input
                type="text"
                value={shippingAddress.firstName}
                onChange={(e) => handleShippingChange('firstName', e.target.value)}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                required
              />
              {shippingErrors.firstName && (
                <p className="mt-1 text-sm text-red-600">{shippingErrors.firstName}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Last Name
              </label>
              <input
                type="text"
                value={shippingAddress.lastName}
                onChange={(e) => handleShippingChange('lastName', e.target.value)}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                required
              />
              {shippingErrors.lastName && (
                <p className="mt-1 text-sm text-red-600">{shippingErrors.lastName}</p>
              )}
            </div>
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Address
              </label>
              <input
                type="text"
                value={shippingAddress.address}
                onChange={(e) => handleShippingChange('address', e.target.value)}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                required
              />
              {shippingErrors.address && (
                <p className="mt-1 text-sm text-red-600">{shippingErrors.address}</p>
              )}
            </div>
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Address Line 2 (Optional)
              </label>
              <input
                type="text"
                value={shippingAddress.address2}
                onChange={(e) => handleShippingChange('address2', e.target.value)}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                placeholder="Apartment, suite, unit, etc."
              />
            </div>
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Address Line 3 (Optional)
              </label>
              <input
                type="text"
                value={shippingAddress.address3}
                onChange={(e) => handleShippingChange('address3', e.target.value)}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                placeholder="Additional address information"
              />
            </div>
            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Postcode
              </label>
              <input
                type="text"
                value={shippingAddress.postcode}
                onChange={(e) => handleShippingChange('postcode', e.target.value)}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                required
              />
              {shippingErrors.postcode && (
                <p className="mt-1 text-sm text-red-600">{shippingErrors.postcode}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
              </label>
              <input
                type="email"
                value={shippingAddress.email}
                onChange={(e) => handleShippingChange('email', e.target.value)}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                required
                disabled={!!userProfile}
                placeholder="Enter your email address"
              />
              {shippingErrors.email && (
                <p className="mt-1 text-sm text-red-600">{shippingErrors.email}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Telephone
              </label>
              <input
                type="tel"
                value={shippingAddress.telephone}
                onChange={(e) => handleShippingChange('telephone', e.target.value)}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                required
                placeholder="Enter your telephone number"
              />
              {shippingErrors.telephone && (
                <p className="mt-1 text-sm text-red-600">{shippingErrors.telephone}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Country
              </label>
              <input
                type="text"
                value={shippingAddress.country}
                onChange={(e) => handleShippingChange('country', e.target.value)}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                required
                placeholder="Enter your country"
              />
              {shippingErrors.country && (
                <p className="mt-1 text-sm text-red-600">{shippingErrors.country}</p>
              )}
            </div>
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-bold mb-4">Payment Method</h2>
          <div className="space-y-4">
            <div className="flex items-center p-4 border rounded-lg">
              <CreditCard className="h-6 w-6 text-gray-400 mr-3" />
              <div className="flex-grow">
                <div className="font-medium">Credit Card</div>
                <div className="text-sm text-gray-500">
                  Visa, Mastercard, American Express
                </div>
              </div>
              <input
                type="radio"
                name="payment"
                className="h-4 w-4 text-yellow-400"
                defaultChecked
              />
            </div>
          </div>
        </div>
      </div>

      <div className="lg:col-span-1">
        <div className="bg-white p-6 rounded-lg shadow sticky top-6">
          <h2 className="text-xl font-bold mb-4">Order Summary</h2>
          <div className="divide-y">
            {state.items.map((item) => (
              <div key={item.id} className="flex items-center space-x-4 py-4">
                <img
                  src={getProductImage(item)}
                  alt={item.title}
                  className="w-16 h-16 object-contain"
                />
                <div className="flex-grow">
                  <div className="font-medium line-clamp-1">{item.title}</div>
                  <div className="text-sm text-gray-500">
                    Qty: {item.quantity} × {formatPrice(item.price, currency)}
                    <span className="text-xs text-gray-500 ml-1">per item</span>
                  </div>
                  <div className="text-xs text-gray-500">
                    Base: {formatPrice(item.price * item.quantity, currency)} + VAT: {formatPrice(item.price * item.quantity * taxRate / 100, currency)}
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <div className="font-medium">
                    {formatPrice(item.price * item.quantity * (1 + taxRate/100), currency)}
                  </div>
                  <button
                    onClick={() => removeItem(item.id)}
                    className="text-red-500 hover:text-red-700 transition-colors"
                    title="Remove item"
                  >
                    <Trash2 className="h-5 w-5" />
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="border-t mt-6 pt-4 space-y-2">
            <div className="flex justify-between">
              <span>Subtotal</span>
              <span>{formatPrice(totals.baseAmount, currency)}</span>
            </div>
            <div className="flex justify-between text-sm">
              <span>VAT ({taxRate}%)</span>
              <span>{formatPrice(totals.taxAmount, currency)}</span>
            </div>
            <ShippingSummary items={state.items} />
            <div className="flex justify-between font-bold text-lg">
              <span>Total</span>
              <span>{formatPrice(totals.finalTotal, currency)}</span>
            </div>
          </div>

          <div className="mt-6 space-y-6">
            <div className="border-t pt-6">
              <h3 className="font-semibold mb-4">Payment Method</h3>
              <div className="grid grid-cols-2 gap-4">
                <button
                  onClick={() => setPaymentType('card')}
                  className={`p-4 rounded-lg border-2 transition-colors ${
                    paymentType === 'card'
                      ? 'border-yellow-400 bg-yellow-50'
                      : 'border-gray-200 hover:border-gray-300'
                  }`}
                >
                  <CreditCard className="h-6 w-6 mx-auto mb-2" />
                  <div className="text-sm font-medium">Credit Card</div>
                </button>
                <button
                  onClick={() => setPaymentType('crypto')}
                  className={`p-4 rounded-lg border-2 transition-colors ${
                    paymentType === 'crypto'
                      ? 'border-yellow-400 bg-yellow-50'
                      : 'border-gray-200 hover:border-gray-300'
                  }`}
                >
                  <Bitcoin className="h-6 w-6 mx-auto mb-2" />
                  <div className="text-sm font-medium">Cryptocurrency</div>
                </button>
              </div>
            </div>

            <div className="border-t pt-6">
              {paymentType === 'card' ? (
                <CreditCardForm 
                  shippingDetails={shippingAddress}
                  totals={totals}
                  qualifiesForFreeShipping={qualifiesForFreeShipping}
                />
              ) : (
                <CryptoPayment 
                  amount={totals.finalTotal}
                  currency={currency} 
                  surchargeRate={cryptoSurchargeRate}
                  shippingDetails={shippingAddress}
                  items={state.items}
                  taxRate={taxRate}
                />
              )}
            </div>
          </div>
          
          <div className="mt-2 text-xs text-gray-500 text-center">
            All prices include VAT at {taxRate}%
          </div>
        </div>
      </div>
    </div>
  );
}
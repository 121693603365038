import { ThemeConfig } from '../types/theme';

export const valentinesTheme: ThemeConfig = {
  id: 'valentines',
  name: 'Valentine\'s Day Theme',
  description: 'Romantic theme with hearts and soft colors',
  colors: {
    primary: '#BE185D', // pink-700
    secondary: '#FDF2F8', // pink-50
    accent: '#FB7185', // rose-400
    background: '#FFF1F2', // rose-50
    text: '#881337', // rose-900
    border: '#FCE7F3', // pink-100
  },
  assets: {
    backgroundImage: 'https://images.unsplash.com/photo-1518199266791-5375a83190b7?w=2000&auto=format&fit=crop&q=80',
    headerImage: 'https://images.unsplash.com/photo-1518895312237-a9e23508077d?w=2000&auto=format&fit=crop&q=80',
    overlayEffect: 'hearts',
    animations: ['float', 'pulse']
  },
  isActive: false,
  lastModified: new Date().toISOString(),
  modifiedBy: 'system'
};
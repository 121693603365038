import React, { useState } from 'react';
import { Save, X, Eye } from 'lucide-react';
import { Product } from '../../types/product';
import { db } from '../../config/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import { useCategories } from '../../hooks/useCategories';
import ImageGallerySlot from './ImageGallerySlot';
import { SEOGenerator } from '../../modules/seo/components';

interface ProductEditorProps {
  product: Product;
  onClose: () => void;
}

interface ValidationErrors {
  [key: string]: string;
}

export default function ProductEditor({ product, onClose }: ProductEditorProps) {
  const { userProfile } = useAuth();
  const { categories, loading: categoriesLoading } = useCategories();
  const [formData, setFormData] = useState<Product>({
    ...product,
    category: product.category || '',
    shipping_cost: typeof product.shipping_cost === 'number' ? product.shipping_cost : 0,
    stock: product.stock || {
      quantity: product.stock?.quantity || 0,
      threshold: product.stock?.threshold || 5,
      status: product.stock?.status || 'out-of-stock',
      lastUpdated: product.stock?.lastUpdated || new Date().toISOString()
    },
    imageSlot1: product.imageSlot1 || { url: '', isFeatured: true, uploadedAt: new Date().toISOString() },
    imageSlot2: product.imageSlot2 || { url: '', isFeatured: false, uploadedAt: new Date().toISOString() },
    imageSlot3: product.imageSlot3 || { url: '', isFeatured: false, uploadedAt: new Date().toISOString() },
    imageSlot4: product.imageSlot4 || { url: '', isFeatured: false, uploadedAt: new Date().toISOString() },
    imageSlot5: product.imageSlot5 || { url: '', isFeatured: false, uploadedAt: new Date().toISOString() },
    imageSlot6: product.imageSlot6 || { url: '', isFeatured: false, uploadedAt: new Date().toISOString() },
    imageSlot7: product.imageSlot7 || { url: '', isFeatured: false, uploadedAt: new Date().toISOString() },
    imageSlot8: product.imageSlot8 || { url: '', isFeatured: false, uploadedAt: new Date().toISOString() },
    imageSlot9: product.imageSlot9 || { url: '', isFeatured: false, uploadedAt: new Date().toISOString() },
  });
  const [errors, setErrors] = useState<ValidationErrors>({});
  const [isPreview, setIsPreview] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [saveStatus, setSaveStatus] = useState<'idle' | 'success' | 'error'>('idle');

  const validateForm = (): boolean => {
    const newErrors: ValidationErrors = {};
    
    if (!formData.title.trim()) {
      newErrors.title = 'Product name is required';
    }
    
    if (formData.price <= 0) {
      newErrors.price = 'Price must be greater than 0';
    }
    
    if (!formData.description.trim()) {
      newErrors.description = 'Description is required';
    }
    
    if (!formData.category.trim()) {
      newErrors.category = 'Category is required';
    }

    // Image validation - only validate if we have images
    const validImages = Array.from({ length: 9 }, (_, i) => {
      const slotKey = `imageSlot${i + 1}` as keyof Product;
      const slot = formData[slotKey];
      return slot && typeof slot === 'object' && 'url' in slot && slot.url.trim().startsWith('http');
    });

    if (!validImages.some(Boolean)) {
      newErrors.images = 'At least one valid image is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsSaving(true);
    try {
      const productRef = doc(db, 'products', product.id);
      await updateDoc(productRef, {
        ...formData,
        lastModified: new Date().toISOString(),
        modifiedBy: userProfile?.email
      });

      setSaveStatus('success');
    } catch (error) {
      console.error('Error updating product:', error);
      setSaveStatus('error');
    } finally {
      setIsSaving(false);
    }
  };

  // Rest of the component remains the same...
  // (The UI portion of the component stays unchanged)
}
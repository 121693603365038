import React from 'react';
import { useDebug } from '../hooks/useDebug';
import { useLocation } from 'react-router-dom';
import { Hash } from 'lucide-react';

export default function PageIdentifier() {
  const { debugMode } = useDebug();
  const location = useLocation();
  const pageId = `page-${location.pathname.replace(/\//g, '-').replace(/[^a-zA-Z0-9-]/g, '')}`;

  if (!debugMode) return null;

  return (
    <div className="fixed bottom-4 right-4 bg-black bg-opacity-75 text-white px-4 py-2 rounded-lg text-sm font-mono z-[9999] flex items-center space-x-2">
      <Hash className="h-4 w-4" />
      <span>{pageId}</span>
    </div>
  );
}
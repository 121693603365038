import { collection, query, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';

export async function getUniqueCategories(): Promise<string[]> {
  try {
    const productsRef = collection(db, 'products');
    const querySnapshot = await getDocs(query(productsRef));
    
    const categories = new Set<string>();    
    querySnapshot.forEach(doc => {
      const data = doc.data();
      if (data.category && typeof data.category === 'string') {
        categories.add(data.category);
      }
    });

    return Array.from(categories).sort((a, b) => a.localeCompare(b));
  } catch (error) {
    console.error('Error fetching categories:', error);
    return [];
  }
}
import React, { useState } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { Order } from '../../../types/order';
import OrderList from './OrderList';

interface OrderArchiveProps {
  orders: Order[];
  onOrderClick: (orderId: string) => void;
  searchTerm: string;
}

export default function OrderArchive({ orders, onOrderClick, searchTerm }: OrderArchiveProps) {
  const [isExpanded, setIsExpanded] = useState(false);

  const archivedOrders = orders.filter(order => 
    ['cancelled', 'refunded', 'delivered', 'returned'].includes(order.status)
  );

  const matchingArchivedOrders = archivedOrders.filter(order =>
    order.orderNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.customerEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.customerName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const shouldShow = searchTerm ? matchingArchivedOrders.length > 0 : true;

  if (!shouldShow) return null;

  return (
    <div className="mt-6">
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="w-full flex items-center justify-between p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
      >
        <div className="flex items-center space-x-2">
          {isExpanded ? (
            <ChevronDown className="h-5 w-5 text-gray-500" />
          ) : (
            <ChevronRight className="h-5 w-5 text-gray-500" />
          )}
          <span className="font-medium">Archived Orders</span>
          <span className="text-sm text-gray-500">
            ({archivedOrders.length} orders)
          </span>
        </div>
      </button>

      {(isExpanded || searchTerm) && (
        <div className="mt-4">
          <OrderList
            orders={searchTerm ? matchingArchivedOrders : archivedOrders}
            onOrderClick={onOrderClick}
          />
        </div>
      )}
    </div>
  );
}
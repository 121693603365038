import React, { useState, useEffect } from 'react';
import { Users, Package, ShoppingBag, Activity } from 'lucide-react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, auth } from '../../config/firebase';
import { fetchAdminStats } from '../../utils/admin/statsUtils';

export default function AdminStats() {
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    totalProducts: 0,
    activeProducts: 0,
    totalOrders: 0,
    pendingOrders: 0,
    processingOrders: 0,
    shippedOrders: 0
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const newStats = await fetchAdminStats();
        setStats(newStats);
      } catch (error) {
        if (error.code === 'permission-denied') {
          console.error('Permission denied. Please ensure you are logged in as admin.');
        } else {
          console.error('Error fetching stats:', error);
        }
      }
    };

    fetchStats();
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      <div className="bg-white p-6 rounded-lg shadow-sm">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-gray-500">Total Users</p>
            <h3 className="text-2xl font-bold">{stats.totalUsers}</h3>
          </div>
          <Users className="h-8 w-8 text-blue-500" />
        </div>
        <p className="text-sm text-gray-600 mt-2">
          {stats.activeUsers} active users
        </p>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-sm">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-gray-500">Total Products</p>
            <h3 className="text-2xl font-bold">{stats.totalProducts}</h3>
          </div>
          <Package className="h-8 w-8 text-green-500" />
        </div>
        <p className="text-sm text-gray-600 mt-2">
          {stats.activeProducts} active products
        </p>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-sm">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-gray-500">Orders Overview</p>
            <h3 className="text-2xl font-bold">{stats.totalOrders}</h3>
          </div>
          <ShoppingBag className="h-8 w-8 text-purple-500" />
        </div>
        <p className="text-sm text-gray-600 mt-2">
          {stats.pendingOrders} pending · {stats.processingOrders} processing · {stats.shippedOrders} shipped
        </p>
      </div>

      <div className="bg-white p-6 rounded-lg shadow-sm">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-gray-500">System Status</p>
            <h3 className="text-2xl font-bold text-green-500">Active</h3>
          </div>
          <Activity className="h-8 w-8 text-green-500" />
        </div>
        <p className="text-sm text-gray-600 mt-2">
          All systems operational
        </p>
      </div>
    </div>
  );
}
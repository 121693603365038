import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../config/firebase';
import { collection, query, where, getDocs, onSnapshot } from 'firebase/firestore';
import { ThemeConfig } from '../types/theme';
import { christmasTheme } from '../themes/christmas';
import { saveThemeChanges, initializeThemes } from '../utils/themeUtils';
import { verifyAdminAccess } from '../utils/auth/permissions';
import { auth } from '../config/firebase';
import { quotaManager } from '../utils/auth/quotaManager';

interface ThemeContextType {
  currentTheme: ThemeConfig;
  setTheme: (theme: ThemeConfig) => Promise<void>;
  loading: boolean;
  error: string | null;
}

const ThemeContext = createContext<ThemeContextType | null>(null);

export function ThemeProvider({ children }: { children: React.ReactNode }) {
  const [currentTheme, setCurrentTheme] = useState<ThemeConfig>(christmasTheme);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTheme = async () => {
      try {
        // Initialize admin first
        await initializeAdminUser().catch(err => {
          console.debug('Admin initialization skipped:', err.message);
        });

        setLoading(true);
        
        // Initialize themes if needed
        await initializeThemes();
        
        const themesRef = collection(db, 'themes');
        const activeThemeQuery = query(themesRef, where('is_active', '==', true));
        const snapshot = await getDocs(activeThemeQuery);

        if (!snapshot.empty) {
          const data = snapshot.docs[0].data();
          const themeConfig: ThemeConfig = {
            id: data.theme_id,
            name: data.name,
            description: data.description,
            colors: data.colors,
            assets: data.assets,
            isActive: data.is_active,
            lastModified: data.last_modified,
            modifiedBy: data.modified_by
          };
          setCurrentTheme(themeConfig);
        } else { 
          setCurrentTheme(christmasTheme);
        }
      } catch (err) {
        setCurrentTheme(christmasTheme);
      } finally {
        setLoading(false);
      }
    };

    fetchTheme();

    // Set up real-time listener
    try {
      const themesRef = collection(db, 'themes');
      const activeThemeQuery = query(themesRef, where('is_active', '==', true));
      
      const unsubscribe = onSnapshot(activeThemeQuery, (snapshot) => {
        if (!snapshot.empty) {
          const data = snapshot.docs[0].data();
          setCurrentTheme({
            id: data.theme_id,
            name: data.name,
            description: data.description,
            colors: data.colors,
            assets: data.assets,
            isActive: data.is_active,
            lastModified: data.last_modified,
            modifiedBy: data.modified_by
          });
        }
      });

      return () => unsubscribe();
    } catch (err) {
      // Silently handle subscription errors
      console.debug('Real-time subscription not available');
    }
  }, []);

  const setTheme = async (theme: ThemeConfig) => {
    try {
      setError(null);
      setLoading(true);

      // Check theme quota
      const canProceed = await quotaManager.checkQuota('theme_change');
      if (!canProceed) {
        const cooldown = Math.ceil(quotaManager.getRemainingCooldown('theme_change') / 60000);
        throw new Error(`Rate limit exceeded. Please try again in ${cooldown} minutes.`);
      }

      if (!auth.currentUser) {
        console.log('No user found, attempting admin initialization...');
        await initializeAdminUser();
        if (!auth.currentUser) {
          throw new Error('Please sign in as admin to save theme changes');
        }
      }

      // Verify admin access first
      const hasAccess = await verifyAdminAccess();
      if (!hasAccess) {
        throw new Error('Admin access required to modify themes');
      }

      // Validate theme object
      if (!theme.id || !theme.name || !theme.colors) {
        throw new Error('Invalid theme configuration');
      }

      const timestamp = new Date().toISOString(); 

      // Create a complete theme object
      const completeTheme = {
        ...theme,
        isActive: true,
        lastModified: timestamp,
        modifiedBy: auth.currentUser.email,
        assets: {
          ...theme.assets,
          ...(theme.id === 'christmas' && {
            hollyImage: theme.assets?.hollyImage || '',
            ornamentImage: theme.assets?.ornamentImage || ''
          }),
          ...(theme.id === 'halloween' && {
            pumpkinImage: theme.assets?.pumpkinImage || '',
            ghostImage: theme.assets?.ghostImage || ''
          }),
          ...(theme.id === 'valentines' && {
            heartImage: theme.assets?.heartImage || '',
            cupidImage: theme.assets?.cupidImage || ''
          })
        },
      };

      // Use rate-limited save utility
      try { 
        await saveThemeChanges(completeTheme);
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'Failed to save theme changes';
        console.error('Theme save error:', errorMessage);
        throw new Error(errorMessage);
      }
      
      // Update current theme state after successful save 
      setCurrentTheme(completeTheme);
      setError(null);
      
    } catch (error) {
      if (error instanceof Error && error.message?.includes('quota')) {
        quotaManager.handleQuotaExceeded('theme_change');
        const cooldown = Math.ceil(quotaManager.getRemainingCooldown('theme_change') / 60000);
        throw new Error(`Rate limit exceeded. Please try again in ${cooldown} minutes.`);
      }

      const errorMessage = error instanceof Error ? error.message : 'Failed to save theme changes';
      console.error('Theme update error:', errorMessage);
      setError(errorMessage);
      throw new Error(errorMessage);
    }
    setLoading(false);
  };

  // Enhanced useEffect for applying theme

  useEffect(() => {
    // Apply theme to document root
    const root = document.documentElement;
    
    // Reset all theme properties first
    root.style.setProperty('--theme-background-image', 'none');
    root.style.setProperty('--theme-animations', 'none');
    Object.keys(christmasTheme.colors).forEach(key => {
      root.style.setProperty(`--theme-${key}`, christmasTheme.colors[key]);
    });

    // Apply colors
    Object.entries(currentTheme.colors).forEach(([key, value]) => {
      root.style.setProperty(`--theme-${key}`, value);
    });

    // Apply background image
    if (currentTheme.assets?.backgroundImage) {
      root.style.setProperty(
        '--theme-background-image',
        `url(${currentTheme.assets.backgroundImage})`
      );
    }

    // Apply animations
    if (currentTheme.assets?.animations?.length) {
      root.style.setProperty(
        '--theme-animations',
        currentTheme.assets.animations.join(' ')
      );
    }
    
    // Force a re-render of theme-dependent components
    document.documentElement.style.setProperty('--theme-update', Date.now().toString());
  }, [currentTheme]);

  return (
    <ThemeContext.Provider value={{ currentTheme, setTheme, loading, error }}>
      {children}
    </ThemeContext.Provider>
  );
}

export function useTheme() {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
}
import React, { useState, useEffect } from 'react';
import { Truck, Plus, X, AlertCircle } from 'lucide-react';
import { db } from '../../config/firebase';
import { collection, query, where, getDocs, addDoc, updateDoc, deleteDoc, doc, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';

interface Carrier {
  id: string;
  name: string;
  status: 'active' | 'inactive';
  last_modified: string;
  modified_by: string;
}

export default function CarrierSettings() {
  const { userProfile } = useAuth();
  const [carriers, setCarriers] = useState<Carrier[]>([]);
  const [newCarrierName, setNewCarrierName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCarriers = async () => {
      try {
        const carriersQuery = query(
          collection(db, 'carriers'),
          where('status', '==', 'active')
        );
        const snapshot = await getDocs(carriersQuery);
        const carriers = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        setCarriers(carriers);
        setLoading(false);

        // If no carriers exist, add Royal Mail as default
        if (carriers.length === 0 && userProfile) {
          const timestamp = new Date().toISOString();
          await addDoc(collection(db, 'carriers'), {
              name: 'Royal Mail',
              status: 'active',
              last_modified: timestamp,
              modified_by: userProfile.email
            });
        }
      } catch (err) {
        console.error('Error fetching carriers:', err);
        setError('Failed to load carriers');
        setLoading(false);
      }
    };

    fetchCarriers();

    // Set up real-time listener
    const unsubscribe = onSnapshot(
      query(collection(db, 'carriers')),
      (snapshot) => {
        const carrierData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCarriers(carrierData);
      },
      (err) => {
        console.error('Error in carriers subscription:', err);
        setError('Failed to sync carrier updates');
      }
    );

    return () => unsubscribe();
  }, [userProfile]);

  const handleAddCarrier = async () => {
    if (!userProfile) return;
    if (!newCarrierName.trim()) {
      setError('Carrier name is required');
      return;
    }

    try {
      const timestamp = new Date().toISOString();
      await addDoc(collection(db, 'carriers'), {
        name: newCarrierName.trim(),
        status: 'active',
        last_modified: timestamp,
        modified_by: userProfile.email
      });
      
      setNewCarrierName('');
      setError(null);
    } catch (err) {
      setError('Failed to add carrier');
    }
  };

  const handleDeleteCarrier = async (carrierId: string) => {
    if (carriers.length <= 1) {
      setError('At least one carrier must remain active');
      return;
    }

    try {
      await deleteDoc(doc(db, 'carriers', carrierId));
      
      setError(null);
    } catch (err) {
      setError('Failed to delete carrier');
    }
  };

  const toggleCarrierStatus = async (carrier: Carrier) => {
    if (!userProfile) return;

    // Prevent deactivating if it's the last active carrier
    if (carrier.status === 'active' && carriers.filter(c => c.status === 'active').length <= 1) {
      setError('At least one carrier must remain active');
      return;
    }

    try {
      const timestamp = new Date().toISOString();
      await updateDoc(doc(db, 'carriers', carrier.id), {
        status: carrier.status === 'active' ? 'inactive' : 'active',
        last_modified: timestamp,
        modified_by: userProfile.email
      });
      setError(null);
    } catch (err) {
      setError('Failed to update carrier status');
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
        <div className="space-y-4">
          <div className="h-10 bg-gray-200 rounded"></div>
          <div className="h-10 bg-gray-200 rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl">
      <div className="flex items-center space-x-2 mb-6">
        <Truck className="h-6 w-6 text-gray-700" />
        <h2 className="text-xl font-bold">Shipping Carriers</h2>
      </div>

      <div className="space-y-6">
        {/* Add New Carrier */}
        <div className="flex space-x-4">
          <input
            type="text"
            value={newCarrierName}
            onChange={(e) => setNewCarrierName(e.target.value)}
            placeholder="Enter carrier name"
            className="flex-1 p-2 border rounded-lg focus:ring-2 ring-yellow-400"
          />
          <button
            onClick={handleAddCarrier}
            className="px-4 py-2 bg-yellow-400 hover:bg-yellow-500 rounded-lg flex items-center space-x-2"
          >
            <Plus className="h-5 w-5" />
            <span>Add Carrier</span>
          </button>
        </div>

        {/* Error Message */}
        {error && (
          <div className="bg-red-50 text-red-700 p-4 rounded-lg flex items-start space-x-2">
            <AlertCircle className="h-5 w-5 mt-0.5" />
            <span>{error}</span>
          </div>
        )}

        {/* Carriers List */}
        <div className="space-y-4">
          {carriers.map((carrier) => (
            <div
              key={carrier.id}
              className="flex items-center justify-between p-4 border rounded-lg"
            >
              <div className="flex items-center space-x-4">
                <Truck className="h-5 w-5 text-gray-400" />
                <span className="font-medium">{carrier.name}</span>
              </div>

              <div className="flex items-center space-x-4">
                <button
                  onClick={() => toggleCarrierStatus(carrier)}
                  className={`px-3 py-1 rounded-full text-sm ${
                    carrier.status === 'active'
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                  }`}
                >
                  {carrier.status}
                </button>
                <button
                  onClick={() => handleDeleteCarrier(carrier.id)}
                  className="text-red-500 hover:text-red-700"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export async function checkNetworkConnectivity(): Promise<boolean> {
  if (!navigator.onLine) return false;

  const TIMEOUT = 5000;
  const RETRY_ATTEMPTS = 3;
  const RETRY_DELAY = 1000;
  const ENDPOINTS = [
    'https://www.google.com/favicon.ico',
    'https://www.cloudflare.com/favicon.ico',
    'https://www.microsoft.com/favicon.ico'
  ];

  for (let attempt = 0; attempt < RETRY_ATTEMPTS; attempt++) {
    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), TIMEOUT);

      // Try multiple endpoints in case one is blocked
      for (const endpoint of ENDPOINTS) {
        try {
          const response = await fetch(endpoint, {
            mode: 'no-cors',
            signal: controller.signal,
            cache: 'no-store'
          });
          
          clearTimeout(timeoutId);
          if (response.type === 'opaque') {
            return true;
          }
        } catch {
          continue;
        }
      }

      // If all endpoints failed, wait and retry
      if (attempt < RETRY_ATTEMPTS - 1) {
        await new Promise(resolve => 
          setTimeout(resolve, RETRY_DELAY * Math.pow(2, attempt))
        );
      }
    } catch (error) {
      if (attempt < RETRY_ATTEMPTS - 1) {
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * Math.pow(2, attempt)));
        continue;
      }
      return false;
    }
  }

  return false;
}

export function addNetworkListeners(
  onOnline: () => void,
  onOffline: () => void
): () => void {
  let reconnectTimeout: NodeJS.Timeout;

  const handleOnline = async () => {
    // Clear any existing timeout
    if (reconnectTimeout) {
      clearTimeout(reconnectTimeout);
    }

    // Verify actual connectivity before calling handler
    const isConnected = await checkNetworkConnectivity();
    if (isConnected) {
      onOnline();
    } else {
      // If connectivity check fails, retry after delay
      reconnectTimeout = setTimeout(handleOnline, 5000);
    }
  };

  window.addEventListener('online', handleOnline);
  window.addEventListener('offline', onOffline);

  return () => {
    window.removeEventListener('online', handleOnline);
    window.removeEventListener('offline', onOffline);
    if (reconnectTimeout) {
      clearTimeout(reconnectTimeout);
    }
  };
}
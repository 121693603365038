import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import { UserProfile } from '../types/auth';

export async function listUsers(): Promise<UserProfile[]> {
  try {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, orderBy('name'));
    const querySnapshot = await getDocs(q);

    const users: UserProfile[] = [];
    querySnapshot.forEach(doc => {
      const data = doc.data();
      users.push({
        id: doc.id,
        name: data.name || '',
        email: data.email,
        role: data.role,
        status: data.status,
        address: data.address || '',
        address2: data.address2 || '',
        address3: data.address3 || '',
        postcode: data.postcode || '',
        telephone: data.telephone || '',
        country: data.country || '',
        registrationDate: data.registration_date,
        lastLoginDate: data.last_login || undefined,
        lastModified: data.last_modified,
        modifiedBy: data.modified_by || undefined
      });
    });

    return users;
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
}
import { QuotaConfig, QuotaStatus } from './types';

const DEFAULT_CONFIG: QuotaConfig = {
  maxAttempts: 50,
  windowMs: 5 * 60 * 1000, // 5 minutes
  cooldownBase: 5 * 60 * 1000, // 5 minutes
  globalCooldown: 30 * 60 * 1000, // 30 minutes
  retryDelay: 1000 // 1 second
};

class QuotaManager {
  private static instance: QuotaManager;
  private state: {
    quotaExceededUntil: number;
    globalQuotaExceededUntil: number;
    operations: Record<string, {
      attempts: number;
      lastAttempt: number;
      cooldownUntil: number;
      errors: Array<{
        code: string;
        timestamp: number;
      }>;
    }>;
    windowStart: number;
  };
  private config: QuotaConfig;
  private static quotaWarningThreshold = 0.8; // 80% of max attempts
  private static THEME_QUOTA = {
    maxAttempts: 10,
    windowMs: 30 * 60 * 1000, // 30 minutes
    cooldownBase: 30 * 60 * 1000 // 30 minutes
  };

  private constructor(config: Partial<QuotaConfig> = {}) {
    this.config = {
      ...DEFAULT_CONFIG,
      maxAttempts: 20, // Further reduced to prevent quota issues
      windowMs: 5 * 60 * 1000, // 5 minutes
      ...config
    };
    this.state = {
      quotaExceededUntil: 0,
      globalQuotaExceededUntil: 0,
      operations: {},
      windowStart: Date.now()
    };
    this.loadState();
  }

  static getInstance(): QuotaManager {
    if (!QuotaManager.instance) {
      QuotaManager.instance = new QuotaManager();
    }
    return QuotaManager.instance;
  }

  private loadState() {
    try {
      const saved = localStorage.getItem('quotaState');
      if (saved) {
        this.state = JSON.parse(saved);
        // Reset if window has expired
        if (Date.now() - this.state.windowStart > this.config.windowMs) {
          this.reset();
        }
      }
    } catch (error) {
      console.error('Error loading quota state:', error);
      this.reset();
    }
  }

  private saveState() {
    try {
      localStorage.setItem('quotaState', JSON.stringify(this.state));
    } catch (error) {
      console.error('Error saving quota state:', error);
    }
  }

  async checkQuota(operation = 'default'): Promise<boolean> {
    const now = Date.now();

    // Use stricter limits for theme operations
    const config = operation.startsWith('theme_') ? 
      QuotaManager.THEME_QUOTA : this.config;

    // Check if we should show a warning
    const remainingAttempts = config.maxAttempts - (this.state.operations[operation]?.attempts || 0);
    const warningThreshold = Math.floor(config.maxAttempts * QuotaManager.quotaWarningThreshold);
    
    if (remainingAttempts <= warningThreshold) {
      console.warn(`Warning: ${remainingAttempts} attempts remaining for ${operation}`);
    }

    // Initialize operation state if needed
    if (!this.state.operations[operation]) {
      this.state.operations[operation] = {
        attempts: 0,
        lastAttempt: 0,
        cooldownUntil: 0,
        errors: []
      };
    }

    const opState = this.state.operations[operation];

    // Check global quota exceeded
    if (this.state.globalQuotaExceededUntil > now) {
      return false;
    }

    // Check operation-specific cooldown
    if (opState.cooldownUntil > now) {
      return false;
    }

    // Enforce minimum delay between attempts
    const timeSinceLastAttempt = now - opState.lastAttempt;
    if (timeSinceLastAttempt < this.config.retryDelay) {
      await new Promise(resolve => setTimeout(resolve, this.config.retryDelay - timeSinceLastAttempt));
    }

    // Update attempt count
    opState.attempts++;
    opState.lastAttempt = now;

    // Check if exceeded max attempts
    if (opState.attempts > this.config.maxAttempts) {
      this.handleQuotaExceeded(operation);
      const cooldown = this.getRemainingCooldown(operation);
      console.warn(`Rate limit exceeded for ${operation}. Cooldown: ${Math.ceil(cooldown / 60000)} minutes`);
      return false;
    }

    this.saveState();
    return true;
  }

  handleQuotaExceeded(operation = 'default') {
    const now = Date.now();
    const opState = this.state.operations[operation];

    // Track error
    opState.errors.push({
      code: 'quota_exceeded',
      timestamp: now
    });
    
    // Calculate cooldown with exponential backoff
    const cooldownMultiplier = Math.min(Math.pow(2, opState.attempts - this.config.maxAttempts), 6);
    const cooldownDuration = this.config.cooldownBase * cooldownMultiplier;

    // Set operation-specific cooldown
    opState.cooldownUntil = now + cooldownDuration;

    const recentErrors = opState.errors.filter(e => 
      now - e.timestamp < this.config.windowMs
    ).length;
    
    // Set global cooldown if too many attempts
    if (recentErrors >= 5 || opState.attempts >= this.config.maxAttempts * 2) {
      this.state.globalQuotaExceededUntil = now + this.config.globalCooldown;
    }

    this.saveState();
  }

  getRemainingCooldown(operation = 'default'): number {
    const now = Date.now();
    const opState = this.state.operations[operation];
    if (!opState) return 0;

    return Math.max(
      opState.cooldownUntil - now,
      this.state.globalQuotaExceededUntil - now,
      0
    );
  }

  reset() {
    this.state = {
      quotaExceededUntil: 0,
      globalQuotaExceededUntil: 0,
      operations: {},
      windowStart: Date.now()
    };
    this.saveState();
  }

  getQuotaStatus(operation = 'default'): QuotaStatus {
    const now = Date.now();
    const opState = this.state.operations[operation] || {
      attempts: 0,
      lastAttempt: 0,
      cooldownUntil: 0
    };

    return {
      attempts: opState.attempts,
      remainingAttempts: Math.max(0, this.config.maxAttempts - opState.attempts),
      globalCooldown: Math.max(0, this.state.globalQuotaExceededUntil - now),
      individualCooldown: Math.max(0, opState.cooldownUntil - now),
      windowResetIn: Math.max(0, (this.state.windowStart + this.config.windowMs) - now)
    };
  }
}

export const quotaManager = QuotaManager.getInstance();
import React, { useState, useEffect } from 'react';
import { Wallet, Save, AlertCircle } from 'lucide-react';
import { db } from '../../config/firebase';
import { collection, query, where, getDocs, doc, updateDoc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import { validateCryptoAddress } from '../../utils/cryptoUtils';

interface XRPAddress {
  address: string;
  tag?: string;
}

interface CryptoAddresses {
  BTC?: string;
  ETH?: string;
  XRP?: XRPAddress;
  lastModified?: string;
  modifiedBy?: string;
}

export default function CryptoAddressSettings() {
  const { userProfile } = useAuth();
  const [addresses, setAddresses] = useState<CryptoAddresses>({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);
  const [surchargeRate, setSurchargeRate] = useState(0.5);
  const [savingFee, setSavingFee] = useState(false);
  const [feeSuccess, setFeeSuccess] = useState(false);

  useEffect(() => {
    async function fetchSettings() {
      try {
        const settingsRef = doc(db, 'settings', 'site_settings');
        const settingsSnap = await getDoc(settingsRef);

        if (!settingsSnap.exists()) {
          throw new Error('Settings not found');
        }

        const data = settingsSnap.data();
        const addresses: CryptoAddresses = {
          BTC: data.btc_address || '',
          ETH: data.eth_address || '',
          XRP: {
            address: data.xrp_address || '',
            tag: data.xrp_tag
          },
          lastModified: data.last_modified,
          modifiedBy: data.modified_by
        };

        setAddresses(addresses);
        setSurchargeRate(data.crypto_surcharge_rate ?? 0.5);
      } catch (err) {
        setError('Failed to load crypto settings');
        console.error('Error fetching settings:', err);
      } finally {
        setLoading(false);
      }
    }

    fetchSettings();
  }, []);

  const handleSave = async () => {
    if (!userProfile) return;
    
    setSaving(true);
    setError(null);
    setSuccess(false);

    try {
      // Validate each address before saving
      if (addresses.BTC && !validateCryptoAddress(addresses.BTC, 'BTC')) {
        setError('Invalid BTC address format');
        setSaving(false);
        return;
      }
      
      if (addresses.ETH && !validateCryptoAddress(addresses.ETH, 'ETH')) {
        setError('Invalid ETH address format');
        setSaving(false);
        return;
      }
      
      if (addresses.XRP?.address && !validateCryptoAddress(addresses.XRP, 'XRP')) {
        setError('Invalid XRP address format');
        setSaving(false);
        return;
      }

      const timestamp = new Date().toISOString();

      // Update crypto settings
      const settingsRef = doc(db, 'settings', 'site_settings');
      await updateDoc(settingsRef, {
        btc_address: addresses.BTC || '',
        eth_address: addresses.ETH || '',
        xrp_address: addresses.XRP?.address || '',
        xrp_tag: addresses.XRP?.tag || '',
        crypto_surcharge_rate: surchargeRate,
        last_modified: timestamp,
        modified_by: userProfile.email
      });

      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
    } catch (err) {
      setError('Failed to save addresses');
      console.error('Error saving addresses:', err);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
        <div className="space-y-4">
          <div className="h-10 bg-gray-200 rounded"></div>
          <div className="h-10 bg-gray-200 rounded"></div>
          <div className="h-10 bg-gray-200 rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-2xl">
      <h2 className="text-xl font-bold mb-6">Cryptocurrency Payment Addresses</h2>
      
      <div className="mb-6 p-4 bg-white rounded-lg border">
        <h3 className="font-medium mb-2">Processing Fee</h3>
        <div className="flex items-center justify-between mb-2">
          <div className="flex items-center space-x-2">
            <input
              type="number"
              value={surchargeRate}
              onChange={(e) => {
                const value = Math.min(100, Math.max(0, Number(e.target.value)));
                setSurchargeRate(value);
              }}
              step="0.01"
              min="0"
              max="100"
              className="w-24 p-2 border rounded-lg focus:ring-2 ring-yellow-400"
            />
            <span className="text-gray-600">%</span>
          </div>
          <button
            onClick={async () => {
              if (!userProfile) return;
              setSavingFee(true);
              try {
                const siteSettingsRef = doc(db, 'site_settings', '1');
                await updateDoc(siteSettingsRef, {
                  crypto_surcharge_rate: surchargeRate,
                  last_modified: new Date().toISOString(),
                  modified_by: userProfile.email
                });

                setFeeSuccess(true);
                setTimeout(() => setFeeSuccess(false), 3000);
              } catch (err) {
                setError('Failed to save processing fee');
              } finally {
                setSavingFee(false);
              }
            }}
            disabled={savingFee}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
              savingFee
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-yellow-400 hover:bg-yellow-500'
            }`}
          >
            <Save className="h-5 w-5" />
            <span>{savingFee ? 'Saving...' : 'Update Fee'}</span>
          </button>
        </div>
        {feeSuccess && (
          <div className="text-sm text-green-600 mb-2">
            Processing fee updated successfully
          </div>
        )}
        <p className="text-sm text-gray-500 mt-1">
          Additional fee applied to cryptocurrency payments
        </p>
      </div>
      
      <div className="space-y-6">
        {['BTC', 'ETH'].map((currency) => (
          <div key={currency}>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              {currency} Address
            </label>
            <div className="relative">
              <input
                type="text"
                value={addresses[currency as keyof CryptoAddresses] || ''}
                onChange={(e) => setAddresses({
                  ...addresses,
                  [currency]: e.target.value
                })}
                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 ring-yellow-400"
                placeholder={`Enter ${currency} wallet address`}
              />
              <Wallet className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
          </div>
        ))}
        
        {/* XRP with Destination Tag */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            XRP Address
          </label>
          <div className="relative">
            <input
              type="text"
              value={addresses.XRP?.address || ''}
              onChange={(e) => setAddresses({
                ...addresses,
                XRP: { ...addresses.XRP, address: e.target.value }
              })}
              className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 ring-yellow-400"
              placeholder="Enter XRP wallet address"
            />
            <Wallet className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
          
          <div className="mt-2">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              XRP Destination Tag (Optional)
            </label>
            <input
              type="text"
              value={addresses.XRP?.tag || ''}
              onChange={(e) => {
                const tag = e.target.value.replace(/\D/g, '');
                setAddresses({
                  ...addresses,
                  XRP: { ...addresses.XRP, tag }
                });
              }}
              className="w-full px-4 py-2 border rounded-lg focus:ring-2 ring-yellow-400"
              placeholder="Enter destination tag (numbers only)"
            />
          </div>
        </div>

        <div className="flex items-center justify-between pt-4">
          <div className="text-sm text-gray-500">
            Last modified: {addresses.lastModified
              ? new Date(addresses.lastModified).toLocaleString()
              : 'Never'}
            {addresses.modifiedBy && (
              <span className="ml-1">by {addresses.modifiedBy}</span>
            )}
          </div>
          
          <button
            onClick={handleSave}
            disabled={saving}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
              saving
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-yellow-400 hover:bg-yellow-500'
            }`}
          >
            <Save className="h-5 w-5" />
            <span>{saving ? 'Saving...' : 'Save Changes'}</span>
          </button>
        </div>

        {error && (
          <div className="bg-red-50 text-red-700 p-4 rounded-lg flex items-start space-x-2">
            <AlertCircle className="h-5 w-5 mt-0.5" />
            <span>{error}</span>
          </div>
        )}

        {success && (
          <div className="bg-green-50 text-green-700 p-4 rounded-lg flex items-start space-x-2">
            <AlertCircle className="h-5 w-5 mt-0.5" />
            <span>Addresses saved successfully</span>
          </div>
        )}
      </div>
    </div>
  );
}
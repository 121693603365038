import React from 'react';
import { Trash2 } from 'lucide-react';
import ImageUsageIndicator from './ImageUsageIndicator';
import { StorageImage } from '../../../types/storage';
import { cn } from '../../../utils/cn';

interface ImageGridProps {
  images: StorageImage[];
  selectedImages: Set<string>;
  imageUsage: Record<string, string[]>;
  onSelect: (path: string) => void;
}

export default function ImageGrid({ 
  images, 
  selectedImages, 
  imageUsage, 
  onSelect 
}: ImageGridProps) {
  return (
    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
      {images.map((image) => {
        const isUsed = imageUsage[image.path]?.length > 0;
        const isSelected = selectedImages.has(image.path);
        const filename = image.path.split('/').pop() || '';

        return (
          <div
            key={image.path}
            className={cn(
              "relative group border-2 rounded-lg overflow-hidden",
              isSelected ? "border-blue-500" : "border-gray-200",
              "hover:border-blue-300 transition-colors"
            )}
          >
            <div className="aspect-square relative">
              <img
                src={image.url}
                alt={filename}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-opacity" />
            </div>

            <div className="p-2 bg-white border-t">
              <p className="text-sm truncate" title={filename}>
                {filename}
              </p>
              <ImageUsageIndicator usage={imageUsage[image.path]} />
            </div>

            <div className="absolute top-2 right-2 flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isSelected}
                onChange={() => onSelect(image.path)}
                className="h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
              />
            </div>

            {!isUsed && (
              <button
                className="absolute bottom-2 right-2 p-1 bg-red-100 text-red-600 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                title="Delete image"
              >
                <Trash2 className="h-4 w-4" />
              </button>
            )}
          </div>
        );
      })}
    </div>
  );
}
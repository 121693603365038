import { collection, query, where, getDocs, writeBatch, doc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { ThemeConfig } from '../types/theme';
import { christmasTheme } from '../themes/christmas';
import { halloweenTheme } from '../themes/halloween';
import { valentinesTheme } from '../themes/valentines';
import { defaultTheme } from '../themes/default';
import { RateLimiter } from './auth/rateLimiter';
import { quotaManager } from './auth/quotaManager';
import { verifyAdminAccess } from './auth/permissions';
import { withNetworkRetry } from './networkRetry';

const THEME_CACHE_KEY = 'cached_theme';
const THEME_CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

const themeRateLimiter = new RateLimiter({
  maxAttempts: 10,
  windowMs: 300000, // 5 minutes
  initialDelay: 1000,
  maxDelay: 5000,
  jitterFactor: 0.1
});

export async function initializeThemes(): Promise<void> {
  // Check cache first
  const cachedThemes = localStorage.getItem(THEME_CACHE_KEY);
  if (cachedThemes) {
    const { data, timestamp } = JSON.parse(cachedThemes);
    if (Date.now() - timestamp < THEME_CACHE_DURATION) {
      return;
    }
  }

  const themesRef = collection(db, 'themes');
  const snapshot = await getDocs(themesRef);

  if (snapshot.empty) {
    const batch = writeBatch(db);
    const timestamp = new Date().toISOString();
    const themes = [defaultTheme, christmasTheme, halloweenTheme, valentinesTheme];

    for (const theme of themes) {
      const themeRef = doc(themesRef, theme.id);
      batch.set(themeRef, {
        theme_id: theme.id,
        name: theme.name,
        description: theme.description,
        colors: theme.colors,
        assets: theme.assets || {},
        is_active: theme.id === 'christmas', // Set Christmas theme as active
        last_modified: timestamp,
        modified_by: 'system'
      });
    }
    await batch.commit();
    
    // Cache the initialized themes
    localStorage.setItem(THEME_CACHE_KEY, JSON.stringify({
      data: themes,
      timestamp: Date.now()
    }));
  }
}

export async function saveThemeChanges(theme: ThemeConfig): Promise<void> {
  try {
    // Check theme quota first
    const canProceed = await quotaManager.checkQuota('theme_save');
    if (!canProceed) {
      const cooldown = Math.ceil(quotaManager.getRemainingCooldown('theme_save') / 60000);
      throw new Error(`To prevent system overload, theme changes are limited. Please wait ${cooldown} minutes before trying again.`);
    }

    // Check auth state
    if (!auth.currentUser) {
      const cachedStatus = localStorage.getItem('admin_status');
      if (cachedStatus) {
        const { isAdmin, expiry } = JSON.parse(cachedStatus);
        if (Date.now() < expiry && isAdmin) {
          console.debug('Using cached admin status');
        } else {
          throw new Error('Please sign in as admin to save changes');
        }
      } else {
        throw new Error('Please sign in as admin to save changes');
      }
    }

    // Verify admin access
    const hasAccess = await verifyAdminAccess();
    if (!hasAccess) {
      throw new Error('Admin access required - please sign in with an admin account');
    }

    // Validate theme object
    if (!theme.id || !theme.name || !theme.colors) {
      throw new Error('Invalid theme configuration');
    }

    // Use network retry for database operations
    await withNetworkRetry(async () => {
      const themesRef = collection(db, 'themes');
      const activeThemes = await getDocs(
        query(themesRef, where('is_active', '==', true))
      );

      const batch = writeBatch(db);
      activeThemes.forEach(doc => {
        batch.update(doc.ref, { is_active: false });
      });

      const themeRef = doc(collection(db, 'themes'), theme.id);
      batch.update(themeRef, {
        theme_id: theme.id,
        name: theme.name,
        description: theme.description,
        colors: theme.colors,
        assets: theme.assets,
        is_active: true,
        last_modified: new Date().toISOString(),
        modified_by: theme.modifiedBy
      });

      await batch.commit();
    }, {
      maxAttempts: 3,
      onRetry: (attempt) => {
        console.warn(`Theme save attempt ${attempt} failed, retrying...`);
      }
    });

    // Reset limiters on success
    themeRateLimiter.reset();
    quotaManager.reset('theme_save');
    
    // Update cache
    try {
      const cacheData = {
        data: theme,
        timestamp: Date.now()
      };
      localStorage.setItem(THEME_CACHE_KEY, JSON.stringify(cacheData));
    } catch (cacheError) {
      console.warn('Failed to cache theme:', cacheError);
      // Non-critical error, continue
    }

  } catch (error) {
    if (error?.code === 'auth/quota-exceeded' || error?.message?.includes('rate limit')) {
      quotaManager.handleQuotaExceeded('theme_save');
      const cooldown = Math.ceil(quotaManager.getRemainingCooldown('theme_save') / 60000);
      throw new Error(`Theme changes are temporarily restricted to prevent system overload. Please try again in ${cooldown} minutes.`);
    }

    console.error('Theme update failed:', error);
    throw error instanceof Error ? error : new Error('Failed to update theme');
  }
}
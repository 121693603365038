import React, { createContext, useContext, useState, useEffect } from 'react';
import { db } from '../config/firebase';
import { doc, getDoc, onSnapshot, collection } from 'firebase/firestore';
import { SiteSettings } from '../types/settings';
import { getSiteSettings } from '../utils/settings';

const defaultSettings: SiteSettings = {
  siteName: 'BlackJack Ecommerce',
  currency: 'USD',
  tax: {
    rate: 20,
    inclusive: true,
    lastModified: new Date().toISOString(),
    modifiedBy: 'system'
  },
  shipping: {
    freeThreshold: 100,
    lastModified: new Date().toISOString(),
    modifiedBy: 'system'
  },
  cartSystem: {
    enabled: true,
    options: {
      showPrices: true,
      showStock: true,
      showAddToCart: true,
      showBuyNow: true,
      showShipping: true,
      showRatings: true
    },
    lastModified: new Date().toISOString(),
    modifiedBy: 'system'
  },
  lastModified: new Date().toISOString(),
  modifiedBy: 'system'
};

interface SiteContextType {
  siteName: string;
  currency: 'USD' | 'GBP' | 'EUR';
  settings: SiteSettings | null;
  loading: boolean;
  error: string | null;
}

const SiteContext = createContext<SiteContextType>({
  siteName: defaultSettings.siteName,
  currency: defaultSettings.currency,
  settings: defaultSettings,
  loading: true,
  error: null
});

export function SiteProvider({ children }: { children: React.ReactNode }) {
  const [siteName, setSiteName] = useState(defaultSettings.siteName);
  const [currency, setCurrency] = useState<'USD' | 'GBP' | 'EUR'>(defaultSettings.currency);
  const [settings, setSettings] = useState<SiteSettings>(defaultSettings);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settingsData = await getSiteSettings();
        setSettings(settingsData);
        setSiteName(settingsData.siteName);
        setCurrency(settingsData.currency);
      } catch (err) {
        console.error('Error in fetchSettings:', err);
        setSettings(defaultSettings);
        setSiteName(defaultSettings.siteName);
        setCurrency(defaultSettings.currency);
        setError('Failed to load settings');
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();

    // Set up real-time subscription
    try {
      const unsubscribe = onSnapshot(
        doc(db, 'settings', 'site_settings'),
        (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            setSettings({
              siteName: data.site_name,
              currency: data.currency,
              tax: {
                rate: data.tax_rate,
                inclusive: data.tax_inclusive,
                lastModified: data.last_modified,
                modifiedBy: data.modified_by
              },
              shipping: {
                freeThreshold: data.shipping_free_threshold,
                lastModified: data.last_modified,
                modifiedBy: data.modified_by
              },
              cartSystem: {
                enabled: data.cart_enabled,
                options: data.cart_options,
                lastModified: data.last_modified,
                modifiedBy: data.modified_by
              },
              lastModified: data.last_modified,
              modifiedBy: data.modified_by
            });
            setSiteName(data.site_name);
            setCurrency(data.currency);
          }
        }
      );
      return () => unsubscribe();
    } catch (err) {
      // Silently handle subscription errors
      console.debug('Real-time subscription not available');
    }
  }, []);

  return (
    <SiteContext.Provider value={{ siteName, currency, settings, loading, error }}>
      {children}
    </SiteContext.Provider>
  );
}

export function useSite() {
  const context = useContext(SiteContext);
  if (!context) {
    throw new Error('useSite must be used within a SiteProvider');
  }
  return context;
}
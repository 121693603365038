import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { hasAdminRole } from '../utils/auth/permissions';
import { initializeAdmin } from '../utils/auth/initAdmin';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requireAdmin?: boolean;
}

export default function ProtectedRoute({ children, requireAdmin = false }: ProtectedRouteProps) {
  const { user, userProfile, loading } = useAuth(); 
  const [adminInitialized, setAdminInitialized] = React.useState(false);

  React.useEffect(() => {
    if (requireAdmin && user && !adminInitialized) {
      initializeAdmin()
        .then(() => setAdminInitialized(true))
        .catch((error) => {
          console.error('Admin initialization failed:', error);
          setAdminInitialized(false);
        });
    }
  }, [requireAdmin, user, adminInitialized]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/" replace />;
  }

  if (requireAdmin && userProfile && !hasAdminRole(userProfile)) {
    console.log('Access denied: User is not admin');
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
}
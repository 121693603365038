import { ref, listAll, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage, auth } from '../config/firebase';
import { StorageImage } from '../types/storage';
import { verifyAdminAccess } from './auth/permissions';
import { quotaManager } from './auth/quotaManager'; 

export async function listStorageFiles(
  onProgress?: (progress: number) => void
): Promise<StorageImage[]> {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('Please sign in to access images');
    }

    // Add retry logic for token refresh
    let retryCount = 0;
    const maxRetries = 3;

    // Check quota first
    const canProceed = await quotaManager.checkQuota('storage_list');
    if (!canProceed) {
      const cooldown = Math.ceil(quotaManager.getRemainingCooldown('storage_list') / 60000);
      throw new Error(`Rate limit exceeded. Please wait ${cooldown} minutes.`);
    }

    // Force token refresh with retries
    try {
      while (retryCount < maxRetries) {
        try {
          const tokenPromise = user.getIdToken(true);
          const timeoutPromise = new Promise((_, reject) => {
            setTimeout(() => reject(new Error('Token refresh timeout')), 10000);
          });
          await Promise.race([tokenPromise, timeoutPromise]);
          break;
        } catch (error) {
          retryCount++;
          if (retryCount === maxRetries) throw error;
          await new Promise(resolve => setTimeout(resolve, 2000 * retryCount));
        }
      }
    } catch (error) {
      throw new Error('Failed to refresh authentication');
    }

    // Verify admin access
    const hasAccess = await verifyAdminAccess();
    if (!hasAccess) {
      throw new Error('Admin access required');
    }

    const categories = ['theme-headers', 'theme-backgrounds', 'products', 'decorative'];
    const images: StorageImage[] = [];
    let totalProgress = 0;

    for (const category of categories) {
      try {
        const categoryRef = ref(storage, category);
        const result = await listAll(categoryRef);

        for (const item of result.items) {
          try {
            const url = await getDownloadURL(item);
            const metadata = await item.getMetadata();

            const urlWithCors = new URL(url);
            urlWithCors.searchParams.append('alt', 'media');
            urlWithCors.searchParams.append('token', await auth.currentUser?.getIdToken() || '');

            images.push({
              path: item.fullPath,
              url: urlWithCors.toString(),
              metadata: {
                contentType: metadata.contentType || '',
                size: metadata.size,
                timeCreated: metadata.timeCreated,
                updated: metadata.updated,
                md5Hash: metadata.md5Hash || '',
                customMetadata: metadata.customMetadata || {}
              }
            });
          } catch (itemError) {
            console.warn(`Failed to load image ${item.fullPath}:`, itemError);
            continue;
          }

          totalProgress += (1 / (result.items.length * categories.length)) * 100;
          if (onProgress) {
            onProgress(Math.min(Math.round(totalProgress), 100));
          }
        }
      } catch (categoryError) {
        console.warn(`Failed to load category ${category}:`, categoryError);
        continue;
      }
    }

    return images;
  } catch (error) {
    if (error?.code === 'auth/quota-exceeded') {
      quotaManager.handleQuotaExceeded('storage_list');
      const cooldown = Math.ceil(quotaManager.getRemainingCooldown('storage_list') / 60000);
      throw new Error(`Rate limit exceeded. Please wait ${cooldown} minutes.`);
    }

    console.error('Failed to list storage files:', error);
    throw error;
  }
}

export async function getImageUsage(imagePaths: string[]): Promise<Record<string, string[]>> {
  const usage: Record<string, string[]> = {};

  // For now, return empty usage data
  // In a real implementation, this would check references in Firestore
  return usage;
}

export async function deleteStorageFile(path: string): Promise<void> {
  try {
    // Check auth state
    if (!auth.currentUser) {
      throw new Error('Authentication required');
    }

    // Check quota
    const canProceed = await quotaManager.checkQuota('storage_delete');
    if (!canProceed) {
      const cooldown = Math.ceil(quotaManager.getRemainingCooldown('storage_delete') / 60000);
      throw new Error(`Rate limit exceeded. Please wait ${cooldown} minutes.`);
    }

    // Force token refresh
    await auth.currentUser.getIdToken(true);

    // Verify admin access
    const hasAccess = await verifyAdminAccess();
    if (!hasAccess) {
      throw new Error('Admin access required to delete images');
    }

    const fileRef = ref(storage, path);
    await deleteObject(fileRef);
  } catch (error) {
    if (error?.code === 'auth/quota-exceeded') {
      quotaManager.handleQuotaExceeded('storage_delete');
      const cooldown = Math.ceil(quotaManager.getRemainingCooldown('storage_delete') / 60000);
      throw new Error(`Rate limit exceeded. Please wait ${cooldown} minutes.`);
    }

    console.error('Error deleting file:', error);
    throw new Error('Failed to delete file');
  }
}
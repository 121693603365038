import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export interface DebugContextType {
  debugMode: boolean;
  setDebugMode: (enabled: boolean) => void;
  currentComponent: string;
  pageId: string;
  setCurrentComponent: (component: string) => void;
  pageTransitions: Array<{
    path: string;
    component: string;
    timestamp: string;
  }>;
}

export const DebugContext = createContext<DebugContextType | null>(null);

export function DebugProvider({ children }: { children: React.ReactNode }) {
  const [debugMode, setDebugMode] = useState(() => {
    const saved = localStorage.getItem('debug_mode');
    return saved ? JSON.parse(saved) : false;
  });
  const [pageId, setPageId] = useState('');
  const [currentComponent, setCurrentComponent] = useState('');
  const [pageTransitions, setPageTransitions] = useState<Array<{
    path: string;
    component: string;
    timestamp: string;
  }>>([]);
  const location = useLocation();

  useEffect(() => {
    localStorage.setItem('debug_mode', JSON.stringify(debugMode));
  }, [debugMode]);

  useEffect(() => {
    if (debugMode) {
      const componentName = location.pathname === '/' 
        ? 'HomePage'
        : location.pathname
            .split('/')
            .filter(Boolean)
            .map(part => part.charAt(0).toUpperCase() + part.slice(1))
            .join('')
            + 'Page';
      const newPageId = `page-${location.pathname.replace(/\//g, '-').replace(/[^a-zA-Z0-9-]/g, '')}`;

      setCurrentComponent(componentName);
      setPageId(newPageId);
      setPageTransitions(prev => [
        ...prev,
        {
          path: location.pathname,
          component: componentName,
          timestamp: new Date().toISOString()
        }
      ].slice(-10)); // Keep last 10 transitions
    }
  }, [location, debugMode]);

  return (
    <DebugContext.Provider 
      value={{ 
        debugMode, 
        setDebugMode,
        currentComponent,
        pageId,
        setCurrentComponent,
        pageTransitions
      }}
    >
      {children}
      {debugMode && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-75 text-white px-4 py-2 rounded-lg text-sm font-mono z-[9999]">
          <div>Path: {location.pathname}</div>
          <div>Component: {currentComponent}</div>
          <div>Page ID: {pageId}</div>
          <div className="text-xs text-gray-400">
            {new Date().toLocaleTimeString()}
          </div>
        </div>
      )}
    </DebugContext.Provider>
  );
}
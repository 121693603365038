import { Product } from '../../../types/product';
import { sanitizeText } from '../utils/textUtils';

export function generateRelatedTerms(product: Product): string[] {
  try {
    const relatedTerms = new Set<string>();
    const cleanTitle = sanitizeText(product.title);
    const cleanCategory = sanitizeText(product.category);

    // Basic combinations
    relatedTerms.add(`${cleanCategory} ${cleanTitle}`);
    relatedTerms.add(`best ${cleanCategory}`);
    relatedTerms.add(`${cleanCategory} online`);
    relatedTerms.add(`buy ${cleanCategory}`);
    relatedTerms.add(`${cleanCategory} for sale`);
    relatedTerms.add(`quality ${cleanCategory}`);

    // Price-related terms
    if (product.price) {
      relatedTerms.add(`affordable ${cleanCategory}`);
      relatedTerms.add(`premium ${cleanCategory}`);
    }

    // Specification-based terms
    Object.entries(product.specifications || {}).forEach(([key, value]) => {
      if (typeof value === 'string') {
        const cleanKey = sanitizeText(key);
        const cleanValue = sanitizeText(value);
        relatedTerms.add(`${cleanCategory} with ${cleanKey} ${cleanValue}`);
        relatedTerms.add(`${cleanCategory} ${cleanValue}`);
      }
    });

    // Tag-based terms
    product.tags?.forEach(tag => {
      const cleanTag = sanitizeText(tag);
      relatedTerms.add(`${cleanCategory} ${cleanTag}`);
      relatedTerms.add(`${cleanTag} ${cleanCategory}`);
    });

    return Array.from(relatedTerms)
      .filter(term => term.length >= 5 && term.length <= 60)
      .slice(0, 15);
  } catch (error) {
    throw new Error('Failed to generate related terms: ' + (error instanceof Error ? error.message : 'Unknown error'));
  }
}
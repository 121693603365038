import { collection, query, where, getDocs } from 'firebase/firestore';
import { db, auth } from '../../config/firebase';
import { verifyAdminAccess } from '../auth/permissions';
import { quotaManager } from '../auth/quotaManager';

const STATS_CACHE_KEY = 'admin_stats';
const STATS_CACHE_DURATION = 5 * 60 * 1000; // 5 minutes

interface AdminStats {
  totalUsers: number;
  activeUsers: number;
  totalProducts: number;
  activeProducts: number;
  totalOrders: number;
  pendingOrders: number;
  processingOrders: number;
  shippedOrders: number;
}

export async function fetchAdminStats(): Promise<AdminStats> {
  try {
    // Check auth state first
    if (!auth.currentUser) {
      console.debug('No authenticated user');
      return getDefaultStats();
    }

    // Try to get cached stats first
    const cachedStats = localStorage.getItem('admin_stats');
    if (cachedStats) {
      const { stats, expiry } = JSON.parse(cachedStats);
      if (Date.now() < expiry) {
        console.debug('Using cached admin stats');
        return stats;
      }
    }

    // Verify admin access first
    const canProceed = await quotaManager.checkQuota('admin_stats');
    if (!canProceed) {
      const cooldown = Math.ceil(quotaManager.getRemainingCooldown('admin_stats') / 60000);
      throw new Error(`Rate limit exceeded. Please wait ${cooldown} minutes.`);
    }

    const hasAccess = await verifyAdminAccess();
    if (!hasAccess) {
      console.debug('Admin access verification failed');
      return getDefaultStats();
    }

    // Force token refresh
    if (auth.currentUser) {
      await auth.currentUser.getIdToken(true);
    }

    // Fetch all stats in parallel
    const [
      totalUsers,
      activeUsers,
      totalProducts,
      activeProducts,
      totalOrders,
      pendingOrders,
      processingOrders,
      shippedOrders
    ] = await Promise.all([
      getDocs(collection(db, 'users')),
      getDocs(query(collection(db, 'users'), where('status', '==', 'active'))),
      getDocs(collection(db, 'products')),
      getDocs(query(collection(db, 'products'), where('status', '==', 'active'))),
      getDocs(collection(db, 'orders')),
      getDocs(query(collection(db, 'orders'), where('status', '==', 'pending'))),
      getDocs(query(collection(db, 'orders'), where('status', '==', 'processing'))),
      getDocs(query(collection(db, 'orders'), where('status', '==', 'shipped')))
    ]);

    const result = {
      totalUsers: totalUsers.size,
      activeUsers: activeUsers.size,
      totalProducts: totalProducts.size,
      activeProducts: activeProducts.size,
      totalOrders: totalOrders.size,
      pendingOrders: pendingOrders.size,
      processingOrders: processingOrders.size,
      shippedOrders: shippedOrders.size
    };

    // Cache stats for 5 minutes
    localStorage.setItem('admin_stats', JSON.stringify({
      stats: result,
      expiry: Date.now() + STATS_CACHE_DURATION
    }));

    return result;
  } catch (error) {
    console.error('Error fetching admin stats:', error);
    return getDefaultStats();
  }
}

function getDefaultStats(): AdminStats {
  return {
    totalUsers: 0,
    activeUsers: 0,
    totalProducts: 0,
    activeProducts: 0,
    totalOrders: 0,
    pendingOrders: 0,
    processingOrders: 0,
    shippedOrders: 0
  }
}
import React, { useState } from 'react';
import { db } from '../config/firebase';
import { collection, getDocs } from 'firebase/firestore';

const defaultConfig = {
  apiKey: "your-api-key",
  authDomain: "your-auth-domain",
  projectId: "your-project-id",
  storageBucket: "your-storage-bucket",
  messagingSenderId: "your-messaging-sender-id",
  appId: "your-app-id",
  measurementId: "your-measurement-id"
};

export function useFirebaseConfig() {
  const [status, setStatus] = useState<'idle' | 'testing' | 'success' | 'error'>('idle');
  const [error, setError] = useState<string | null>(null);

  const testConnection = async () => {
    setStatus('testing');
    setError(null);

    try {
      // Test connection by trying to access Firestore
      const testQuery = collection(db, 'users');
      await getDocs(testQuery);
      
      setStatus('success');
    } catch (err) {
      setStatus('error');
      setError(err instanceof Error ? err.message : 'Failed to connect to database');
    }
  };

  const saveConfiguration = async () => {
    try {
      // Configuration is now managed through environment variables
      setStatus('success');
      return true;
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save configuration');
      return false;
    }
  };

  return {
    status,
    error,
    testConnection,
    saveConfiguration,
    defaultConfig
  };
}
import { RateLimitConfig, RateLimitState } from './types';

const DEFAULT_CONFIG: RateLimitConfig = {
  maxAttempts: 5,
  windowMs: 3600000, // 1 hour window
  initialDelay: 1000,
  maxDelay: 1800000, // 30 minutes max delay
  jitterFactor: 0.1
};

export class RateLimiter {
  private state: RateLimitState = {
    attempts: 0,
    lastAttempt: 0,
    windowStart: 0,
    lockedUntil: 0,
    globalLockUntil: 0,
    networkErrors: 0,
    lastError: undefined
  };
  private static globalLockUntil = 0;
  private static tokenRefreshTimeout: NodeJS.Timeout | null = null;

  private config: RateLimitConfig;
  private static quotaExceededUntil = 0;
  private static globalQuotaExceededUntil = 0;

  constructor(config: Partial<RateLimitConfig> = {}) {
    this.config = { ...DEFAULT_CONFIG, ...config };
    this.state.windowStart = Date.now();
  }

  private static handleQuotaExceeded() {
    const now = Date.now();
    RateLimiter.globalQuotaExceededUntil = now + (5 * 60 * 1000); // 5 minute global cooldown
    console.log('Global quota exceeded cooldown until:', new Date(RateLimiter.globalQuotaExceededUntil));
  }

  getLockedUntil(): number {
    return this.state.lockedUntil;
  }

  async checkRateLimit(): Promise<boolean> {
    const now = Date.now();
    
    // Check global quota exceeded status
    if (RateLimiter.globalQuotaExceededUntil > now) {
      console.log('Global quota limit in effect until:', new Date(RateLimiter.globalQuotaExceededUntil));
      return false;
    }
    
    // Check quota exceeded cooldown
    if (RateLimiter.quotaExceededUntil > now) {
      console.log('Quota exceeded cooldown active until:', new Date(RateLimiter.quotaExceededUntil));
      return false;
    }

    // Check global lock
    if (RateLimiter.globalLockUntil > now) {
      console.log('Global rate limit in effect');
      return false;
    }

    // Add mandatory delay between attempts
    const timeSinceLastAttempt = now - this.state.lastAttempt;
    if (timeSinceLastAttempt < 1000) { // Minimum 1 second delay
      await new Promise(resolve => setTimeout(resolve, 1000 - timeSinceLastAttempt));
    }

    // Add exponential backoff for network errors
    if (this.state.lastError?.code === 'auth/network-request-failed') {
      const networkBackoff = Math.min(
        this.config.initialDelay * Math.pow(2, this.state.networkErrors),
        60000 // 1 minute max for network retries
      );
      await new Promise(resolve => setTimeout(resolve, networkBackoff));
      this.state.networkErrors = 0;
    }

    // Check if we're in cooldown
    if (this.state.lockedUntil > now) {
      console.log('Rate limit cooldown active until:', new Date(this.state.lockedUntil));
      return false;
    }

    // Reset window if expired
    if (now - this.state.windowStart > this.config.windowMs) {
      this.state = {
        attempts: 0,
        lastAttempt: now,
        windowStart: now,
        lockedUntil: 0
      };
      return true;
    }

    this.state.attempts++;
    this.state.lastAttempt = now;

    // Check if we need to enter cooldown
    if (this.state.attempts > this.config.maxAttempts) {
      const lockoutDuration = Math.min(
        this.config.initialDelay * Math.pow(2, this.state.attempts - this.config.maxAttempts),
        this.config.maxDelay
      );
      
      // Set global lock for severe rate limiting
      if (this.state.attempts >= this.config.maxAttempts * 2) {
        RateLimiter.globalLockUntil = now + lockoutDuration * 2;
        console.log('Global rate limit activated until:', new Date(RateLimiter.globalLockUntil));
      }

      // Add jitter to prevent thundering herd
      const jitter = (Math.random() * 2 - 1) * this.config.jitterFactor * lockoutDuration;
      this.state.lockedUntil = now + lockoutDuration + jitter;

      console.log('Rate limit exceeded. Cooldown until:', new Date(this.state.lockedUntil));
      return false;
    }

    return true;
  }

  static clearTokenRefreshTimeout() {
    if (RateLimiter.tokenRefreshTimeout) {
      clearTimeout(RateLimiter.tokenRefreshTimeout);
      RateLimiter.tokenRefreshTimeout = null;
    }
  }

  handleError(error: any): void {
    if (error?.code === 'auth/network-request-failed') {
      this.state.networkErrors++;
      this.state.lastError = error;
    } else if (error?.code === 'auth/quota-exceeded') {
      // Set 5 minute cooldown for quota exceeded
      RateLimiter.quotaExceededUntil = Date.now() + (5 * 60 * 1000);
      console.log('Quota exceeded - enforcing 5 minute cooling period');
    }
    
    // Handle quota exceeded specifically
    if (error?.code === 'auth/quota-exceeded') {
      const now = Date.now();
      RateLimiter.globalLockUntil = now + (30 * 60 * 1000); // 30 minute global lock
      console.log('Quota exceeded - enforcing 30 minute cooling period');
    }
  }

  async waitForNextAttempt(): Promise<void> {
    const now = Date.now();
    if (this.state.lockedUntil > now) {
      const baseDelay = Math.max(this.state.lockedUntil - now, this.config.initialDelay);
      const jitter = (Math.random() * 2 - 1) * this.config.jitterFactor * baseDelay;
      const delay = baseDelay + jitter;
      await new Promise(resolve => setTimeout(resolve, delay));
    }
    // Always reset state after waiting
    this.reset();
  }

  reset(): void {
    this.state = {
      attempts: 0,
      lastAttempt: 0,
      windowStart: Date.now(),
      lockedUntil: 0,
      globalLockUntil: 0,
      networkErrors: 0,
      lastError: undefined
    };
  }
}
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';

interface ColumnUsage {
  field: string;
  usedInCode: boolean;
  usedInDatabase: boolean;
  references: string[];
}

export async function analyzeDatabase(): Promise<{
  unusedColumns: ColumnUsage[];
  recommendations: string[];
}> {
  const tables = ['profiles', 'products', 'orders', 'carriers', 'site_settings'];
  const columnUsage = new Map<string, ColumnUsage>();
  const recommendations: string[] = [];

  // Scan database
  for (const tableName of tables) {
    try {
      const collectionRef = collection(db, tableName);
      const snapshot = await getDocs(collectionRef);
      const data = snapshot.docs[0]?.data();

      if (data) {
        Object.keys(data).forEach(field => {
          const key = `${tableName}.${field}`;
          if (!columnUsage.has(key)) {
            columnUsage.set(key, {
              field: key,
              usedInCode: false,
              usedInDatabase: true,
              references: []
            });
          }
        });
      }
    } catch (error) {
      console.error(`Error scanning ${tableName}:`, error);
    }
  }

  // Fields that are actually used in code
  const usedFields = new Set([
    // Product fields
    'products.id',
    'products.title',
    'products.price',
    'products.description',
    'products.category',
    'products.stock_quantity',
    'products.stock_threshold',
    'products.stock_status',
    'products.rating',
    'products.tags',
    'products.specifications',
    'products.categories',
    'products.version',
    'products.history',
    'products.metadata',
    'products.status',
    'products.shipping_cost',
    'products.sku',
    'products.seo',
    'products.updated_at',
    'products.updated_by',

    // Profile fields
    'profiles.id',
    'profiles.name',
    'profiles.email',
    'profiles.role',
    'profiles.status',
    'profiles.address',
    'profiles.postcode',
    'profiles.telephone',
    'profiles.address2',
    'profiles.address3',
    'profiles.country',
    'profiles.last_login',
    'profiles.registration_date',
    'profiles.last_modified',
    'profiles.modified_by',

    // Site settings fields
    'site_settings.site_name',
    'site_settings.currency',
    'site_settings.tax_rate',
    'site_settings.tax_inclusive',
    'site_settings.shipping_free_threshold',
    'site_settings.cart_enabled',
    'site_settings.cart_options',
    'site_settings.last_modified',
    'site_settings.modified_by',

    // Carrier fields
    'carriers.id',
    'carriers.name',
    'carriers.status',
    'carriers.last_modified',
    'carriers.modified_by'
  ]);

  // Mark fields as used in code
  usedFields.forEach(field => {
    if (columnUsage.has(field)) {
      const usage = columnUsage.get(field)!;
      usage.usedInCode = true;
    }
  });

  // Find unused columns
  const unusedColumns = Array.from(columnUsage.values())
    .filter(usage => !usage.usedInCode)
    .sort((a, b) => a.field.localeCompare(b.field));

  // Generate recommendations
  if (unusedColumns.length > 0) {
    recommendations.push('Consider removing or archiving unused database fields:');
    unusedColumns.forEach(col => {
      recommendations.push(`- ${col.field} is not referenced in code`);
    });
  }

  // Add optimization recommendations
  recommendations.push('\nOptimization opportunities:');
  recommendations.push('1. Consider adding indexes for frequently queried fields:');
  recommendations.push('   - products.category');
  recommendations.push('   - products.status');
  recommendations.push('   - profiles.email');
  recommendations.push('   - profiles.role');
  
  recommendations.push('\n2. Data structure optimizations:');
  recommendations.push('   - Consider denormalizing frequently accessed data');
  recommendations.push('   - Add caching for product categories');
  recommendations.push('   - Implement pagination for large collections');
  recommendations.push('   - Use materialized views for complex reports');

  recommendations.push('\n3. Security recommendations:');
  recommendations.push('   - Review and update RLS policies');
  recommendations.push('   - Implement rate limiting for write operations');
  recommendations.push('   - Add validation rules for all fields');
  recommendations.push('   - Enable audit logging for sensitive operations');

  recommendations.push('\n4. Performance monitoring:');
  recommendations.push('   - Set up query performance monitoring');
  recommendations.push('   - Track slow queries and optimize them');
  recommendations.push('   - Monitor connection pool usage');
  recommendations.push('   - Set up alerts for performance thresholds');

  return {
    unusedColumns,
    recommendations
  };
}
import React from 'react';
import AdminLayout from '../components/admin/AdminLayout';
import DatabaseConfig from '../components/admin/DatabaseConfig';
import SiteSettings from '../components/admin/SiteSettings';
import CryptoAddressSettings from '../components/admin/CryptoAddressSettings';
import CarrierSettings from '../components/admin/CarrierSettings';
import DebugSettings from '../components/admin/DebugSettings';
import DatabaseAnalyzer from '../components/admin/DatabaseAnalyzer'; 

export default function AdminSettingsPage() {
  return (
    <AdminLayout>
      <div className="p-6">
        <div className="space-y-8">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <SiteSettings />
          </div>
          <hr className="border-gray-200" />
          <div className="bg-white rounded-lg shadow-sm p-6">
            <CryptoAddressSettings />
          </div>
          <hr className="border-gray-200" />
          <div className="bg-white rounded-lg shadow-sm p-6">
            <CarrierSettings />
          </div>
          <hr className="border-gray-200" />
          <div className="bg-white rounded-lg shadow-sm p-6">
            <DebugSettings />
          </div>
          <hr className="border-gray-200" />
          <div className="bg-white rounded-lg shadow-sm p-6">
            <DatabaseAnalyzer />
          </div>
          <hr className="border-gray-200" />
          <div className="bg-white rounded-lg shadow-sm p-6">
            <DatabaseConfig />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
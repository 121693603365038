import React, { useState } from 'react';
import { AlertCircle, Database, RefreshCw } from 'lucide-react';
import { analyzeDatabase } from '../../utils/databaseAnalysis';

export default function DatabaseAnalyzer() {
  const [analyzing, setAnalyzing] = useState(false);
  const [results, setResults] = useState<{
    unusedColumns: any[];
    recommendations: string[];
  } | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleAnalyze = async () => {
    try {
      setAnalyzing(true);
      setError(null);
      const analysis = await analyzeDatabase();
      setResults(analysis);
    } catch (err) {
      setError('Failed to analyze database');
      console.error('Analysis error:', err);
    } finally {
      setAnalyzing(false);
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-2">
          <Database className="h-6 w-6 text-gray-700" />
          <h2 className="text-xl font-bold">Database Analysis</h2>
        </div>
        <button
          onClick={handleAnalyze}
          disabled={analyzing}
          className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
            analyzing
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-yellow-400 hover:bg-yellow-500'
          }`}
        >
          {analyzing ? (
            <RefreshCw className="h-5 w-5 animate-spin" />
          ) : (
            <Database className="h-5 w-5" />
          )}
          <span>{analyzing ? 'Analyzing...' : 'Analyze Database'}</span>
        </button>
      </div>

      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-lg flex items-center space-x-2">
          <AlertCircle className="h-5 w-5" />
          <span>{error}</span>
        </div>
      )}

      {results && (
        <div className="space-y-6">
          {/* Unused Columns */}
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-semibold mb-4">Unused Database Fields</h3>
            {results.unusedColumns.length > 0 ? (
              <div className="space-y-2">
                {results.unusedColumns.map((col, index) => (
                  <div
                    key={index}
                    className="p-3 bg-yellow-50 text-yellow-800 rounded-lg"
                  >
                    {col.field}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-green-600">No unused columns found!</p>
            )}
          </div>

          {/* Recommendations */}
          <div className="bg-white rounded-lg shadow p-6">
            <h3 className="text-lg font-semibold mb-4">Recommendations</h3>
            <div className="space-y-4">
              {results.recommendations.map((rec, index) => (
                <div key={index} className="text-gray-700">
                  {rec.startsWith('-') ? (
                    <div className="ml-4 text-gray-600">{rec}</div>
                  ) : rec.startsWith('  ') ? (
                    <div className="ml-8 text-gray-500">{rec}</div>
                  ) : (
                    <div className="font-medium">{rec}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
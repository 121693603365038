import { collection, query, getDocs, writeBatch, doc } from 'firebase/firestore';
import { db } from '../config/firebase';

export async function syncCategories(): Promise<{
  success: boolean;
  message: string;
  added: number;
}> {
  try {
    // Get all unique categories from products
    const productsRef = collection(db, 'products');
    const productsSnapshot = await getDocs(query(productsRef));

    const uniqueCategories = new Set<string>();
    productsSnapshot.forEach(doc => {
      const category = doc.data().category;
      if (category && typeof category === 'string') {
        uniqueCategories.add(category);
      }
    });

    // Get existing categories
    const categoriesRef = collection(db, 'categories');
    const categoriesSnapshot = await getDocs(query(categoriesRef));
    const existingNames = new Set(
      categoriesSnapshot.docs.map(doc => doc.data().name)
    );

    // Find categories to add
    const categoriesToAdd = Array.from(uniqueCategories).filter(
      category => !existingNames.has(category)
    );

    if (categoriesToAdd.length === 0) {
      return {
        success: true,
        message: 'Categories are already in sync',
        added: 0
      };
    }

    // Add new categories in batches
    const batch = writeBatch(db);
    const timestamp = new Date().toISOString();

    categoriesToAdd.forEach((categoryName, index) => {
      const categoryRef = doc(categoriesRef);
      batch.set(categoryRef, {
        name: categoryName,
        description: '',
        order: existingNames.size + index,
        status: 'active',
        created_at: timestamp,
        last_modified: timestamp,
        modified_by: 'system'
      });
    });

    await batch.commit();

    return {
      success: true,
      message: `Added ${categoriesToAdd.length} new categories`,
      added: categoriesToAdd.length
    };
  } catch (error) {
    console.error('Error syncing categories:', error);
    return {
      success: false,
      message: error instanceof Error ? error.message : 'Failed to sync categories',
      added: 0
    };
  }
}
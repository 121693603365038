import { db } from '../config/firebase';
import { doc, updateDoc, collection, addDoc } from 'firebase/firestore';
import { UserProfile } from '../types/auth';

export interface RoleChange {
  userId: string;
  previousRole: string;
  newRole: string;
  changedBy: string;
  timestamp: string;
}

export async function updateUserRole(
  userId: string,
  newRole: 'admin' | 'user',
  currentUser: UserProfile
): Promise<void> {
  const timestamp = new Date().toISOString();

  try {
    // Update user profile
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
        role: newRole,
        lastModified: timestamp,
        modifiedBy: currentUser.email
    });

    // Log role change
    await addDoc(collection(db, 'role_changes'), {
        user_id: userId,
        previous_role: currentUser.role,
        new_role: newRole,
        changed_by: currentUser.email,
        timestamp
      });
    
    // Log auth event
    await addDoc(collection(db, 'auth_logs'), {
      eventType: 'role_changed',
      metadata: {
        user_id: userId,
        previous_role: currentUser.role,
        new_role: newRole,
        changed_by: currentUser.email,
        timestamp
      }
    });
  } catch (error) {
    throw error;
  }
}

export function canModifyRoles(user: UserProfile | null): boolean {
  return Boolean(
    user?.role === 'admin' &&
    user?.status === 'active'
  );
}

export async function getRoleChanges(userId: string): Promise<RoleChange[]> {
  const roleChangesRef = collection(db, 'role_changes');
  const q = query(
    roleChangesRef,
    where('user_id', '==', userId),
    orderBy('timestamp', 'desc')
  );
  
  const snapshot = await getDocs(q);
  return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
}

export async function validateRoleChange(userId: string, newRole: 'admin' | 'user'): Promise<boolean> {
  // Get current user
  const currentUser = auth.currentUser;
  if (!currentUser) return false;

  // Check if current user is admin
  const currentUserDoc = await getDoc(doc(db, 'users', currentUser.uid));
  const currentUserData = currentUserDoc.data();

  if (!currentUserData || currentUserData.role !== 'admin' || currentUserData.status !== 'active') {
    return false;
  }

  // Check if target user exists
  const targetUserDoc = await getDoc(doc(db, 'users', userId));
  if (!targetUserDoc.exists()) {
    return false;
  }

  return true;
}
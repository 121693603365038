import React from 'react';
import AdminLayout from '../components/admin/AdminLayout';
import ThemeManager from '../components/admin/ThemeManager';

export default function AdminThemesPage() {
  return (
    <AdminLayout>
      <div className="p-6">
        <ThemeManager />
      </div>
    </AdminLayout>
  );
}
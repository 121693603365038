import { auth, db } from '../../config/firebase';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { quotaManager } from './quotaManager';
import { UserProfile } from '../../types/auth';
import { checkNetworkConnectivity } from './network';
import { withNetworkRetry } from '../networkRetry';

export async function ensureAuth(): Promise<{isAuthenticated: boolean; isAdmin: boolean}> {
  const user = auth.currentUser;
  if (!user) {
    return { isAuthenticated: false, isAdmin: false };
  }

  try {
    // Get fresh ID token to ensure claims are up to date
    await user.getIdTokenResult(true);
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    
    if (!userDoc.exists()) {
      return { isAuthenticated: true, isAdmin: false };
    }

    const userData = userDoc.data();
    return {
      isAuthenticated: true,
      isAdmin: userData.role === 'admin' && userData.status === 'active'
    };
  } catch (error) {
    console.error('Auth check failed:', error);
    return { isAuthenticated: false, isAdmin: false };
  }
}

export async function verifyAdminAccess(): Promise<boolean> {
  try {
    let user = auth.currentUser;
    if (!user) {
      return false; 
    }
    
    // Get fresh ID token with claims
    const idTokenResult = await user.getIdTokenResult(true);
    
    // Check custom claims first
    if (idTokenResult.claims.admin === true) {
      return true;
    }

    // Fallback to database check
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    if (!userDoc.exists()) {
      return false;
    }

    const userData = userDoc.data();
    return userData.role === 'admin' && userData.status === 'active';
  } catch (error) {
    console.error('Admin verification failed:', error);
    return false;
  }
}

export function hasAdminRole(userProfile: UserProfile | null): boolean { 
  if (!userProfile) return false;
  return userProfile.role === 'admin' && 
         userProfile.status === 'active';
}
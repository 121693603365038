import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ProductDetail from '../components/ProductDetail';
import { db } from '../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Product } from '../types/product';
import { useSite, SiteSettings } from '../context/SiteContext';

export default function ProductDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { siteName, settings } = useSite();
  const cartEnabled = settings?.cartSystem?.enabled ?? false;
  const [product, setProduct] = useState<Product | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function fetchProduct() {
      if (!id) return;

      try {
        const productRef = doc(db, 'products', id);
        const productSnap = await getDoc(productRef);

        if (!productSnap.exists()) {
          setError('Product not found');
          return;
        }

        setProduct({ id: productSnap.id, ...productSnap.data() } as Product);
      } catch (err) {
        setError('Error loading product');
        console.error('Error fetching product:', err);
      } finally {
        setLoading(false);
      }
    }

    fetchProduct();
  }, [id]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-[400px]">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">{error}</h2>
        <button
          onClick={() => navigate('/')}
          className="text-blue-600 hover:text-blue-800"
        >
          Return to Home
        </button>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="text-center py-12">
        <Helmet>
          <title>{`Product Not Found | ${siteName}`}</title>
          <meta name="robots" content="noindex,nofollow" />
        </Helmet>
        <h2 className="text-2xl font-bold text-gray-900">Product not found</h2>
        <button
          onClick={() => navigate('/')}
          className="mt-4 text-blue-600 hover:text-blue-800"
        >
          Return to Home
        </button>
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>{`${product.seo?.title || product.title} | ${siteName}`}</title>
        <meta name="description" content={product.seo?.description || product.description} />
        {product.seo?.keywords && (
          <meta name="keywords" content={product.seo.keywords.join(', ')} />
        )}
        <meta property="og:title" content={`${product.seo?.title || product.title} | ${siteName}`} />
        <meta property="og:description" content={product.seo?.description || product.description} />
        <meta property="og:type" content="product" />
        <meta property="og:price:amount" content={product.price.toString()} />
        <meta property="og:price:currency" content="USD" />
        <meta property="og:availability" content={product.stock?.status || 'out-of-stock'} />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Product',
            name: product.title,
            description: product.description,
            sku: product.sku,
            image: product.imageSlot1?.url,
            offers: {
              '@type': 'Offer',
              price: product.price,
              priceCurrency: 'USD',
              availability: product.stock?.status === 'in-stock' 
                ? 'https://schema.org/InStock' 
                : 'https://schema.org/OutOfStock'
            }
          })}
        </script>
      </Helmet>
      <ProductDetail product={product} onBack={() => navigate('/')} />
    </>
  );
}
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { CartProvider } from './context/CartContext';
import { SearchProvider } from './context/SearchContext';
import { AuthProvider } from './context/AuthContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { HelmetProvider } from 'react-helmet-async';
import { SiteProvider } from './context/SiteContext';
import { ThemeProvider } from './context/ThemeContext';
import { DebugProvider } from './context/DebugContext';
import { PaymentProvider } from './context/PaymentContext';
import { initializeAdmin } from './utils/auth/initAdmin';
import { handleConnectionChange } from './utils/firestore';
import { useEffect } from 'react';
import AppContent from './components/AppContent';
import { quotaManager } from './utils/auth/quotaManager';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

function App() {
  useEffect(() => {
    // Handle online/offline status
    const handleOnline = () => handleConnectionChange(true);
    const handleOffline = () => handleConnectionChange(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Check initial connection status
    handleConnectionChange(navigator.onLine);

    // Initialize admin user after component mount
    const initAdmin = async () => {
      try {
        // Check quota before initialization
        const canProceed = await quotaManager.checkQuota();
        if (!canProceed) {
          const cooldown = quotaManager.getRemainingCooldown();
          console.log(`Waiting ${Math.ceil(cooldown / 60000)} minutes before admin initialization`);
          return;
        }

        console.log('Initializing admin user...');
        await initializeAdmin();
        console.log('Admin initialization complete');
      } catch (error) {
        if (error?.code === 'auth/quota-exceeded') {
          quotaManager.handleQuotaExceeded();
          return;
        }
        console.error('Admin initialization failed:', error);
      }
    };
    
    initAdmin();

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <ThemeProvider>
            <SiteProvider>
              <BrowserRouter>
                <CartProvider>
                  <PaymentProvider>
                    <SearchProvider>
                      <DebugProvider>
                        <AppContent />
                      </DebugProvider>
                    </SearchProvider>
                  </PaymentProvider>
                </CartProvider>
              </BrowserRouter>
            </SiteProvider>
          </ThemeProvider>
        </HelmetProvider>
      </QueryClientProvider>
    </AuthProvider>
  );
}
export default App;
import React, { useState } from 'react';
import { db } from '../../../config/firebase';
import { doc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { Order } from '../../../types/order';
import { Carrier } from '../../../types/settings';
import { useSite } from '../../../context/SiteContext';
import { useAuth } from '../../../context/AuthContext';
import { TruckIcon, Ban, RefreshCw, Send, AlertCircle } from 'lucide-react';

interface OrderActionsProps {
  order: Order;
}

export default function OrderActions({ order }: OrderActionsProps) {
  const { userProfile } = useAuth();
  const { currency } = useSite();
  const [carriers, setCarriers] = useState<Carrier[]>([]);
  const [processing, setProcessing] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [trackingNumber, setTrackingNumber] = useState('');
  const [carrier, setCarrier] = useState('');
  const [showTrackingInput, setShowTrackingInput] = useState(false);
  const [cancellationReason, setCancellationReason] = useState('');

  // Show tracking input when order is processing
  React.useEffect(() => {
    setShowTrackingInput(order.status === 'processing');
  }, [order.status]);

  React.useEffect(() => {
    // Fetch active carriers
    const fetchCarriers = async () => {
      const carriersRef = collection(db, 'carriers');
      const q = query(carriersRef, where('status', '==', 'active'));
      const snapshot = await getDocs(q);
      const carriers = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setCarriers(carriers);
    };

    fetchCarriers();
  }, []);

  const updateOrderStatus = async (
    newStatus: Order['status'],
    additionalData?: any
  ) => {
    if (!userProfile) return;
    
    setProcessing(true);
    setError(null);

    try {
      const timestamp = new Date().toISOString();
      const performedBy = userProfile.email;
      
      const shippingDetails = newStatus === 'shipped' ? {
        trackingNumber,
        carrier,
        status: 'shipped',
        estimatedDelivery: new Date(
          Date.now() + 7 * 24 * 60 * 60 * 1000
        ).toISOString()
      } : undefined;

      const historyEntry = {
        action: `Order ${newStatus}`,
        timestamp,
        performedBy,
        details: additionalData?.reason || 
          (newStatus === 'shipped' 
            ? `Order shipped via ${carrier} with tracking number ${trackingNumber}`
            : `Status updated to ${newStatus}`)
      };

      const updates = {
        status: newStatus,
        updated_at: timestamp,
        history: [...(order.history || []), historyEntry],
        ...(shippingDetails && { shipping_details: shippingDetails })
      };

      if (additionalData?.reason) {
        updates.cancellationReason = additionalData.reason;
      }
      
      if (newStatus === 'refunded') {
        updates.payment = {
          ...order.payment,
          status: 'refunded',
          lastUpdated: timestamp
        };
      } else if (newStatus === 'cancelled') {
        updates.payment = {
          ...order.payment,
          status: 'cancelled',
          lastUpdated: timestamp
        };
        updates.shipping_details = {
          ...order.shipping_details,
          status: 'cancelled'
        };
      } else if (newStatus === 'returned') {
        updates.shipping_details = {
          ...order.shipping_details,
          status: 'returned'
        };
        updates.payment = {
          ...order.payment,
          status: 'pending_refund',
          lastUpdated: timestamp
        };
      }

      const orderRef = doc(db, 'orders', order.id);
      await updateDoc(orderRef, updates);

      setError(null);
      setShowConfirmation(false);
      setCancellationReason('');
    } catch (err) {
      setError('Failed to update order status. Please try again.');
      console.error('Error updating order:', err);
    } finally {
      setProcessing(false);
    }
  };

  const handleShipOrder = async () => {
    if (!trackingNumber || !carrier) {
      setError('Tracking number and carrier are required');
      return;
    }

    await updateOrderStatus('shipped', {
      'shipping.trackingNumber': trackingNumber,
      'shipping.carrier': carrier,
      'shipping.status': 'shipped',
      'shipping.estimatedDelivery': new Date(
        Date.now() + 7 * 24 * 60 * 60 * 1000
      ).toISOString()
    });
  };

  const handleCancelOrder = async () => {
    if (!cancellationReason.trim()) {
      setError('Please provide a reason for cancellation');
      return;
    }

    try {
      await updateOrderStatus('cancelled', { reason: cancellationReason });
      setShowConfirmation(false);
      setCancellationReason('');
    } catch (err) {
      setError('Failed to cancel order. Please try again.');
    }
  };

  const isActionDisabled = processing || order.status === 'cancelled';

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 space-y-6">
      <h3 className="font-semibold text-lg">Order Actions</h3>

      {error && (
        <div className="bg-red-50 text-red-700 p-3 rounded-lg flex items-center">
          <AlertCircle className="h-5 w-5 mr-2" />
          {error}
        </div>
      )}

      {(order.status === 'pending' || order.status === 'payment_pending_verification') && (
        <button
          onClick={() => updateOrderStatus('processing')}
          disabled={isActionDisabled}
          className="w-full flex items-center justify-center space-x-2 bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 disabled:opacity-50"
        >
          <RefreshCw className="h-5 w-5" />
          <span>Start Processing</span>
        </button>
      )}

      {showTrackingInput && (
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Tracking Number
            </label>
            <input
              type="text"
              value={trackingNumber}
              onChange={(e) => setTrackingNumber(e.target.value)}
              className="w-full p-2 border rounded-lg"
              placeholder="Enter tracking number"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Carrier
            </label>
            <select
              value={carrier}
              onChange={(e) => setCarrier(e.target.value)}
              className="w-full p-2 border rounded-lg"
            >
              <option value="">Select carrier</option>
              {carriers.map((c) => (
                <option key={c.id} value={c.name}>
                  {c.name}
                </option>
              ))}
            </select>
          </div>

          <button
            onClick={handleShipOrder}
            disabled={isActionDisabled || !trackingNumber || !carrier}
            className={`w-full flex items-center justify-center space-x-2 px-4 py-2 rounded-lg ${
              isActionDisabled || !trackingNumber || !carrier
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-purple-600 hover:bg-purple-700 text-white'
            }`}
          >
            <TruckIcon className="h-5 w-5" />
            <span>Mark as Shipped</span>
          </button>
        </div>
      )}

      {order.status === 'shipped' && (
        <button
          onClick={() => updateOrderStatus('delivered')}
          disabled={isActionDisabled}
          className="w-full flex items-center justify-center space-x-2 bg-green-600 text-white px-4 py-2 rounded-lg hover:bg-green-700 disabled:opacity-50"
        >
          <Send className="h-5 w-5" />
          <span>Mark as Delivered</span>
        </button>
      )}

      {order.status === 'delivered' && (
        <button
          onClick={() => updateOrderStatus('returned')}
          disabled={isActionDisabled}
          className="w-full flex items-center justify-center space-x-2 bg-orange-600 text-white px-4 py-2 rounded-lg hover:bg-orange-700 disabled:opacity-50"
        >
          <TruckIcon className="h-5 w-5 rotate-180" />
          <span>Mark as Returned</span>
        </button>
      )}

      {!['cancelled', 'refunded'].includes(order.status) && (
        <>
          <button
            onClick={() => setShowConfirmation(true)}
            disabled={isActionDisabled}
            className="w-full flex items-center justify-center space-x-2 bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700 disabled:opacity-50"
          >
            <Ban className="h-5 w-5" />
            <span>Cancel Order</span>
          </button>

          {showConfirmation && (
            <div className="mt-4 p-4 bg-gray-50 rounded-lg">
              <h4 className="font-medium mb-2">Confirm Order Cancellation</h4>
              <p className="text-sm text-gray-600 mb-4">
                Please provide a reason for cancellation:
              </p>
              <textarea
                value={cancellationReason}
                onChange={(e) => setCancellationReason(e.target.value)}
                className="w-full p-2 border rounded-lg mb-4"
                rows={3}
                placeholder="Enter cancellation reason..."
              />
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => {
                    setShowConfirmation(false);
                    setCancellationReason('');
                    setError(null);
                  }}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={handleCancelOrder}
                  disabled={processing}
                  className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 disabled:opacity-50"
                >
                  Confirm Cancellation
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
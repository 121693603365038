import { sanitizeText } from '../utils/textUtils';

export function generateTitle(title: string, category: string): string {
  try {
    const cleanTitle = sanitizeText(title);
    const cleanCategory = sanitizeText(category);
    let seoTitle = `${cleanTitle} - ${cleanCategory}`;
    
    if (seoTitle.length > 60) {
      seoTitle = seoTitle.substring(0, 57) + '...';
    }
    
    return seoTitle;
  } catch (error) {
    throw new Error('Failed to generate SEO title: ' + (error instanceof Error ? error.message : 'Unknown error'));
  }
}
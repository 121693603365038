import { useQuery } from 'react-query';
import { db } from '../config/firebase';
import { collection, query, getDocs } from 'firebase/firestore';

interface Category {
  id: string;
  name: string;
  description: string;
  order: number;
  status: 'active' | 'inactive';
}

async function fetchCategories() {
  const productsRef = collection(db, 'products');
  const snapshot = await getDocs(query(productsRef));
  const products = snapshot.docs.map(doc => doc.data());


  // Get unique categories
  const categories = new Set<string>();
  products.forEach(product => {
    if (product.category && typeof product.category === 'string') {
      categories.add(product.category);
    }
  });

  // Convert to Category objects
  return Array.from(categories)
    .sort()
    .map((name, index) => ({
      id: name.toLowerCase().replace(/\s+/g, '-'),
      name,
      description: '',
      order: index,
      status: 'active'
    }));
}

export function useCategories() {
  const { data: categories = [], isLoading, error } = useQuery<Category[], Error>(
    'categories',
    fetchCategories,
    {
      staleTime: 5 * 60 * 1000, // Consider data fresh for 5 minutes
      cacheTime: 30 * 60 * 1000, // Keep unused data in cache for 30 minutes
    }
  );

  return { 
    categories, 
    loading: isLoading, 
    error 
  };
}
import { db } from '../config/firebase';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { SiteSettings } from '../types/settings';

const SETTINGS_DOC = 'site_settings';
const SETTINGS_COLLECTION = 'settings';

const DEFAULT_SETTINGS: SiteSettings = {
  siteName: 'Blackjack Productions Limited',
  currency: 'USD',
  tax: {
    rate: 20,
    inclusive: true,
    lastModified: new Date().toISOString(),
    modifiedBy: 'system'
  },
  shipping: {
    freeThreshold: 100,
    lastModified: new Date().toISOString(),
    modifiedBy: 'system'
  },
  cartSystem: {
    enabled: true,
    options: {
      showPrices: true,
      showStock: true,
      showAddToCart: true,
      showBuyNow: true,
      showShipping: true,
      showRatings: true,
      showProductLink: true,
      productLinkText: 'View Details'
    },
    lastModified: new Date().toISOString(),
    modifiedBy: 'system'
  },
  lastModified: new Date().toISOString(),
  modifiedBy: 'system'
};

export async function getSiteSettings(): Promise<SiteSettings> {
  try {
    const settingsRef = doc(db, 'settings', 'site_settings');
    const settingsSnap = await getDoc(settingsRef);

    if (!settingsSnap.exists()) {
      // Create default settings if they don't exist
      await setDoc(settingsRef, {
        site_name: DEFAULT_SETTINGS.siteName,
        currency: DEFAULT_SETTINGS.currency,
        tax_rate: DEFAULT_SETTINGS.tax.rate,
        tax_inclusive: DEFAULT_SETTINGS.tax.inclusive,
        shipping_free_threshold: DEFAULT_SETTINGS.shipping.freeThreshold,
        cart_enabled: DEFAULT_SETTINGS.cartSystem.enabled,
        cart_options: DEFAULT_SETTINGS.cartSystem.options,
        last_modified: DEFAULT_SETTINGS.lastModified,
        modified_by: DEFAULT_SETTINGS.modifiedBy
      });
      return DEFAULT_SETTINGS;
    }

    const data = settingsSnap.data();
    return {
      siteName: data.site_name,
      currency: data.currency,
      tax: {
        rate: data.tax_rate,
        inclusive: data.tax_inclusive,
        lastModified: data.last_modified,
        modifiedBy: data.modified_by
      },
      shipping: {
        freeThreshold: data.shipping_free_threshold,
        lastModified: data.last_modified,
        modifiedBy: data.modified_by
      },
      cartSystem: {
        enabled: data.cart_enabled,
        options: data.cart_options,
        lastModified: data.last_modified,
        modifiedBy: data.modified_by
      },
      lastModified: data.last_modified,
      modifiedBy: data.modified_by
    };
  } catch (error) {
    console.error('Error fetching settings:', error);
    // Return default settings on error
    return DEFAULT_SETTINGS;
  }
}

export async function updateCurrency(
  currency: 'USD' | 'GBP' | 'EUR',
  modifiedBy: string
): Promise<void> {
  await updateDoc(doc(db, SETTINGS_COLLECTION, SETTINGS_DOC), {
    currency,
    last_modified: new Date().toISOString(),
    modified_by: modifiedBy
  });
}

export async function updateShippingThreshold(
  threshold: number,
  modifiedBy: string
): Promise<void> {
  if (threshold < 0) {
    throw new Error('Threshold must be a positive number');
  }

  await updateDoc(doc(db, SETTINGS_COLLECTION, SETTINGS_DOC), {
    shipping_free_threshold: threshold,
    last_modified: new Date().toISOString(),
    modified_by: modifiedBy
  });
}

export async function updateTaxRate(
  rate: number,
  modifiedBy: string
): Promise<void> {
  if (rate < 0 || rate > 100) {
    throw new Error('Tax rate must be between 0 and 100');
  }

  await updateDoc(doc(db, SETTINGS_COLLECTION, SETTINGS_DOC), {
    tax_rate: rate,
    last_modified: new Date().toISOString(),
    modified_by: modifiedBy
  });
}

export async function updateSiteName(
  newName: string,
  modifiedBy: string
): Promise<void> {  
  if (!newName.trim()) {
    throw new Error('Site name cannot be empty');
  }

  if (newName.length > 50) {
    throw new Error('Site name cannot exceed 50 characters');
  }

  await updateDoc(doc(db, SETTINGS_COLLECTION, SETTINGS_DOC), {
    site_name: newName,
    last_modified: new Date().toISOString(),
    modified_by: modifiedBy
  });
}

export async function updateCryptoSurcharge(
  rate: number,
  modifiedBy: string
): Promise<void> {
  if (rate < 0 || rate > 100) {
    throw new Error('Surcharge rate must be between 0 and 100');
  }

  await updateDoc(doc(db, SETTINGS_COLLECTION, SETTINGS_DOC), {
    crypto_surcharge_rate: rate,
    last_modified: new Date().toISOString(),
    modified_by: modifiedBy
  });
}
import React from 'react';
import AdminLayout from '../components/admin/AdminLayout';
import CategoryManager from '../components/admin/CategoryManager';

export default function AdminCategoriesPage() {
  return (
    <AdminLayout>
      <div className="p-6">
        <CategoryManager />
      </div>
    </AdminLayout>
  );
}
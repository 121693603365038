import React, { useState } from 'react';
import { Shield, AlertCircle } from 'lucide-react';
import { updateUserRole } from '../../utils/roleManagement';
import { useAuth } from '../../context/AuthContext';
import { UserProfile } from '../../types/auth';

interface RoleManagerProps {
  user: UserProfile & { id: string };
  onRoleUpdate: () => void;
}

export default function RoleManager({ user, onRoleUpdate }: RoleManagerProps) {
  const { userProfile } = useAuth();
  const [isChanging, setIsChanging] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleRoleChange = async (newRole: 'admin' | 'user') => {
    if (!userProfile) return;
    
    setIsChanging(true);
    setError(null);

    try {
      await updateUserRole(user.id, newRole, userProfile);
      setShowConfirm(false);
      onRoleUpdate();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update role');
    } finally {
      setIsChanging(false);
    }
  };

  if (!userProfile?.role === 'admin') return null;

  return (
    <div className="relative">
      <button
        onClick={() => setShowConfirm(true)}
        disabled={isChanging}
        className="flex items-center space-x-1 text-gray-600 hover:text-gray-900"
      >
        <Shield className="h-5 w-5" />
        <span className="text-sm">{user.role || 'user'}</span>
      </button>

      {showConfirm && (
        <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg p-4 z-10 border">
          <h4 className="font-medium mb-2">Change User Role</h4>
          <p className="text-sm text-gray-600 mb-4">
            Select a new role for {user.name}
          </p>
          
          <div className="space-y-2">
            <button
              onClick={() => handleRoleChange('user')}
              disabled={user.role === 'user' || isChanging}
              className={`w-full p-2 rounded-lg text-left ${
                user.role === 'user'
                  ? 'bg-gray-100 text-gray-500'
                  : 'hover:bg-gray-50'
              }`}
            >
              Standard User
            </button>
            <button
              onClick={() => handleRoleChange('admin')}
              disabled={user.role === 'admin' || isChanging}
              className={`w-full p-2 rounded-lg text-left ${
                user.role === 'admin'
                  ? 'bg-gray-100 text-gray-500'
                  : 'hover:bg-gray-50'
              }`}
            >
              Administrator
            </button>
          </div>

          {error && (
            <div className="mt-3 text-sm text-red-600 flex items-center space-x-1">
              <AlertCircle className="h-4 w-4" />
              <span>{error}</span>
            </div>
          )}

          <div className="mt-4 flex justify-end">
            <button
              onClick={() => setShowConfirm(false)}
              className="text-sm text-gray-600 hover:text-gray-900"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
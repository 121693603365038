import { useEffect } from 'react';
import { useSite } from '../context/SiteContext';

export function usePageTitle(pageTitle?: string) {
  const { siteName } = useSite();
  
  useEffect(() => {
    const titleElement = document.getElementById('page-title');
    if (titleElement) {
      titleElement.textContent = pageTitle 
        ? `${pageTitle} | ${siteName}`
        : `${siteName} - Your One-Stop Shop`;
    }
  }, [siteName, pageTitle]);
}
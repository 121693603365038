import React, { useState } from 'react';
import { CreditCard, Calendar, Lock } from 'lucide-react';
import { validateCreditCard } from '../../utils/paymentValidation';
import { encryptCardData } from '../../utils/encryption'; 
import { db } from '../../config/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import RegistrationModal from './RegistrationModal';
import { validateShippingDetails, isShippingComplete } from '../../utils/shippingValidation';
import { createOrder } from '../../utils/orderUtils';
import { useCart } from '../../context/CartContext'; 
import { useSite } from '../../context/SiteContext';
import { usePayment } from '../../context/PaymentContext';
import { useNavigate } from 'react-router-dom';

interface CreditCardFormProps {
  shippingDetails: {
    firstName: string;
    lastName: string;
    email: string;
    telephone: string;
    address: string;
    address2?: string;
    address3?: string;
    postcode: string;
    country: string;
  };
  totals: {
    baseAmount: number;
    taxAmount: number;
    totalAmount: number;
    finalTotal: number;
  };
  qualifiesForFreeShipping: boolean;
}

export default function CreditCardForm({ 
  shippingDetails, 
  totals,
  qualifiesForFreeShipping 
}: CreditCardFormProps) {
  const { setPaymentMethod, processing } = usePayment();
  const { state: cartState, dispatch: cartDispatch } = useCart();
  const { currency, settings } = useSite();
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentAttempts, setPaymentAttempts] = useState(0);
  const MAX_PAYMENT_ATTEMPTS = 3;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [cardData, setCardData] = useState({
    number: '',
    expiry: '',
    cvv: '',
    name: `${shippingDetails.firstName} ${shippingDetails.lastName}`.trim()
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [saveCard, setSaveCard] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);
  const [paymentError, setPaymentError] = useState<string | null>(null);

  const checkUserExists = async (email: string): Promise<boolean> => {
    const usersRef = collection(db, 'users');
    const q = query(usersRef, where('email', '==', email));
    const snapshot = await getDocs(q);

    return !snapshot.empty;
  };

  const processPayment = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (isProcessing || isSubmitted) {
      console.log('Payment already in progress');
      return;
    }
    
    if (paymentAttempts >= MAX_PAYMENT_ATTEMPTS) {
      setErrors({ submit: 'Maximum payment attempts reached. Please try again later.' });
      return;
    }

    setPaymentAttempts(prev => prev + 1);
    setIsProcessing(true);
    setErrors({});
    setPaymentError(null);
    setPaymentMethod({ type: 'credit-card', processing: true });

    try {
      // Validate cart first
      if (!cartState.items.length) {
        throw new Error('Cart is empty');
      }

      // Validate totals
      if (!totals || typeof totals.finalTotal !== 'number' || totals.finalTotal <= 0) {
        throw new Error('Invalid order total');
      }

      // Validate shipping details
      const shippingErrors = validateShippingDetails(shippingDetails);
      if (Object.keys(shippingErrors).length > 0) {
        throw new Error('Please complete all required shipping details');
      }

      // Validate card details
      const validation = validateCreditCard(cardData);
      if (!validation.isValid) {
        setErrors(validation.errors);
        throw new Error('Please check your card details');
      }

      // Encrypt card data
      const encryptedData = await encryptCardData(cardData);
      if (!encryptedData) {
        throw new Error('Failed to secure payment data');
      }

      // Check user existence
      const userExists = await checkUserExists(shippingDetails.email);
      if (!userExists) {
        setIsProcessing(false);
        setShowRegistration(true);
        return;
      }

      // Create order first to validate stock/availability
      const order = await createOrder({
        items: cartState.items,
        currency,
        taxRate: settings?.tax?.rate || 20,
        customerEmail: shippingDetails.email,
        customerName: cardData.name,
        shippingAddress: shippingDetails,
        total: totals.finalTotal,
        paymentMethod: {
          type: 'credit-card'
        }
      });

      if (!order?.id) {
        throw new Error('Failed to create order');
      }

      // Clear cart and navigate on success
      cartDispatch({ type: 'CLEAR_CART' });
      setIsSubmitted(true);
      navigate('/payment-success');
    } catch (error) {
      console.error('Payment processing error:', error);
      setPaymentError(error instanceof Error ? error.message : 'Payment processing failed');
      handlePaymentError();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await processPayment(e);
  };

  const handlePaymentError = () => {
    setIsProcessing(false);
    setPaymentMethod({ type: 'credit-card', processing: false });
    
    if (paymentAttempts >= MAX_PAYMENT_ATTEMPTS) {
      setErrors({ submit: 'Maximum payment attempts reached. Please try again later.' });
      setCardData({
        number: '',
        expiry: '',
        cvv: '',
        name: `${shippingDetails.firstName} ${shippingDetails.lastName}`.trim()
      });
      return;
    }
    
    if (paymentError) {
      setErrors({ submit: `${paymentError}. Please try again.` });
    }
  };

  const handleRegistrationSuccess = async () => {
    setShowRegistration(false);
    await processPayment(new Event('submit') as React.FormEvent);
  };

  return (
    <>
    <form onSubmit={handleSubmit} className="space-y-4">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Card Number
            </label>
            <div className="relative">
              <input
                type="text"
                maxLength={19}
                value={cardData.number}
                onChange={(e) => {
                  const formatted = e.target.value
                    .replace(/\s/g, '')
                    .replace(/(\d{4})/g, '$1 ')
                    .trim();
                  setCardData({ ...cardData, number: formatted });
                  setErrors({ ...errors, number: '' });
                }}
                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 ring-yellow-400"
                placeholder="1234 5678 9012 3456"
              />
              <CreditCard className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            {errors.number && (
              <p className="mt-1 text-sm text-red-600">{errors.number}</p>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Expiry Date
              </label>
              <div className="relative">
                <input
                  type="text"
                  maxLength={5}
                  value={cardData.expiry}
                  onChange={(e) => {
                    const formatted = e.target.value
                      .replace(/\D/g, '')
                      .replace(/(\d{2})(\d)/, '$1/$2');
                    setCardData({ ...cardData, expiry: formatted });
                    setErrors({ ...errors, expiry: '' });
                  }}
                  className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 ring-yellow-400"
                  placeholder="MM/YY"
                />
                <Calendar className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
              {errors.expiry && (
                <p className="mt-1 text-sm text-red-600">{errors.expiry}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                CVV
              </label>
              <div className="relative">
                <input
                  type="text"
                  maxLength={4}
                  value={cardData.cvv}
                  onChange={(e) => {
                    const formatted = e.target.value.replace(/\D/g, '');
                    setCardData({ ...cardData, cvv: formatted });
                    setErrors({ ...errors, cvv: '' });
                  }}
                  className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 ring-yellow-400"
                  placeholder="123"
                />
                <Lock className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
              {errors.cvv && (
                <p className="mt-1 text-sm text-red-600">{errors.cvv}</p>
              )}
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Cardholder Name
            </label>
            <input
              type="text"
              value={cardData.name}
              onChange={(e) => {
                setCardData({ ...cardData, name: e.target.value });
                setErrors({ ...errors, name: '' });
              }}
              className="w-full py-2 px-4 border rounded-lg focus:ring-2 ring-yellow-400"
              placeholder="John Smith"
            />
            {errors.name && (
              <p className="mt-1 text-sm text-red-600">{errors.name}</p>
            )}
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id="save-card"
              checked={saveCard}
              onChange={(e) => setSaveCard(e.target.checked)}
              className="h-4 w-4 text-yellow-400 focus:ring-yellow-400 border-gray-300 rounded"
            />
            <label htmlFor="save-card" className="ml-2 text-sm text-gray-600">
              Save card for future purchases
            </label>
          </div>

          <button
            type="submit"
            disabled={processing || isProcessing || isSubmitted || !cardData.number.trim()}
            className={`w-full py-2 px-4 rounded-lg text-black font-semibold ${
              processing || isProcessing || isSubmitted
                ? 'bg-gray-300 cursor-not-allowed'
                : isProcessing 
                  ? 'bg-green-500 text-white cursor-not-allowed'
                  : 'bg-yellow-400 hover:bg-yellow-500'
            }`}
          >
            {isSubmitted ? 'Payment Complete' : processing ? 'Processing...' : isProcessing ? 'Processing Payment...' : 'Pay Now'}
          </button>

          {errors.submit && (
            <p className="mt-2 text-sm text-red-600 text-center">{errors.submit}</p>
          )}
        </div>
    </form>

    <RegistrationModal
      isOpen={showRegistration}
      onClose={() => setShowRegistration(false)}
      onSuccess={handleRegistrationSuccess}
      shippingDetails={shippingDetails}
    />
    </>
  );
}
import { ThemeConfig } from '../types/theme';

export const halloweenTheme: ThemeConfig = {
  id: 'halloween',
  name: 'Halloween Theme',
  description: 'Spooky Halloween theme with dark mode and effects',
  colors: {
    primary: '#EA580C', // orange-600
    secondary: '#6B21A8', // purple-800
    accent: '#78350F', // amber-900
    background: '#18181B', // zinc-900
    text: '#F4F4F5', // zinc-100
    border: '#3F3F46', // zinc-700
  },
  assets: {
    backgroundImage: 'https://images.unsplash.com/photo-1508361001413-7a9dca21d08a?w=2000&auto=format&fit=crop&q=80',
    headerImage: 'https://images.unsplash.com/photo-1509557965875-b88c97052f0e?w=2000&auto=format&fit=crop&q=80',
    overlayEffect: 'fog',
    animations: ['fog', 'float']
  },
  isActive: false,
  lastModified: new Date().toISOString(),
  modifiedBy: 'system'
};
import React, { useState } from 'react';
import { Search, ArrowUpDown, Shield, Eye, Trash2 } from 'lucide-react';
import RoleManager from './RoleManager';
import UserProfileEditor from './UserProfile';
import { db } from '../../config/firebase';
import { collection, deleteDoc, doc } from 'firebase/firestore';
import { UserProfile } from '../../types/auth';

interface AdminUserListProps {
  users: (UserProfile & { id: string })[];
}

export default function AdminUserList({ users }: AdminUserListProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState<(UserProfile & { id: string }) | null>(null);
  const [userToDelete, setUserToDelete] = useState<(UserProfile & { id: string }) | null>(null);
  const [sortField, setSortField] = useState<keyof UserProfile>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [deleteError, setDeleteError] = useState<string | null>(null);

  const handleSort = (field: keyof UserProfile) => {
    if (field === sortField) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const filteredUsers = users
    .filter(user =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
      const modifier = sortDirection === 'asc' ? 1 : -1;
      
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return aValue.localeCompare(bValue) * modifier;
      }
      return 0;
    });

  const handleDelete = async () => {
    if (!userToDelete) return;
    
    try {
      await deleteDoc(doc(db, 'users', userToDelete.id));
      setUserToDelete(null);
      setRefreshTrigger(prev => prev + 1);
    } catch (error) {
      setDeleteError('Failed to delete user. Please try again.');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <div className="mb-4 relative">
        <input
          type="text"
          placeholder="Search users..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 border rounded-lg"
        />
        <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead>
            <tr className="border-b">
              <th className="py-2 text-left">
                <button
                  onClick={() => handleSort('name')}
                  className="flex items-center space-x-1"
                >
                  <span>Name</span>
                  <ArrowUpDown className="h-4 w-4" />
                </button>
              </th>
              <th className="py-2 text-left">
                <button
                  onClick={() => handleSort('email')}
                  className="flex items-center space-x-1"
                >
                  <span>Email</span>
                  <ArrowUpDown className="h-4 w-4" />
                </button>
              </th>
              <th className="py-2 text-left">Status</th>
              <th className="py-2 text-left">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id} className="border-b">
                <td className="py-2">{user.name}</td>
                <td className="py-2">{user.email}</td>
                <td className="py-2">
                  <span className={`px-2 py-1 rounded-full text-xs ${
                    user.status === 'active' 
                      ? 'bg-green-100 text-green-800'
                      : 'bg-red-100 text-red-800'
                  }`}>
                    {user.status}
                  </span>
                </td>
                <td className="py-2">
                  <div className="flex items-center space-x-3">
                    <button
                      onClick={() => setSelectedUser(user)}
                      className="p-2 text-blue-600 hover:text-blue-800 hover:bg-blue-50 rounded-lg transition-colors"
                      title="Edit User"
                    >
                      <Eye className="h-5 w-5" />
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        setUserToDelete(user);
                      }}
                      className="p-2 text-red-600 hover:text-red-800 hover:bg-red-50 rounded-lg transition-colors"
                      title="Delete User"
                    >
                      <Trash2 className="h-5 w-5" />
                    </button>
                    <RoleManager 
                      user={user}
                      onRoleUpdate={() => setRefreshTrigger(prev => prev + 1)}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      
      {selectedUser && (
        <UserProfileEditor
          user={selectedUser}
          onClose={() => setSelectedUser(null)}
          onUpdate={() => setRefreshTrigger(prev => prev + 1)}
        />
      )}
      
      {/* Delete Confirmation Modal */}
      {userToDelete && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <h3 className="text-lg font-semibold mb-2">Delete User</h3>
            <p className="text-gray-600 mb-4">
              Are you sure you want to delete {userToDelete.name}? This action cannot be undone.
            </p>
            {deleteError && (
              <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
                {deleteError}
              </div>
            )}
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => {
                  setUserToDelete(null);
                  setDeleteError(null);
                }}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg"
              >
                Delete User
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export interface PriceBreakdown {
  basePrice: number;
  taxAmount: number;
  totalPrice: number;
  formattedBase: string;
  formattedTax: string;
  formattedTotal: string;
  taxRate: number;
}

export function shouldApplyFreeShipping(subtotal: number, threshold: number): boolean {
  if (isNaN(subtotal) || isNaN(threshold) || threshold <= 0) {
    return false;
  }
  return subtotal >= threshold;
}

export function calculateTax(price: number, taxRate: number): number {
  return Math.round((price * (taxRate / 100)) * 100) / 100;
}

export function calculateTotalWithTax(price: number, taxRate: number): number {
  return Math.round((price * (1 + taxRate / 100)) * 100) / 100;
}

export function formatPrice(
  price: number,
  currency: 'USD' | 'GBP' | 'EUR',
  includeTax: boolean = false,
  taxRate?: number
): string {
  const finalPrice = includeTax && taxRate ? calculateTotalWithTax(price, taxRate) : price;
  
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
  
  return formatter.format(finalPrice);
}

export function getPriceBreakdown(
  basePrice: number,
  currency: 'USD' | 'GBP' | 'EUR',
  taxRate: number
): PriceBreakdown {
  const taxAmount = calculateTax(basePrice, taxRate);
  const totalPrice = calculateTotalWithTax(basePrice, taxRate);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return {
    basePrice,
    taxAmount,
    totalPrice,
    formattedBase: formatter.format(basePrice),
    formattedTax: formatter.format(taxAmount),
    formattedTotal: formatter.format(totalPrice),
    taxRate
  };
}
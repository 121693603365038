import React, { useState, useEffect } from 'react';
import { Trash2, Image as ImageIcon, AlertCircle, RefreshCw } from 'lucide-react';
import { deleteStorageFile, listStorageFiles, getImageUsage } from '../../../utils/imageStorage';
import { StorageImage } from '../../../types/storage';
import ImageGrid from './ImageGrid';
import { cn } from '../../../utils/cn';
import { ensureAuth } from '../../../utils/auth/permissions';
import { withRetry } from '../../../utils/imageRetry';
import { auth } from '../../../config/firebase';
import { testStorageConnection } from '../../../utils/storageTest';

export default function ImageManager() {
  const [images, setImages] = useState<StorageImage[]>([]);
  const [selectedImages, setSelectedImages] = useState<Set<string>>(new Set());
  const [loading, setLoading] = useState(true);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState<{success: boolean; message: string} | null>(null);
  const [imageUsage, setImageUsage] = useState<Record<string, string[]>>({});
  const [deleting, setDeleting] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;

  // Only handle connection check initially
  useEffect(() => {
    let mounted = true;
    let retryTimeout: NodeJS.Timeout;
    
    async function checkConnection() {
      if (!mounted) return;
      
      setLoading(true);
      setConnectionStatus(null);
      
      try {
        const status = await testStorageConnection();
        if (!mounted) return;
        
        setConnectionStatus(status);
        setError(status.success ? null : status.message);
        
        // Only proceed with loading images if connection is successful
        if (status.success) {
          await loadImagesWithProgress();
        }
      } catch (error) {
        if (!mounted) return;
        setConnectionStatus({
          success: false,
          message: 'Connection check failed'
        });
        setError('Failed to verify storage connection');
        setLoading(false);
      }
    }

    checkConnection();

    return () => {
      mounted = false;
      if (retryTimeout) clearTimeout(retryTimeout);
    };
  }, []);

  const handleAuthError = (error: any) => {
    console.error('Auth error:', error);
    if (error.code === 'auth/network-request-failed') {
      return 'Network connection failed. Please check your internet connection.';
    }
    if (error.code === 'auth/user-token-expired') {
      return 'Your session has expired. Please sign in again.';
    }
    if (error.code === 'permission-denied') {
      return 'You do not have permission to access the image manager.';
    }
    return error instanceof Error ? error.message : 'Failed to load images';
  };

  const loadImagesWithProgress = async () => {
    try {
      console.log('Starting image load...');
      setLoading(true);
      setLoadingProgress(0);
      setError(null);
      
      // Ensure we're authenticated
      if (!auth.currentUser) {
        throw new Error('Authentication required');
      }

      // Force token refresh
      await auth.currentUser.getIdToken(true);

      const loadedImages = await withRetry(
        () => listStorageFiles((progress) => {
          setLoadingProgress(Math.min(progress, 95));
        }),
        { maxAttempts: MAX_RETRIES }
      );
      
      const usage = await withRetry(
        () => getImageUsage(loadedImages.map(img => img.path)),
        { maxAttempts: MAX_RETRIES }
      );
      
      setImages(loadedImages);
      setImageUsage(usage);
      setLoadingProgress(100);

    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to load images';
      console.error('Image loading failed:', errorMessage);
      setError(errorMessage);
      setLoadingProgress(0);
      
      // Implement retry logic
      if (retryCount < MAX_RETRIES) {
        setRetryCount(prev => prev + 1);
        setTimeout(() => {
          loadImagesWithProgress();
        }, Math.pow(2, retryCount) * 1000);
      }
    } finally {
      setLoading(false);
    }
  };

  // Load images only after successful connection
  useEffect(() => {
    if (!connectionStatus?.success) return;

    let mounted = true;
    let retryTimeout: NodeJS.Timeout;
    let retryCount = 0;

    const loadImages = async () => {
      if (!mounted) return;
      
      try {
        if (retryCount >= MAX_RETRIES) {
          setError('Failed to load images after multiple attempts');
          return;
        }

        setError(null);
        await ensureAuth();
        await loadImagesWithProgress();
      } catch (error) {
        console.error('Failed to load images:', error);
        const errorMessage = handleAuthError(error);
        setError(errorMessage);
        
        if (error.code === 'auth/network-request-failed' || error.code === 'permission-denied') {
          retryCount++;
          const delay = Math.min(5000 * Math.pow(2, retryCount), 30000);
          retryTimeout = setTimeout(loadImages, delay);
        }
      }
    };

    loadImages();
    
    return () => {
      mounted = false;
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, [connectionStatus?.success]);

  const handleRetry = () => {
    setError(null);
    setLoading(true);
    setConnectionStatus(null);
    setLoadingProgress(0);

    setConnectionStatus(null);
    setLoadingProgress(0);

    setConnectionStatus(null);
    setLoadingProgress(0);

    setConnectionStatus(null);
    setLoadingProgress(0);

    testStorageConnection().then(status => {
      setConnectionStatus(status);
      setError(status.success ? null : status.message);
      setLoading(!status.success);
      
      if (status.success) {
        loadImagesWithProgress();
      }
      
      if (status.success) {
        loadImagesWithProgress();
      }
      
      if (status.success) {
        loadImagesWithProgress();
      }
      
      if (status.success) {
        loadImagesWithProgress();
      }
    });
  };

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] space-y-4">
        <div className="bg-red-50 text-red-700 p-4 rounded-lg flex items-center space-x-2 max-w-md text-center">
          <AlertCircle className="h-5 w-5" />
          <span>{error}</span>
        </div>
        <button
          onClick={handleRetry}
          className="px-4 py-2 bg-yellow-400 hover:bg-yellow-500 rounded-lg flex items-center space-x-2 transition-colors"
        >
          <RefreshCw className="h-5 w-5" />
          <span>Retry Loading Images</span>
        </button>
      </div>
    );
  }

  const handleSelectUnused = () => {
    const unusedImages = new Set(
      images
        .filter(img => !imageUsage[img.path]?.length)
        .map(img => img.path)
    );
    setSelectedImages(unusedImages);
  };

  const handleImageSelect = (imagePath: string) => {
    const newSelected = new Set(selectedImages);
    if (newSelected.has(imagePath)) {
      newSelected.delete(imagePath);
    } else {
      newSelected.add(imagePath);
    }
    setSelectedImages(newSelected);
  };

  const handleBulkDelete = async () => {
    if (!selectedImages.size) return;
    
    // Check if any selected images are in use
    const usedImages = Array.from(selectedImages)
      .filter(path => imageUsage[path]?.length > 0);
    
    if (usedImages.length > 0) {
      setError('Cannot delete images that are in use');
      return;
    }

    try {
      setDeleting(true);
      for (const path of selectedImages) {
        await deleteStorageFile(path);
      }
      
      // Update images list
      setImages(prev => prev.filter(img => !selectedImages.has(img.path)));
      setSelectedImages(new Set());
      setShowDeleteConfirm(false);
    } catch (err) {
      setError('Failed to delete some images');
      console.error('Delete error:', err);
    } finally {
      setDeleting(false);
    }
  };

  const categories = {
    'theme-headers': 'Header Images',
    'theme-backgrounds': 'Background Images',
    'products': 'Product Images',
    'decorative': 'Decorative Items'
  };

  return (
    <div className="space-y-6 p-6">
      {/* Loading Indicator */}
      {loading && !images.length && (
        <div className="flex justify-center items-center min-h-[400px]">
          <div className="flex flex-col items-center space-y-4">
            <RefreshCw className="h-12 w-12 animate-spin text-yellow-400" />
            <div className="text-center">
              <p className="text-sm font-medium text-gray-900">Loading images...</p>
              <p className="text-sm text-gray-600">{Math.round(loadingProgress)}%</p>
            </div>
          </div>
        </div>
      )}
      
      {/* Connection Status */}
      <div className={cn(
        "p-4 rounded-lg flex items-center justify-between mt-8",
        connectionStatus?.success ? "bg-green-50 text-green-700" : "bg-yellow-50 text-yellow-700",
        !connectionStatus && "bg-gray-50 text-gray-600"
      )}>
        <div className={cn(
          "flex items-center space-x-2",
          !connectionStatus && "animate-pulse"
        )}>
          <AlertCircle className="h-5 w-5" />
          <span className="font-medium">Storage Connection:</span>
          <span>
            {connectionStatus 
              ? connectionStatus.message 
              : 'Checking connection...'}
          </span>
        </div>
        <button
          onClick={handleRetry}
          className={cn(
            "px-3 py-1 rounded-md flex items-center space-x-1 transition-colors",
            "bg-white bg-opacity-50 hover:bg-opacity-75",
            loading && "opacity-50 cursor-not-allowed"
          )}
          disabled={loading}
        >
          <RefreshCw className={cn("h-4 w-4", loading && "animate-spin")} />
          <span>{loading ? 'Checking...' : 'Check Connection'}</span>
        </button>
      </div>

      {/* Image Management Interface */}
      {(images.length > 0 || !loading) && (
        <>
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-bold flex items-center space-x-2">
              <ImageIcon className="h-6 w-6" />
              <span>Image Management</span>
            </h2>
            
            <div className="flex items-center space-x-4">
              <button
                onClick={handleSelectUnused}
                className="text-sm text-blue-600 hover:text-blue-800"
              >
                Select Unused Images
              </button>
              
              <button
                onClick={() => setShowDeleteConfirm(true)}
                disabled={selectedImages.size === 0 || deleting}
                className={cn(
                  "flex items-center space-x-2 px-4 py-2 rounded-lg",
                  selectedImages.size === 0 || deleting
                    ? "bg-gray-300 cursor-not-allowed"
                    : "bg-red-600 hover:bg-red-700 text-white"
                )}
              >
                <Trash2 className="h-5 w-5" />
                <span>Delete Selected ({selectedImages.size})</span>
              </button>
            </div>
          </div>

          {Object.entries(categories).map(([category, title]) => {
            const categoryImages = images.filter(img => img.path.startsWith(category));
            if (!categoryImages.length) return null;

            return (
              <div key={category} className="space-y-4">
                <h3 className="text-lg font-semibold">{title}</h3>
                <ImageGrid
                  images={categoryImages}
                  selectedImages={selectedImages}
                  imageUsage={imageUsage}
                  onSelect={handleImageSelect}
                />
              </div>
            );
          })}
        </>
      )}

      {/* Delete Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
            <h3 className="text-lg font-semibold mb-2">Confirm Deletion</h3>
            <p className="text-gray-600 mb-4">
              Are you sure you want to delete {selectedImages.size} images? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleBulkDelete}
                className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-lg"
              >
                Delete Images
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
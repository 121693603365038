import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase';
import { Product } from '../types/product';
import AdminLayout from '../components/admin/AdminLayout';
import AdminProductList from '../components/admin/AdminProductList';
import ProductImportExport from '../components/admin/ProductImportExport';
import { migrateProductData } from '../utils/productMigration';
import { AlertCircle } from 'lucide-react';

export default function AdminProductsPage() {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);

    try {
      // Set up real-time listener for products
      const productsQuery = query(
        collection(db, 'products'),
        orderBy('title')
      );

      const unsubscribe = onSnapshot(
        productsQuery,
        (snapshot) => {
          const productData: Product[] = [];
          snapshot.forEach((doc) => {
            productData.push({ id: doc.id, ...doc.data() } as Product);
          });
          setProducts(productData);
          setLoading(false);
          setError(null);
        },
        (err) => {
          console.error('Error fetching products:', err);
          setError('Error loading product data. Please try again later.');
          setLoading(false);
        }
      );

      // Cleanup subscription
      return () => unsubscribe();
    } catch (err) {
      console.error('Error setting up products listener:', err);
      setError('Error loading product data. Please try again later.');
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex justify-center items-center min-h-[400px]">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      </AdminLayout>
    );
  }

  if (error) {
    return (
      <AdminLayout>
        <div className="p-6">
          <div className="bg-red-50 text-red-800 p-4 rounded-lg">
            {error}
          </div>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="p-6 space-y-6">
        <ProductImportExport />
        <AdminProductList products={products} />
      </div>
    </AdminLayout>
  );
}
import { db, auth } from '../config/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

let isOnline = true;
let connectionTimeout: NodeJS.Timeout;

export async function handleConnectionChange(online: boolean) {
  if (online === isOnline) return;
  isOnline = online;
  console.log('Connection status:', online ? 'online' : 'offline');
  
  // Clear any existing timeout
  if (connectionTimeout) {
    clearTimeout(connectionTimeout);
  }
  
  if (online) {
    // Verify connection with timeout
    connectionTimeout = setTimeout(async () => {
      try {
        const isConnected = await checkConnection();
        if (!isConnected) {
          console.log('Connection check failed, reverting to offline');
          handleConnectionChange(false);
        }
      } catch (error) {
        console.error('Connection check error:', error);
        handleConnectionChange(false);
      }
    }, 5000);
  }
}

export async function checkConnection(): Promise<boolean> {
  try {
    if (!navigator.onLine) {
      return false;
    }

    // Add timeout to prevent hanging
    const timeoutPromise = new Promise<boolean>((_, reject) => {
      setTimeout(() => reject(new Error('Connection check timeout')), 5000);
    });

    const testQuery = query(
      collection(db, 'users'),
      where('email', '==', 'admin@mmm.co.uk')
    );
    
    const queryPromise = getDocs(testQuery).then(() => true);
    return Promise.race([queryPromise, timeoutPromise]);
  } catch (error) {
    console.error('Connection check failed:', error);
    return false;
  }
}
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import { collection, query, onSnapshot, orderBy } from 'firebase/firestore';
import { db } from '../config/firebase';
import { Product } from '../types/product';

interface SearchContextType {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
  selectedCategory: string | null;
  setSelectedCategory: (category: string | null) => void;
  searchResults: Product[];
  loading: boolean;
  error: string | null;
}

const SearchContext = createContext<SearchContextType | null>(null);

export function SearchProvider({ children }: { children: ReactNode }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    
    try {
      // Set up real-time listener for products
      const productsQuery = query(collection(db, 'products'), orderBy('title'));
      const unsubscribe = onSnapshot(productsQuery, 
        (snapshot) => {
          const productData: Product[] = [];
          snapshot.forEach((doc) => {
            productData.push({ id: doc.id, ...doc.data() } as Product);
          });
          setProducts(productData);
          setLoading(false);
          setError(null);
        },
        (err) => {
          console.error('Error fetching products:', err);
          setError('Failed to load products');
          setLoading(false);
        }
      );

      // Cleanup subscription
      return () => unsubscribe();
    } catch (err) {
      console.error('Error setting up products listener:', err);
      setError('Failed to load products');
      setLoading(false);
    }
  }, []);

  const searchResults = products.filter((product) => {
    const matchesSearch = 
      product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      product.description.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesCategory = !selectedCategory || 
      (product.category && product.category.toLowerCase() === selectedCategory.toLowerCase());
    
    return matchesSearch && matchesCategory && product.status !== 'inactive';
  });

  return (
    <SearchContext.Provider value={{ 
      searchTerm, 
      setSearchTerm, 
      selectedCategory,
      setSelectedCategory,
      searchResults, 
      loading,
      error
    }}>
      {children}
    </SearchContext.Provider>
  );
}

export function useSearch() {
  const context = useContext(SearchContext);
  if (!context) {
    throw new Error('useSearch must be used within a SearchProvider');
  }
  return context;
}
import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase';
import { AlertCircle, RefreshCw } from 'lucide-react';
import { Order } from '../types/order';
import AdminLayout from '../components/admin/AdminLayout';
import AdminStats from '../components/admin/AdminStats';
import OrderAnalytics from '../components/admin/orders/OrderAnalytics';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

export default function AdminDashboard() {
  const { userProfile } = useAuth();
  const navigate = useNavigate();
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);
    
    try {
      // Set up real-time listener for orders
      const ordersQuery = query(
        collection(db, 'orders'),
        orderBy('created_at', 'desc')
      );

      const unsubscribe = onSnapshot(
        ordersQuery,
        (snapshot) => {
          const orderData: Order[] = [];
          snapshot.forEach((doc) => {
            orderData.push({ id: doc.id, ...doc.data() } as Order);
          });
          setOrders(orderData);
          setLoading(false);
          setError(null);
        },
        (err) => {
          console.error('Error fetching orders:', err);
          setError('Failed to load orders');
          setLoading(false);
        }
      );

      // Cleanup subscription
      return () => unsubscribe();
    } catch (err) {
      console.error('Error setting up orders listener:', err);
      setError('Failed to load orders');
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex justify-center items-center min-h-[400px]">
          <RefreshCw className="h-12 w-12 animate-spin text-yellow-400" />
        </div>
      </AdminLayout>
    );
  }

  if (error) {
    return (
      <AdminLayout>
        <div className="p-6">
          <div className="bg-red-50 text-red-800 p-4 rounded-lg flex items-center space-x-2">
            <AlertCircle className="h-5 w-5" />
            <span>{error}</span>
          </div>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="p-6 space-y-8">
        <AdminStats />
        <OrderAnalytics orders={orders} />
      </div>
    </AdminLayout>
  );
}
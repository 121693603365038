import { db } from '../config/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';

interface XRPAddress {
  address: string;
  tag?: string;
}

type CryptoAddress = string | XRPAddress;

export async function getCryptoPrice(crypto: string, currency: string): Promise<number> {
  try {
    const cryptoId = getCryptoId(crypto);
    // Fallback rates for stability
    const fallbackRates = {
      BTC: { usd: 65000, eur: 60000, gbp: 52000 },
      ETH: { usd: 3500, eur: 3200, gbp: 2800 },
      XRP: { usd: 0.6, eur: 0.55, gbp: 0.48 }
    };

    const currencyLower = currency.toLowerCase();
    if (!fallbackRates[crypto] || !fallbackRates[crypto][currencyLower]) {
      throw new Error('Unsupported currency pair');
    }

    try {
      // Add API request timeout and retries
      const response = await fetch(
        `https://api.coingecko.com/api/v3/simple/price?ids=${cryptoId}&vs_currencies=${currencyLower}&precision=8`,
        { 
          headers: {
            'Accept': 'application/json',
            'User-Agent': 'Boltazon/1.0',
            'Cache-Control': 'no-cache'
          },
          signal: AbortSignal.timeout(5000)
        }
      );

      if (!response.ok) {
        console.warn('Price API error, using fallback rate');
        return fallbackRates[crypto][currencyLower];
      }

      const data = await response.json();
      const price = data[cryptoId][currencyLower];

      if (!price || price <= 0) {
        console.warn('Invalid price received, using fallback rate');
        return fallbackRates[crypto][currencyLower];
      }

      return price;
    } catch (error) {
      console.warn('Using fallback rate due to error:', error.message);
      return fallbackRates[crypto][currencyLower];
    }
  } catch (error) {
    console.error('Crypto price fetch error:', error);
    throw new Error('Unable to get current exchange rate - using fallback rate');
  }
}

function getCryptoId(crypto: string): string {
  const cryptoIds: Record<string, string> = {
    BTC: 'bitcoin',
    ETH: 'ethereum',
    XRP: 'ripple'
  };

  const id = cryptoIds[crypto];
  if (!id) {
    throw new Error(`Unsupported cryptocurrency: ${crypto}`);
  }

  return id;
}

// Fetch saved crypto addresses
export async function getSavedCryptoAddress(currency: string): Promise<CryptoAddress> {
  try {
    const cryptoSettingsRef = collection(db, 'crypto_settings');
    const q = query(
      cryptoSettingsRef,
      where('currency', '==', currency),
      where('enabled', '==', true)
    );
    
    const snapshot = await getDocs(q);
    
    if (snapshot.empty) {
      throw new Error(`No wallet address configured for ${currency}`);
    }

    const data = snapshot.docs[0].data();

    if (currency === 'XRP' && data.destination_tag) {
      return {
        address: data.wallet_address,
        tag: data.destination_tag
      };
    }

    return data.wallet_address;
  } catch (error) {
    console.error('Error fetching crypto address:', error);
    throw error;
  }
}

export function validateCryptoAddress(address: CryptoAddress | string, currency: string): boolean {
  // Basic validation - in production, implement proper crypto address validation
  const patterns: Record<string, RegExp> = {
    BTC: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,90}$/,
    ETH: /^0x[a-fA-F0-9]{40}$/,
    XRP: /^r[1-9A-HJ-NP-Za-km-z]{24,34}$/,
  };

  // Handle XRP address object format
  if (currency === 'XRP' && typeof address === 'object') {
    const isValidAddress = patterns.XRP.test(address.address);
    const isValidTag = !address.tag || (/^\d+$/.test(address.tag) && parseInt(address.tag) >= 0);
    return isValidAddress && isValidTag;
  }

  // Handle string addresses for other currencies
  if (typeof address === 'string') {
    return patterns[currency]?.test(address) || false;
  }

  return false;
}

export function validateCryptoAmount(amount: string, expectedAmount: number): boolean {
  const parsedAmount = parseFloat(amount);
  if (isNaN(parsedAmount) || parsedAmount <= 0) return false;
  
  // Allow small difference (0.1%) to account for rounding
  const difference = Math.abs(parsedAmount - expectedAmount);
  const tolerance = expectedAmount * 0.001;
  return difference <= tolerance;
}

export function validateTransactionHash(hash: string, currency: string): boolean {
  const patterns: Record<string, RegExp> = {
    BTC: /^[a-fA-F0-9]{64}$/,
    ETH: /^0x[a-fA-F0-9]{64}$/,
    XRP: /^[A-F0-9]{64}$/
  };

  return patterns[currency]?.test(hash) || false;
}

export function estimateTransactionFee(amount: number, currency: string): number {
  // Simplified fee calculation - in production, fetch real network fees
  const feeRates: Record<string, number> = {
    BTC: 0.0001,
    ETH: 0.002,
    XRP: 0.00001
  };

  return amount * (feeRates[currency] || 0);
}
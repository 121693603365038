import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import * as XLSX from 'xlsx';
import { format } from 'date-fns';
import { UserProfile } from '../types/auth';

const BATCH_SIZE = 1000;
const MAX_RECORDS = 10000;

export interface ExportOptions {
  dateRange?: {
    start: Date;
    end: Date;
  };
  status?: 'active' | 'inactive' | 'all';
  role?: 'admin' | 'user' | 'all';
  columns: (keyof UserProfile)[];
}

export interface ExportProgress {
  processed: number;
  total: number;
  status: 'processing' | 'complete' | 'error';
  error?: string;
}

export const exportUsers = async (
  options: ExportOptions,
  onProgress: (progress: ExportProgress) => void
): Promise<Blob> => {
  try {
    const usersRef = collection(db, 'users');
    let constraints = [];

    if (options.dateRange) {
      constraints.push(
        where('registrationDate', '>=', options.dateRange.start.toISOString()),
        where('registrationDate', '<=', options.dateRange.end.toISOString())
      );
    }

    if (options.status && options.status !== 'all') {
      constraints.push(where('status', '==', options.status));
    }

    if (options.role && options.role !== 'all') {
      constraints.push(where('role', '==', options.role));
    }

    const q = query(usersRef, ...constraints);
    const snapshot = await getDocs(q);
    const users = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    onProgress({
      processed: 0,
      total: users.length,
      status: 'processing'
    });

    // Filter and format data according to selected columns
    const exportData = users.map(user => {
      const row: any = {};
      options.columns.forEach(col => {
        if (col === 'address2' || col === 'address3') {
          row[col] = user[col] || '';
        } else if (col === 'registrationDate' || col === 'lastLoginDate') {
          row[col] = user[col] ? format(new Date(user[col]!), 'PPpp') : '';
        } else {
          row[col] = user[col] || '';
        }
      });
      return row;
    });

    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Format headers
    const headerRange = XLSX.utils.decode_range(ws['!ref'] || 'A1');
    for (let col = headerRange.s.c; col <= headerRange.e.c; col++) {
      const cell = XLSX.utils.encode_cell({ r: 0, c: col });
      if (!ws[cell]) continue;
      ws[cell].s = {
        font: { bold: true },
        alignment: { horizontal: 'center' }
      };
    }

    // Add export details in footer
    const footerData = [
      [''],
      ['Export Details'],
      [`Generated: ${format(new Date(), 'PPpp')}`],
      [`Total Records: ${users.length}`],
      [`Filters Applied: ${getFilterDescription(options)}`]
    ];

    XLSX.utils.sheet_add_aoa(ws, footerData, {
      origin: -1
    });

    XLSX.utils.book_append_sheet(wb, ws, 'Users');

    // Auto-size columns
    const colWidths = options.columns.map(col => ({
      wch: Math.max(
        col.length,
        ...exportData.map(row => String(row[col]).length)
      )
    }));
    ws['!cols'] = colWidths;

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    
    onProgress({
      processed: users.length,
      total: users.length,
      status: 'complete'
    });

    return new Blob([wbout], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  } catch (error) {
    onProgress({
      processed: 0,
      total: 0,
      status: 'error',
      error: error.message
    });
    throw error;
  }
};

function getFilterDescription(options: ExportOptions): string {
  const filters = [];
  
  if (options.dateRange) {
    filters.push(`Date Range: ${format(options.dateRange.start, 'PP')} to ${format(options.dateRange.end, 'PP')}`);
  }
  
  if (options.status && options.status !== 'all') {
    filters.push(`Status: ${options.status}`);
  }
  
  if (options.role && options.role !== 'all') {
    filters.push(`Role: ${options.role}`);
  }

  return filters.join(', ') || 'None';
}
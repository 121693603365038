import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '../../config/firebase';
import { verifyAdminAccess } from './permissions';
import { checkNetworkConnectivity } from './network';

export async function initializeAdmin(): Promise<void> {
  try {
    // Check if already signed in
    const currentUser = auth.currentUser;
    if (!currentUser) {
      return;
    }

    // Force token refresh with timeout
    try {
      // Get fresh token with claims
      const idTokenResult = await currentUser.getIdTokenResult(true);
      
      // Check if user already has admin claim
      if (idTokenResult.claims.admin === true) {
        return;
      }
    } catch (error) {
      console.error('Token refresh failed:', error);
      return;
    }

    // Verify admin access
    const hasAccess = await verifyAdminAccess();
    if (!hasAccess) {
      return;
    }

  } catch (error) {
    console.error('Admin initialization failed:', error);
    throw error;
  }
}
import React, { useState, useEffect } from 'react';
import { db } from '../../config/firebase';
import { collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import { Product } from '../../types/product';
import { generateSEO } from '../../modules/seo/generator';
import { AlertCircle, RefreshCw, Wand2 } from 'lucide-react';

export default function BulkSEOOptimizer() {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [optimizing, setOptimizing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<{
    total: number;
    optimized: number;
    skipped: number;
  } | null>(null);

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    try {
      setLoading(true);
      const productsRef = collection(db, 'products');
      const snapshot = await getDocs(productsRef);
      const products = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      setProducts(products || []);
    } catch (err) {
      setError('Failed to fetch products');
    } finally {
      setLoading(false);
    }
  };

  const needsSEO = (product: Product): boolean => {
    return !product.seo || 
           !product.seo.title || 
           !product.seo.description || 
           !product.seo.keywords?.length ||
           !product.seo.lastGenerated;
  };

  const optimizeSEO = async () => {
    try {
      setOptimizing(true);
      setError(null);
      setProgress(0);

      const productsToOptimize = products.filter(needsSEO);
      let optimized = 0;
      let skipped = 0;
      const timestamp = new Date().toISOString();

      for (let i = 0; i < productsToOptimize.length; i++) {
        const product = productsToOptimize[i];
        try {
          const seoContent = generateSEO(product);
          
          const productRef = doc(db, 'products', product.id);
          await updateDoc(productRef, {
              seo: {
                ...seoContent,
                lastGenerated: timestamp
              },
              updatedAt: timestamp
          });

          optimized++;
        } catch (err) {
          console.error(`Failed to optimize SEO for product ${product.id}:`, err);
          skipped++;
        }

        setProgress(Math.round(((i + 1) / productsToOptimize.length) * 100));
      }

      setResult({
        total: productsToOptimize.length,
        optimized,
        skipped
      });

    } catch (err) {
      setError('Failed to optimize SEO content');
    } finally {
      setOptimizing(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center py-8">
        <RefreshCw className="h-8 w-8 animate-spin text-yellow-400" />
      </div>
    );
  }

  const productsNeedingSEO = products.filter(needsSEO);

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-lg font-semibold">Bulk SEO Optimization</h3>
          <p className="text-sm text-gray-600">
            {productsNeedingSEO.length} products need SEO optimization
          </p>
        </div>
        <button
          onClick={optimizeSEO}
          disabled={optimizing || productsNeedingSEO.length === 0}
          className={`flex items-center space-x-2 px-4 py-2 rounded-lg ${
            optimizing || productsNeedingSEO.length === 0
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-yellow-400 hover:bg-yellow-500'
          }`}
        >
          <Wand2 className="h-5 w-5" />
          <span>
            {optimizing ? 'Optimizing...' : 'Optimize All'}
          </span>
        </button>
      </div>

      {optimizing && (
        <div className="bg-blue-50 p-4 rounded-lg">
          <div className="flex items-center justify-between mb-2">
            <span className="font-medium text-blue-700">Optimizing products...</span>
            <span className="text-sm text-blue-600">
              {progress}%
            </span>
          </div>
          <div className="w-full bg-blue-200 rounded-full h-2">
            <div
              className="bg-blue-600 rounded-full h-2 transition-all duration-300"
              style={{ width: `${progress}%` }}
            />
          </div>
        </div>
      )}

      {error && (
        <div className="bg-red-50 text-red-700 p-4 rounded-lg flex items-center space-x-2">
          <AlertCircle className="h-5 w-5" />
          <span>{error}</span>
        </div>
      )}

      {result && (
        <div className="bg-green-50 text-green-700 p-4 rounded-lg">
          <h4 className="font-medium mb-2">Optimization Complete</h4>
          <ul className="space-y-1 text-sm">
            <li>Total products processed: {result.total}</li>
            <li>Successfully optimized: {result.optimized}</li>
            <li>Skipped/Failed: {result.skipped}</li>
          </ul>
        </div>
      )}

      <div className="border rounded-lg overflow-hidden">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Product
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                SEO Status
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Last Generated
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {productsNeedingSEO.map((product) => (
              <tr key={product.id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="text-sm font-medium text-gray-900">
                    {product.title}
                  </div>
                  <div className="text-sm text-gray-500">
                    {product.category}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    Needs Optimization
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {product.seo?.lastGenerated 
                    ? new Date(product.seo.lastGenerated).toLocaleString()
                    : 'Never'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
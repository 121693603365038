import React, { useState } from 'react';
import { Save, X, Eye, AlertCircle } from 'lucide-react';
import { db } from '../../config/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { UserProfile } from '../../types/auth';

interface UserProfileEditorProps {
  user: UserProfile & { id: string };
  onClose: () => void;
  onUpdate: () => void;
}

export default function UserProfileEditor({ user, onClose, onUpdate }: UserProfileEditorProps) {
  const [formData, setFormData] = useState({
    name: user.name || '',
    email: user.email || '',
    role: user.role || 'user',
    status: user.status || 'active',
    address: user.address || '',
    address2: user.address2 || '',
    address3: user.address3 || '',
    postcode: user.postcode || '',
    telephone: user.telephone || '',
    country: user.country || ''
  });
  const [isPreview, setIsPreview] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    setError(null);

    try {
      const timestamp = new Date().toISOString();
      
      const userRef = doc(db, 'users', user.id);
      await updateDoc(userRef, {
        name: formData.name,
        email: formData.email,
        role: formData.role,
        status: formData.status,
        address: formData.address,
        address2: formData.address2,
        address3: formData.address3,
        postcode: formData.postcode,
        telephone: formData.telephone,
        country: formData.country,
        lastModified: timestamp,
        modifiedBy: 'admin'
      });

      setSuccess(true);
      onUpdate();
      setTimeout(() => {
        setSuccess(false);
        onClose();
      }, 2000);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update user');
    } finally {
      setIsSaving(false);
    }
  };

  if (isPreview) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg p-8 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl font-bold">Preview Changes</h2>
            <button onClick={() => setIsPreview(false)} className="text-gray-500 hover:text-gray-700">
              <X className="h-6 w-6" />
            </button>
          </div>
          
          <div className="grid grid-cols-2 gap-8">
            <div>
              <h3 className="font-semibold mb-4">Current Data</h3>
              <div className="space-y-4">
                <div>
                  <span className="text-gray-500">Name:</span>
                  <span className="ml-2">{user.name}</span>
                </div>
                <div>
                  <span className="text-gray-500">Email:</span>
                  <span className="ml-2">{user.email}</span>
                </div>
                <div>
                  <span className="text-gray-500">Role:</span>
                  <span className="ml-2">{user.role}</span>
                </div>
                <div>
                  <span className="text-gray-500">Status:</span>
                  <span className="ml-2">{user.status}</span>
                </div>
                <div>
                  <span className="text-gray-500">Address:</span>
                  <span className="ml-2">{user.address}</span>
                </div>
                <div>
                  <span className="text-gray-500">Address Line 2:</span>
                  <span className="ml-2">{user.address2 || '-'}</span>
                </div>
                <div>
                  <span className="text-gray-500">Address Line 3:</span>
                  <span className="ml-2">{user.address3 || '-'}</span>
                </div>
                <div>
                  <span className="text-gray-500">Postcode:</span>
                  <span className="ml-2">{user.postcode}</span>
                </div>
                <div>
                  <span className="text-gray-500">Telephone:</span>
                  <span className="ml-2">{user.telephone}</span>
                </div>
              </div>
            </div>
            
            <div>
              <h3 className="font-semibold mb-4">New Data</h3>
              <div className="space-y-4">
                <div>
                  <span className="text-gray-500">Name:</span>
                  <span className="ml-2">{formData.name}</span>
                </div>
                <div>
                  <span className="text-gray-500">Email:</span>
                  <span className="ml-2">{formData.email}</span>
                </div>
                <div>
                  <span className="text-gray-500">Role:</span>
                  <span className="ml-2">{formData.role}</span>
                </div>
                <div>
                  <span className="text-gray-500">Status:</span>
                  <span className="ml-2">{formData.status}</span>
                </div>
                <div>
                  <span className="text-gray-500">Address:</span>
                  <span className="ml-2">{formData.address}</span>
                </div>
                <div>
                  <span className="text-gray-500">Address Line 2:</span>
                  <span className="ml-2">{formData.address2 || '-'}</span>
                </div>
                <div>
                  <span className="text-gray-500">Address Line 3:</span>
                  <span className="ml-2">{formData.address3 || '-'}</span>
                </div>
                <div>
                  <span className="text-gray-500">Postcode:</span>
                  <span className="ml-2">{formData.postcode}</span>
                </div>
                <div>
                  <span className="text-gray-500">Telephone:</span>
                  <span className="ml-2">{formData.telephone}</span>
                </div>
              </div>
            </div>
          </div>
          
          <div className="mt-8 flex justify-end space-x-4">
            <button
              onClick={() => setIsPreview(false)}
              className="px-4 py-2 border rounded-lg hover:bg-gray-50"
            >
              Continue Editing
            </button>
            <button
              onClick={handleSubmit}
              className="px-4 py-2 bg-yellow-400 hover:bg-yellow-500 rounded-lg"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-8 max-w-4xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-2xl font-bold">Edit User Profile</h2>
            <p className="text-sm text-gray-500">User ID: {user.id}</p>
          </div>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X className="h-6 w-6" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Full Name
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400" 
                placeholder="Enter full name"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Email Address
              </label>
              <input
                type="email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400" 
                placeholder="Enter email address"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Role
              </label>
              <select
                value={formData.role}
                onChange={(e) => setFormData({ ...formData, role: e.target.value as 'admin' | 'user' })}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
              >
                <option value="user">User</option>
                <option value="admin">Admin</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Account Status
              </label>
              <select
                value={formData.status}
                onChange={(e) => setFormData({ ...formData, status: e.target.value as 'active' | 'inactive' })}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Address
              </label>
              <input
                type="text"
                value={formData.address}
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Address Line 2
              </label>
              <input
                type="text"
                value={formData.address2 || ''}
                onChange={(e) => setFormData({ ...formData, address2: e.target.value })}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                placeholder="Apartment, suite, unit, etc."
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Address Line 3
              </label>
              <input
                type="text"
                value={formData.address3 || ''}
                onChange={(e) => setFormData({ ...formData, address3: e.target.value })}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                placeholder="Additional address information"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Postcode
              </label>
              <input
                type="text"
                value={formData.postcode}
                onChange={(e) => setFormData({ ...formData, postcode: e.target.value })}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Telephone
              </label>
              <input
                type="tel"
                value={formData.telephone}
                onChange={(e) => setFormData({ ...formData, telephone: e.target.value })}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Country
              </label>
              <input
                type="text"
                value={formData.country}
                onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                placeholder="Enter country"
              />
            </div>
          </div>

          <div className="border-t pt-6 flex justify-between items-center">
            <div className="text-sm text-gray-500">
              Last modified: {user.lastModified ? new Date(user.lastModified).toLocaleString() : 'Never'}
              {user.modifiedBy && <span className="ml-1">by {user.modifiedBy}</span>}
            </div>
            
            <div className="flex space-x-4">
              <button
                type="button"
                onClick={() => setIsPreview(true)}
                className="px-4 py-2 border rounded-lg hover:bg-gray-50"
              >
                <Eye className="h-5 w-5" />
              </button>
              <button
                type="submit"
                disabled={isSaving}
                className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
                  isSaving
                    ? 'bg-gray-300 cursor-not-allowed'
                    : 'bg-yellow-400 hover:bg-yellow-500'
                }`}
              >
                <Save className="h-5 w-5" />
                <span>{isSaving ? 'Saving...' : 'Save Changes'}</span>
              </button>
            </div>
          </div>
        </form>

        {error && (
          <div className="mt-4 p-4 bg-red-50 text-red-700 rounded-lg flex items-center space-x-2">
            <AlertCircle className="h-5 w-5" />
            <span>{error}</span>
          </div>
        )}

        {success && (
          <div className="mt-4 p-4 bg-green-50 text-green-700 rounded-lg flex items-center space-x-2">
            <AlertCircle className="h-5 w-5" />
            <span>Changes saved successfully</span>
          </div>
        )}
      </div>
    </div>
  );
}
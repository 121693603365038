import React from 'react';
import { Bug, Clock, AlertCircle, Info } from 'lucide-react';
import { useDebug } from '../../hooks/useDebug';
import { format } from 'date-fns';

export default function DebugSettings() {
  const { debugMode, setDebugMode, pageTransitions } = useDebug();
  const [showConfirm, setShowConfirm] = React.useState(false);

  const handleToggle = () => {
    if (!debugMode) {
      setShowConfirm(true);
    } else {
      setDebugMode(false);
    }
  };

  const confirmEnable = () => {
    setDebugMode(true);
    setShowConfirm(false);
  };

  return (
    <div className="max-w-2xl">
      <h2 className="text-xl font-bold mb-6 flex items-center space-x-2">
        <Bug className="h-6 w-6 text-gray-700" />
        <span>Debug Settings</span>
      </h2>

      <div className="space-y-6">
        <div className="bg-white shadow-sm border rounded-lg overflow-hidden">
          <div className="p-4 flex items-center justify-between border-b">
            <div>
              <h3 className="font-medium flex items-center space-x-2">
                <Bug className="h-4 w-4" />
                <span>Debug Mode</span>
                <span className={`text-xs px-2 py-0.5 rounded-full ${
                  debugMode ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                }`}>
                  {debugMode ? 'ON' : 'OFF'}
                </span>
              </h3>
              <p className="text-sm text-gray-600">
                Enable advanced troubleshooting features
              </p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={debugMode}
                onChange={handleToggle}
              />
              <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-yellow-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-yellow-400"></div>
            </label>
          </div>
          
          <div className="p-4 bg-blue-50 flex items-start space-x-3">
            <Info className="h-5 w-5 text-blue-500 mt-0.5 flex-shrink-0" />
            <div className="text-sm text-blue-700">
              <p className="font-medium mb-1">Debug Mode Features:</p>
              <ul className="list-disc list-inside space-y-1">
                <li>Display page identifiers and component names</li>
                <li>Track page transitions and navigation</li>
                <li>Show real-time debugging information</li>
                <li>Enable advanced troubleshooting tools</li>
              </ul>
            </div>
          </div>
        </div>
        {showConfirm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
              <h3 className="text-lg font-semibold mb-2">Enable Debug Mode?</h3>
              <p className="text-gray-600 mb-4">
                Debug mode will display additional technical information across the application. This is intended for development and troubleshooting purposes.
              </p>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setShowConfirm(false)}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmEnable}
                  className="px-4 py-2 bg-yellow-400 hover:bg-yellow-500 rounded-lg"
                >
                  Enable Debug Mode
                </button>
              </div>
            </div>
          </div>
        )}
        {debugMode && (
          <div className="border rounded-lg overflow-hidden">
            <div className="bg-gray-50 px-4 py-2 border-b">
              <div className="flex items-center space-x-2">
                <Clock className="h-4 w-4 text-gray-500" />
                <h3 className="font-medium">Recent Page Transitions</h3>
              </div>
            </div>
            <div className="divide-y">
              {pageTransitions.length > 0 ? pageTransitions.map((transition, index) => (
                <div key={index} className="px-4 py-2 text-sm">
                  <div className="flex justify-between items-center">
                    <span className="font-mono text-gray-600">
                      {transition.path} → {transition.component}
                    </span>
                    <span className="text-gray-400 text-xs">
                      {format(new Date(transition.timestamp), 'HH:mm:ss')}
                    </span>
                  </div>
                </div>
              )) : (
                <div className="px-4 py-2 text-sm text-gray-500">
                  No page transitions recorded yet
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
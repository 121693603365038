interface ValidationErrors {
  [key: string]: string;
}

interface CardValidationResult {
  isValid: boolean;
  errors: ValidationErrors;
}

export function validateCreditCard(cardData: Readonly<{
  number: string;
  expiry: string;
  cvv: string;
  name: string;
}>): CardValidationResult {
  const errors: ValidationErrors = {};

  // Card number validation (Luhn algorithm)
  const number = cardData.number.replace(/\s/g, '');
  if (!number) {
    errors.number = 'Card number is required';
  } else if (!/^\d{15,16}$/.test(number)) {
    errors.number = 'Invalid card number';
  } else if (!isValidLuhn(number)) {
    errors.number = 'Invalid card number';
  }

  // Expiry validation
  if (!cardData.expiry) {
    errors.expiry = 'Expiry date is required';
  } else {
    const [month, year] = cardData.expiry.split('/');
    const currentYear = new Date().getFullYear() % 100;
    const currentMonth = new Date().getMonth() + 1;
    const expMonth = parseInt(month);
    const expYear = parseInt(year);
    
    if (!/^\d\d\/\d\d$/.test(cardData.expiry)) {
      errors.expiry = 'Invalid format (MM/YY)';
    } else if (expMonth < 1 || expMonth > 12) {
      errors.expiry = 'Invalid month';
    } else if (expYear < currentYear || (expYear === currentYear && expMonth < currentMonth)) {
      errors.expiry = 'Card has expired';
    }
  }

  // CVV validation
  if (!cardData.cvv) {
    errors.cvv = 'CVV is required';
  } else if (!/^\d{3,4}$/.test(cardData.cvv)) {
    errors.cvv = 'Invalid CVV';
  }

  // Name validation
  if (!cardData.name.trim()) {
    errors.name = 'Cardholder name is required';
  } else if (cardData.name.length < 3) {
    errors.name = 'Name is too short';
  }

  return {
    isValid: Object.keys(errors).length === 0,
    errors
  };
}

// Luhn algorithm implementation
function isValidLuhn(number: string): boolean {
  let sum = 0;
  let isEven = false;

  // Loop through values starting from the rightmost digit
  for (let i = number.length - 1; i >= 0; i--) {
    let digit = parseInt(number.charAt(i));

    if (isEven) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    isEven = !isEven;
  }

  return sum % 10 === 0;
}

export function detectCardType(number: string): string {
  const patterns = {
    visa: /^4/,
    mastercard: /^5[1-5]/,
    amex: /^3[47]/,
    discover: /^6(?:011|5)/,
    dinersclub: /^3(?:0[0-5]|[68])/,
    jcb: /^(?:2131|1800|35)/
  };

  const cleanNumber = number.replace(/\D/g, '');
  
  for (const [card, pattern] of Object.entries(patterns)) {
    if (pattern.test(cleanNumber)) {
      return card;
    }
  }
  
  return 'unknown';
}
import React, { useState } from 'react';
import { AlertCircle, Hash, Coins } from 'lucide-react';
import { db } from '../../config/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../context/CartContext';
import { Order } from '../../types/order';
import { validateTransactionHash } from '../../utils/cryptoUtils';

interface PaymentVerificationProps {
  order: Order;
  onVerificationSubmitted: () => void;
  selectedCrypto: string;
  cryptoAmount: number;
}

export default function PaymentVerification({ 
  order, 
  onVerificationSubmitted,
  selectedCrypto,
  cryptoAmount
}: PaymentVerificationProps) {
  const navigate = useNavigate();
  const { dispatch: cartDispatch } = useCart();
  const [transactionHash, setTransactionHash] = useState('');
  const [amount, setAmount] = useState(() => cryptoAmount.toFixed(8));
  const [cryptocurrency, setCryptocurrency] = useState(() => selectedCrypto);
  const [notes, setNotes] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<Record<string, string>>({});

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setValidationErrors({});
    
    if (submitting) return;

    // Validate all fields before submission
    const errors: Record<string, string> = {};

    if (!transactionHash.trim()) {
      errors.hash = 'Transaction hash is required';
    } else if (!validateTransactionHash(transactionHash, cryptocurrency)) {
      errors.hash = 'Invalid transaction hash format';
    }

    const parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount) || parsedAmount <= 0) {
      errors.amount = 'Please enter a valid amount';
    } else if (Math.abs(parsedAmount - cryptoAmount) > 0.00001) {
      errors.amount = `Amount must match expected payment of ${cryptoAmount.toFixed(8)} ${cryptocurrency}`;
    }

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setSubmitting(true);

    try {
      // Verify order exists first
      const orderRef = doc(db, 'orders', order.id);
      const orderSnap = await getDoc(orderRef);
      
      if (!orderSnap.exists()) {
        throw new Error('Order not found - please try again');
      }
      
      const timestamp = new Date().toISOString();

      const verificationDetails = {
        transactionHash: transactionHash.trim(),
        amount: parsedAmount,
        cryptocurrency,
        notes: notes.trim(),
        submittedAt: timestamp
      };

      // Update order with verification details
      await updateDoc(orderRef, {
          status: 'payment_pending_verification',
          payment: {
            ...order.payment,
            status: 'pending_verification',
            method: 'crypto',
            lastUpdated: timestamp,
            verificationDetails,
            cryptoCurrency: cryptocurrency,
            cryptoAmount: parsedAmount,
            exchangeRate: Number((order.total / parsedAmount).toFixed(8)),
            transactionId: transactionHash.trim()
          },
          history: [
            ...order.history,
            {
              action: 'Payment verification submitted',
              timestamp,
              performedBy: order.customerEmail,
              details: `Crypto payment verification submitted: ${parsedAmount} ${cryptocurrency}`
            }
          ]
        })

      // Clear cart and redirect to success page
      cartDispatch({ type: 'CLEAR_CART' });
      onVerificationSubmitted();
      navigate('/payment-success');
    } catch (err) {
      console.error('Verification error:', err);
      const errorMessage = err instanceof Error ? err.message : 'Failed to verify payment - please try again';
      setError(errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <h3 className="text-lg font-semibold mb-4">Payment Verification</h3>
        
        <div className="space-y-4">
          {/* Transaction Hash */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Transaction Hash/ID *
              {validationErrors.hash && (
                <span className="text-red-500 text-xs ml-2">{validationErrors.hash}</span>
              )}
              <span className="text-xs text-gray-500 ml-2">
                (Expected: {cryptoAmount.toFixed(8)} {selectedCrypto})
              </span>
            </label>
            <div className="relative">
              <Hash className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <input
                type="text"
                value={transactionHash}
                onChange={(e) => setTransactionHash(e.target.value)}
                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 ring-yellow-400"
                placeholder="Enter transaction hash"
                required
              />
            </div>
          </div>

          {/* Amount and Cryptocurrency */}
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Amount Sent *
                {validationErrors.amount && (
                  <span className="text-red-500 text-xs ml-2">{validationErrors.amount}</span>
                )}
              </label>
              <div className="relative">
                <Coins className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                <input
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  step="any"
                  min="0"
                  className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 ring-yellow-400"
                  placeholder="0.00"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Cryptocurrency *
              </label>
              <select
                value={cryptocurrency}
                onChange={(e) => setCryptocurrency(e.target.value)}
                className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                required
              >
                <option value="BTC">Bitcoin (BTC)</option>
                <option value="ETH">Ethereum (ETH)</option>
                <option value="XRP">Ripple (XRP)</option>
              </select>
            </div>
          </div>

          {/* Notes */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Additional Notes
            </label>
            <textarea
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              rows={3}
              className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
              placeholder="Any additional information about your payment..."
            />
          </div>
        </div>

        {error && (
          <div className="mt-4 p-3 bg-red-50 text-red-700 rounded-lg flex items-center space-x-2">
            <AlertCircle className="h-5 w-5" />
            <span>{error}</span>
          </div>
        )}

        <button
          type="submit"
          disabled={submitting}
          className={`mt-6 w-full py-2 px-4 rounded-lg text-black font-semibold ${
            submitting
              ? 'bg-gray-300 cursor-not-allowed'
              : 'bg-yellow-400 hover:bg-yellow-500'
          }`}
        >
          {submitting ? 'Submitting...' : 'Submit Payment Verification'}
        </button>
      </div>
    </form>
  );
}
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ProductCard from '../components/ProductCard';
import Cart from '../components/Cart';
import { useSearch } from '../context/SearchContext';
import { useAuth } from '../context/AuthContext';
import { usePageTitle } from '../hooks/usePageTitle';
import { hasAdminRole } from '../utils/auth/permissions';
import { useSite } from '../context/SiteContext';
import CategoryNav from '../components/CategoryNav';

export default function HomePage() {
  usePageTitle();
  const navigate = useNavigate();
  const { searchResults, searchTerm, loading } = useSearch();
  const { userProfile } = useAuth();
  const { settings, siteName, loading: siteLoading } = useSite();
  const cartEnabled = settings?.cartSystem?.enabled ?? false;

  if (userProfile && hasAdminRole(userProfile)) {
    return (
      <div className="text-center py-12">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">Admin Dashboard Access</h2>
        <p className="text-gray-600 mb-6">Welcome to the admin portal.</p>
        <button
          onClick={() => navigate('/admin')}
          className="bg-yellow-400 hover:bg-yellow-500 text-black font-semibold py-2 px-6 rounded-lg"
        >
          Go to Admin Dashboard
        </button>
      </div>
    );
  }

  const renderNoResults = () => (
    <div className="col-span-full text-center py-12">
      <h2 className="text-2xl font-bold text-gray-900 mb-2">No products found</h2>
      <p className="text-gray-600">
        We couldn't find any products matching "{searchTerm}"
      </p>
    </div>
  );

  return (
    <div className="flex flex-col md:flex-row gap-6">
      <Helmet>
        <title>{siteLoading ? 'Loading...' : `${siteName} - Online Store`}</title>
        <meta name="description" content="Shop our wide selection of products with secure checkout and fast shipping." />
        <meta property="og:title" content={siteLoading ? 'Loading...' : `${siteName} - Online Store`} />
        <meta property="og:description" content="Shop our wide selection of products with secure checkout and fast shipping." />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="w-full md:w-64">
        <div className="sticky top-24">
          <CategoryNav />
        </div>
      </div>
      
      <div className="flex-1">
      {loading ? (
        <div className="flex justify-center items-center min-h-[200px]">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {searchResults.length === 0 && searchTerm
          ? renderNoResults()
          : searchResults.map((product) => (
              <div
                key={product.id}
                onClick={() => navigate(`/product/${product.id}`)}
                className="cursor-pointer"
              >
                <ProductCard product={product} />
              </div>
            ))}
      </div>
      )}
      {cartEnabled && (
        <div className="mt-8">
          <Cart />
        </div>
      )}
      </div>
    </div>
  );
}
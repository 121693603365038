import React from 'react';
import { CreditCard, Bitcoin } from 'lucide-react';
import { Order } from '../../../types/order';
import { useSite } from '../../../context/SiteContext';
import { formatPrice } from '../../../utils/currency';

interface PaymentDetailsProps {
  order: Order;
}

export default function PaymentDetails({ order }: PaymentDetailsProps) {
  const { currency, settings } = useSite();
  const taxRate = settings?.tax?.rate || 20;

  const getPaymentStatusColor = (status: string) => {
    const colors = {
      pending: 'bg-yellow-100 text-yellow-800',
      completed: 'bg-green-100 text-green-800',
      failed: 'bg-red-100 text-red-800',
      refunded: 'bg-gray-100 text-gray-800'
    };
    return colors[status as keyof typeof colors] || 'bg-gray-100 text-gray-800';
  };

  const renderCreditCardDetails = () => (
    <div className="space-y-4">
      <div className="flex items-center space-x-3">
        <CreditCard className="h-5 w-5 text-gray-400" />
        <span className="font-medium">Credit Card Payment</span>
      </div>
      
      <div className="grid grid-cols-2 gap-4 text-sm">
        <div>
          <span className="text-gray-500">Card Number:</span>
          <span className="ml-2">**** **** **** {order.payment.cardLast4 || '****'}</span>
        </div>
        <div>
          <span className="text-gray-500">Card Type:</span>
          <span className="ml-2">{order.payment.cardType || 'Unknown'}</span>
        </div>
        <div>
          <span className="text-gray-500">Cardholder:</span>
          <span className="ml-2">{order.payment.cardholderName || 'Not available'}</span>
        </div>
        <div>
          <span className="text-gray-500">Authorization:</span>
          <span className="ml-2">{order.payment.authorizationCode || 'N/A'}</span>
        </div>
      </div>
    </div>
  );

  const renderCryptoDetails = () => (
    <div className="space-y-4">
      <div className="flex items-center space-x-3">
        <Bitcoin className="h-5 w-5 text-gray-400" />
        <span className="font-medium">Cryptocurrency Payment</span>
      </div>
      
      <div className="grid grid-cols-2 gap-4 text-sm">
        <div>
          <span className="text-gray-500">Currency:</span>
          <span className="ml-2">{order.payment.cryptoCurrency || 'Unknown'}</span>
        </div>
        <div>
          <span className="text-gray-500">Amount Paid:</span>
          <span className="ml-2">{order.payment.cryptoAmount || '0'} {order.payment.cryptoCurrency}</span>
        </div>
        <div>
          <span className="text-gray-500">Exchange Rate:</span>
          <span className="ml-2">1 {order.payment.cryptoCurrency} = {formatPrice(order.payment.exchangeRate || 0, currency)}</span>
        </div>
        <div>
          <span className="text-gray-500">Transaction ID:</span>
          <span className="ml-2 break-all">{order.payment.transactionId || 'N/A'}</span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h3 className="text-lg font-semibold mb-4">Payment Details</h3>
      
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <div className="text-sm">
            <span className="text-gray-500">Payment Method:</span>
            <span className="ml-2 font-medium">{order.payment.method}</span>
          </div>
          <span className={`px-2 py-1 text-xs font-medium rounded-full ${getPaymentStatusColor(order.payment.status)}`}>
            {order.payment.status.toUpperCase()}
          </span>
        </div>

        <div className="border-t pt-4">
          {order.payment.method === 'credit-card' ? renderCreditCardDetails() : renderCryptoDetails()}
        </div>

        <div className="border-t pt-4 text-sm">
          <div className="flex justify-between items-center">
            <span className="text-gray-500">Amount Paid:</span>
            <span className="font-bold">{formatPrice(order.payment.amount, currency)}</span>
          </div>
          <div className="text-xs text-gray-500 mt-2">
            Processed on {new Date(order.payment.lastUpdated).toLocaleString()}
          </div>
        </div>
      </div>
    </div>
  );
}
import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase';
import { UserProfile } from '../types/auth';
import AdminLayout from '../components/admin/AdminLayout';
import AdminUserList from '../components/admin/AdminUserList';
import UserExport from '../components/admin/UserExport';
import UserListViewer from '../components/admin/UserListViewer';

export default function AdminUsersPage() {
  const [users, setUsers] = useState<UserProfile[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    setLoading(true);

    try {
      // Set up real-time listener for users
      const usersQuery = query(
        collection(db, 'users'),
        orderBy('name')
      );

      const unsubscribe = onSnapshot(
        usersQuery,
        (snapshot) => {
          const userData: UserProfile[] = [];
          snapshot.forEach((doc) => {
            userData.push({ id: doc.id, ...doc.data() } as UserProfile);
          });
          setUsers(userData);
          setLoading(false);
          setError(null);
        },
        (err) => {
          console.error('Error fetching users:', err);
          setError('Error loading user data. Please try again later.');
          setLoading(false);
        }
      );

      // Cleanup subscription
      return () => unsubscribe();
    } catch (err) {
      console.error('Error setting up users listener:', err);
      setError('Error loading user data. Please try again later.');
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex justify-center items-center min-h-[400px]">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      </AdminLayout>
    );
  }

  if (error) {
    return (
      <AdminLayout>
        <div className="p-6">
          <div className="bg-red-50 text-red-800 p-4 rounded-lg">
            {error}
          </div>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="p-6">
        <UserExport />
        <div className="h-6" />
        <UserListViewer />
        <div className="h-6" />
        <AdminUserList users={users} />
      </div>
    </AdminLayout>
  );
}
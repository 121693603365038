import { SiteSettings } from '../types/settings';

export interface TaxBreakdown {
  baseAmount: number;
  taxAmount: number;
  totalAmount: number;
}

export function calculateTaxBreakdown(amount: number, taxRate: number): TaxBreakdown {
  const baseAmount = amount;
  const taxAmount = Math.round((baseAmount * (taxRate / 100)) * 100) / 100;
  const totalAmount = Math.round((baseAmount + taxAmount) * 100) / 100;

  return {
    baseAmount,
    taxAmount,
    totalAmount
  };
}

export function getTaxRate(settings?: SiteSettings | null): number {
  return settings?.tax?.rate ?? 20;
}
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase';
import { AlertCircle, RefreshCw } from 'lucide-react';
import { Order } from '../types/order';
import AdminLayout from '../components/admin/AdminLayout';
import OrderArchive from '../components/admin/orders/OrderArchive';
import OrderList from '../components/admin/orders/OrderList';
import OrderAnalytics from '../components/admin/orders/OrderAnalytics';
import { Search, Filter } from 'lucide-react'; 

export default function AdminOrdersPage() {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [activeOrders, setActiveOrders] = useState<Order[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);

    try {
      // Set up real-time listener for orders
      const ordersQuery = query(
        collection(db, 'orders'),
        orderBy('created_at', 'desc')
      );

      const unsubscribe = onSnapshot(
        ordersQuery,
        (snapshot) => {
          const orderData: Order[] = [];
          snapshot.forEach((doc) => {
            orderData.push({ id: doc.id, ...doc.data() } as Order);
          });
          setOrders(orderData);
          setLoading(false);
          setError(null);
        },
        (err) => {
          console.error('Error fetching orders:', err);
          setError(err instanceof Error ? err : new Error('Failed to load orders'));
          setLoading(false);
        }
      );

      // Cleanup subscription
      return () => unsubscribe();
    } catch (err) {
      console.error('Error setting up orders listener:', err);
      setError(err instanceof Error ? err : new Error('Failed to load orders'));
      setLoading(false);
    }
  }, []);

  // Update active orders when orders change
  useEffect(() => {
    const active = orders.filter(order => 
      !['cancelled', 'refunded', 'delivered', 'returned'].includes(order.status)
    );
    setActiveOrders(active);
  }, [orders]);

  const filteredOrders = orders.filter(order => {
    const matchesSearch = 
      order.orderNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.customerEmail.toLowerCase().includes(searchTerm.toLowerCase()) ||
      order.customerName.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesStatus = statusFilter === 'all' || order.status === statusFilter;
    
    return matchesSearch && matchesStatus;
  });

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex justify-center items-center min-h-[400px]">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      </AdminLayout>
    );
  }

  if (error) {
    return (
      <AdminLayout>
        <div className="p-6 space-y-4">
          <div className="bg-red-50 text-red-800 p-4 rounded-lg flex items-center space-x-2">
            <AlertCircle className="h-5 w-5 flex-shrink-0" />
            {error.message}
          </div>
          <button
            onClick={() => {
              window.location.reload();
            }}
            className="px-4 py-2 bg-yellow-400 hover:bg-yellow-500 rounded-lg flex items-center space-x-2"
          >
            <RefreshCw className="h-5 w-5" />
            <span>Retry</span>
          </button>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="p-6 space-y-6">
        <OrderAnalytics orders={orders || []} />
        
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
            <h2 className="text-xl font-bold">Orders</h2>
            
            <div className="flex flex-col sm:flex-row gap-4 w-full sm:w-auto">
              <div className="relative flex-grow sm:flex-grow-0">
                <input
                  type="text"
                  placeholder="Search orders..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border rounded-lg"
                />
                <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
              
              <div className="relative flex-grow sm:flex-grow-0">
                <select
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border rounded-lg appearance-none bg-white"
                >
                  <option value="all">All Status</option>
                  <option value="pending">Pending</option>
                  <option value="processing">Processing</option>
                  <option value="shipped">Shipped</option>
                  <option value="delivered">Delivered</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="refunded">Refunded</option>
                </select>
                <Filter className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>

          <OrderList 
            orders={activeOrders.filter(order =>
              order.orderNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
              order.customerEmail?.toLowerCase().includes(searchTerm.toLowerCase()) ||
              order.customerName?.toLowerCase().includes(searchTerm.toLowerCase())
            )}
            onOrderClick={(orderId) => navigate(`/admin/orders/${orderId}`)}
          />

          <OrderArchive
            orders={orders || []}
            onOrderClick={(orderId) => navigate(`/admin/orders/${orderId}`)}
            searchTerm={searchTerm}
          />
        </div>
      </div>
    </AdminLayout>
  );
}
import React, { useState, useEffect } from 'react';
import { ChevronRight, Tag, ShoppingBag } from 'lucide-react';
import { getUniqueCategories } from '../utils/categoryUtils';
import { useSearch } from '../context/SearchContext';

export default function CategoryNav() {
  const [categories, setCategories] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const { selectedCategory, setSelectedCategory } = useSearch();

  useEffect(() => {
    async function fetchCategories() {
      const uniqueCategories = await getUniqueCategories();
      setCategories(uniqueCategories);
      setLoading(false);
    }
    fetchCategories();
  }, []);

  const handleCategoryClick = (categoryName: string) => {
    setSelectedCategory(selectedCategory === categoryName ? null : categoryName);
  };

  if (loading) {
    return (
      <div className="animate-pulse">
        {[...Array(5)].map((_, i) => (
          <div key={i} className="h-10 bg-gray-200 rounded mb-2"></div>
        ))}
      </div>
    );
  }

  return (
    <nav className="bg-white rounded-lg shadow-md p-4">
      <div className="space-y-1">
      <div className="flex items-center space-x-2 mb-4 text-lg font-semibold text-gray-800">
        <ShoppingBag className="h-5 w-5" />
        <span>Categories</span>
      </div>

        <button
          onClick={() => setSelectedCategory(null)}
          className={`w-full flex items-center justify-between p-2 rounded-lg hover:bg-gray-50 transition-colors text-left ${
            !selectedCategory ? 'bg-yellow-50 text-yellow-700' : ''
          }`}
        >
          <div className="flex items-center space-x-2">
            <Tag className="h-4 w-4" />
            <span>All Categories</span>
            <span className="ml-2 text-sm text-gray-500">
              ({categories.length})
            </span>
          </div>
        </button>

        {categories.map((category) => (
          <div key={category}>
            <button
              onClick={() => handleCategoryClick(category)}
              className={`w-full flex items-center justify-between p-2 rounded-lg hover:bg-gray-50 transition-colors text-left ${
                selectedCategory === category ? 'bg-yellow-50 text-yellow-700' : ''
              }`}
            >
              <div className="flex items-center space-x-2">
                <span className="text-gray-700">{category}</span>
              </div>
              <div className="text-sm text-gray-500">
                <ChevronRight className="h-4 w-4" />
              </div>
            </button>
          </div>
        ))}
      </div>
    </nav>
  );
}
import React, { useState, useEffect } from 'react';
import { GripVertical, Plus, X, RefreshCw, AlertCircle } from 'lucide-react';
import { db } from '../../config/firebase';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, orderBy, deleteDoc } from 'firebase/firestore';
import { syncCategories } from '../../utils/categorySync';

interface Category {
  id: string;
  name: string;
  description: string;
  order: number;
  status: 'active' | 'inactive';
}

export default function CategoryManager() {
  const [categories, setCategories] = useState<Category[]>([]);
  const [newCategory, setNewCategory] = useState<Partial<Category>>({
    name: '',
    description: '',
    status: 'active',
    order: 0
  });
  const [error, setError] = useState<string | null>(null);
  const [syncing, setSyncing] = useState(false);
  const [syncResult, setSyncResult] = useState<string | null>(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesRef = collection(db, 'categories');
        const q = query(categoriesRef, orderBy('order'));
        const snapshot = await getDocs(q);
        const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        setCategories(data || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError('Failed to load categories');
      }
    };

    fetchCategories();

    // Note: Real-time subscription would need to be implemented differently with Firestore
  }, []);

  const handleSync = async () => {
    setSyncing(true);
    setSyncResult(null);
    setError(null);
    
    try {
      const result = await syncCategories();
      setSyncResult(result.message);
    } catch (err) {
      setError('Failed to sync categories');
    } finally {
      setSyncing(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    if (!newCategory.name?.trim()) {
      setError('Category name is required');
      return;
    }

    try {
      const categoriesRef = collection(db, 'categories');
      await addDoc(categoriesRef, {
        name: newCategory.name.trim(),
        description: newCategory.description || '',
        order: categories.length,
        status: 'active',
        created_at: new Date().toISOString()
      });

      setNewCategory({
        name: '',
        description: '',
        status: 'active',
        order: 0
      });
    } catch (error) {
      console.error('Error creating category:', error);
      setError('Failed to create category');
    }
  };

  const handleStatusToggle = async (category: Category) => {
    try {
      const categoryRef = doc(db, 'categories', category.id);
      await updateDoc(categoryRef, {
        status: category.status === 'active' ? 'inactive' : 'active',
        updated_at: new Date().toISOString()
      });
    } catch (error) {
      console.error('Error updating category:', error);
      setError('Failed to update category status');
    }
  };

  const handleDelete = async (categoryId: string) => {
    if (!window.confirm('Are you sure you want to delete this category?')) {
      return;
    }

    try {
      const categoryRef = doc(db, 'categories', categoryId);
      await deleteDoc(categoryRef);
    } catch (error) {
      console.error('Error deleting category:', error);
      setError('Failed to delete category');
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-xl font-bold mb-6">Category Management</h2>
      
      <div className="mb-6">
        <button
          onClick={handleSync}
          disabled={syncing}
          className={`flex items-center space-x-2 px-4 py-2 rounded-lg ${
            syncing
              ? 'bg-gray-100 cursor-not-allowed'
              : 'bg-blue-500 hover:bg-blue-600 text-white'
          }`}
        >
          <RefreshCw className={`h-5 w-5 ${syncing ? 'animate-spin' : ''}`} />
          <span>{syncing ? 'Syncing...' : 'Sync Categories'}</span>
        </button>
        
        {syncResult && (
          <div className="mt-2 p-2 bg-green-50 text-green-700 rounded-lg flex items-center space-x-2">
            <AlertCircle className="h-5 w-5" />
            <span>{syncResult}</span>
          </div>
        )}
      </div>

      <form onSubmit={handleSubmit} className="mb-8 space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Category Name
          </label>
          <input
            type="text"
            value={newCategory.name}
            onChange={(e) => setNewCategory({ ...newCategory, name: e.target.value })}
            className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Description
          </label>
          <textarea
            value={newCategory.description}
            onChange={(e) => setNewCategory({ ...newCategory, description: e.target.value })}
            className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
            rows={3}
          />
        </div>

        <button
          type="submit"
          className="px-4 py-2 bg-yellow-400 hover:bg-yellow-500 rounded-lg flex items-center space-x-2"
        >
          <Plus className="h-5 w-5" />
          <span>Add Category</span>
        </button>
      </form>

      {error && (
        <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
          {error}
        </div>
      )}

      <div className="space-y-4">
        {categories.map((category) => (
          <div
            key={category.id}
            className="flex items-center justify-between p-4 border rounded-lg"
          >
            <div className="flex items-center space-x-4">
              <GripVertical className="h-5 w-5 text-gray-400 cursor-move" />
              <div>
                <h3 className="font-medium">{category.name}</h3>
                <p className="text-sm text-gray-500">{category.description}</p>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <button
                onClick={() => handleStatusToggle(category)}
                className={`px-3 py-1 rounded-full text-sm ${
                  category.status === 'active'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-red-100 text-red-800'
                }`}
              >
                {category.status}
              </button>
              <button
                onClick={() => handleDelete(category.id)}
                className="text-red-500 hover:text-red-700"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
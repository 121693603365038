import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trash2, Truck } from 'lucide-react';
import { useCart } from '../context/CartContext';
import { useSite } from '../context/SiteContext';
import { getProductImage } from '../utils/product';
import { formatPrice, shouldApplyFreeShipping } from '../utils/currency';
import { calculateTaxBreakdown, getTaxRate } from '../utils/tax';
import ShippingSummary from './ShippingSummary';

export default function Cart() {
  const { state, dispatch } = useCart();
  const { currency, settings } = useSite();
  const taxRate = getTaxRate(settings);
  const navigate = useNavigate();
  const freeShippingThreshold = settings?.shipping?.freeThreshold ?? 0;
  
  const subtotal = useMemo(() => {
    return state.items.reduce((total, item) => 
      total + (item.price * item.quantity), 0);
  }, [state.items]);
  
  const qualifiesForFreeShipping = shouldApplyFreeShipping(subtotal, freeShippingThreshold);
  
  const shippingTotal = qualifiesForFreeShipping ? 0 : state.items.reduce((total, item) => 
    total + ((item.shipping_cost || 0) * item.quantity), 0);

  const priceBreakdown = useMemo(() => {
    return state.items.map(item => ({
      ...item,
      breakdown: calculateTaxBreakdown(item.price * item.quantity, taxRate)
    }));
  }, [state.items, taxRate]);

  const totals = useMemo(() => {
    const baseAmount = subtotal;
    const taxAmount = Math.round((baseAmount * (taxRate / 100)) * 100) / 100;
    const totalWithTax = baseAmount + taxAmount;
    
    return {
      baseAmount,
      taxAmount,
      totalAmount: totalWithTax,
      finalTotal: totalWithTax + (qualifiesForFreeShipping ? 0 : shippingTotal)
    };
  }, [subtotal, taxRate, shippingTotal, qualifiesForFreeShipping]);

  const updateQuantity = (id: string, quantity: number) => {
    if (quantity < 1) return;
    dispatch({ type: 'UPDATE_QUANTITY', payload: { id, quantity } });
  };

  const removeItem = (id: string) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: id });
  };

  if (state.items.length === 0) {
    return (
      <div className="p-4 text-center">
        <h2 className="text-xl font-semibold mb-2">Your Cart is Empty</h2>
        <p className="text-gray-600">Add some products to your cart!</p>
      </div>
    );
  }

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4">Shopping Cart</h2>
      <div className="space-y-4">
        {state.items.map((item) => (
          <div
            key={item.id}
            className="flex items-center space-x-4 bg-white p-4 rounded-lg shadow"
          >
            <img
              src={getProductImage(item)}
              alt={item.title}
              className="w-24 h-24 object-contain"
            />
            <div className="flex-grow">
              <h3 className="font-semibold">{item.title}</h3>
              <p className="text-gray-600">
                {formatPrice(item.price, currency)}
                <span className="text-xs text-gray-500 ml-1">per item</span>
              </p>
              <p className="text-xs text-gray-500">
                Base: {formatPrice(item.price, currency)} + VAT: {formatPrice(item.price * taxRate / 100, currency)}
              </p>
              <div className="flex items-center space-x-2 mt-2">
                <button
                  onClick={() => updateQuantity(item.id, item.quantity - 1)}
                  className="px-2 py-1 bg-gray-100 rounded"
                >
                  -
                </button>
                <span>{item.quantity}</span>
                <button
                  onClick={() => updateQuantity(item.id, item.quantity + 1)}
                  className="px-2 py-1 bg-gray-100 rounded"
                >
                  +
                </button>
                <button
                  onClick={() => removeItem(item.id)}
                  className="ml-4 text-red-500"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            </div>
            <div className="text-lg font-bold">
              {formatPrice(item.price * item.quantity * (1 + taxRate/100), currency)}
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 text-right">
        {!qualifiesForFreeShipping && freeShippingThreshold > 0 && (
          <div className="mb-4 p-3 bg-yellow-50 rounded-lg text-sm">
            {(() => {
              const remainingForFreeShipping = freeShippingThreshold - subtotal;
              return remainingForFreeShipping > 0 ? (
              <p className="text-yellow-700">
                <span className="flex items-center gap-2">
                  <Truck className="h-4 w-4" />
                  Add {formatPrice(remainingForFreeShipping, currency)} more to qualify for free shipping
                </span>
              </p>
              ) : null;
            })()}
          </div>
        )}
        {qualifiesForFreeShipping && (
          <div className="mb-4 p-3 bg-green-50 rounded-lg text-sm">
            <p className="text-green-700 flex items-center gap-2">
              <Truck className="h-4 w-4" />
              Free shipping applied - Order over {formatPrice(freeShippingThreshold, currency)}
            </p>
          </div>
        )}
        <div className="space-y-2 border-t pt-4">
          <div className="flex justify-between text-sm text-gray-600">
            <span>Subtotal (excl. VAT):</span>
            <span>{formatPrice(subtotal, currency)}</span>
          </div>
          <div className="flex justify-between text-sm text-gray-600">
            <span>VAT ({taxRate}%):</span>
            <span>{formatPrice(subtotal * taxRate / 100, currency)}</span>
          </div>
          {qualifiesForFreeShipping && (
            <div className="text-sm text-green-600 text-right">
              Free shipping applied
            </div>
          )}
          <ShippingSummary items={state.items} />
          <div className="border-t pt-2 flex justify-between font-bold text-lg">
            <span>Total (inc. VAT):</span>
            <span>{formatPrice(subtotal * (1 + taxRate/100) + (qualifiesForFreeShipping ? 0 : shippingTotal), currency)}</span>
          </div>
        </div>
        <button 
          onClick={() => navigate('/checkout')}
          className="mt-4 bg-yellow-400 hover:bg-yellow-500 text-black font-semibold py-2 px-6 rounded-lg"
        >
          Proceed to Checkout
        </button>
        <p className="mt-2 text-xs text-gray-500">
          All prices include VAT at {taxRate}%
        </p>
      </div>
    </div>
  );
}
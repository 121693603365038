import { Product } from '../types/product';

import { formatPrice } from './currency';

const DEFAULT_IMAGE = 'https://images.unsplash.com/photo-1580169980114-ccd0babfa840?w=500';

function convertDropboxUrl(url: string): string {
  if (url?.includes('dropbox.com')) {
    return url
      .replace('www.dropbox.com', 'dl.dropboxusercontent.com')
      .split('?')[0] + '?raw=1';
  }
  return url;
}

export function getProductMainImage(product: Product): string {
  // Check all image slots for featured image
  for (let i = 1; i <= 9; i++) {
    const slot = product[`imageSlot${i}` as keyof Product];
    if (slot && typeof slot === 'object' && 'url' in slot && slot.url?.trim() && slot.isFeatured) {
      return convertDropboxUrl(slot.url);
    }
  }
  
  // If no featured image found, return first non-empty slot
  for (let i = 1; i <= 9; i++) {
    const slot = product[`imageSlot${i}` as keyof Product];
    if (slot && typeof slot === 'object' && 'url' in slot && slot.url?.trim()) {
      return convertDropboxUrl(slot.url);
    }
  }
  
  return DEFAULT_IMAGE;
}

export function getProductImage(product: Product, defaultImage: string = DEFAULT_IMAGE): string {
  // First try to get the main/featured image
  const mainImage = getProductMainImage(product);
  if (mainImage && mainImage !== DEFAULT_IMAGE) {
    return mainImage;
  }

  // If no main image, try to get any valid image from slots
  for (let i = 1; i <= 9; i++) {
    const slot = product[`imageSlot${i}` as keyof Product];
    if (slot && typeof slot === 'object' && 'url' in slot && slot.url?.trim()) {
      return convertDropboxUrl(slot.url);
    }
  }

  return defaultImage;
}
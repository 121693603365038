import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../config/firebase';
import { doc, getDoc, onSnapshot } from 'firebase/firestore';
import { Order } from '../types/order';
import AdminLayout from '../components/admin/AdminLayout';
import OrderDetails from '../components/admin/orders/OrderDetails';
import OrderActions from '../components/admin/orders/OrderActions';
import OrderTimeline from '../components/admin/orders/OrderTimeline';
import OrderNotes from '../components/admin/orders/OrderNotes';
import { Printer, RefreshCw } from 'lucide-react';

export default function AdminOrderDetailPage() {
  const { id } = useParams();
  const [order, setOrder] = useState<Order | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!id) return;

    // Initial fetch
    const fetchOrder = async () => {
      try {
        const orderRef = doc(db, 'orders', id);
        const orderSnap = await getDoc(orderRef);

        if (!orderSnap.exists()) {
          setError('Order not found');
          return;
        }

      } catch (err) {
        console.error('Error fetching order:', err);
        setError('Error loading order details');
      }
      setLoading(false);
    };

    fetchOrder();

    // Set up real-time subscription
    const unsubscribe = onSnapshot(
      doc(db, 'orders', id),
      (doc) => {
        if (!doc.exists()) {
          setError('Order not found');
          setOrder(null);
        } else {
          setOrder({ id: doc.id, ...doc.data() } as Order);
        }
      });

    return () => {
      unsubscribe();
    };
  }, [id]);

  if (loading) {
    return (
      <AdminLayout>
        <div className="flex justify-center items-center min-h-[400px]">
          <RefreshCw className="h-12 w-12 animate-spin text-gray-400" />
        </div>
      </AdminLayout>
    );
  }

  if (error || !order) {
    return (
      <AdminLayout>
        <div className="p-6">
          <div className="bg-red-50 text-red-800 p-4 rounded-lg">
            {error || 'Order not found'}
          </div>
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className="p-6 space-y-6">
        {/* Header */}
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">
              Order #{order.orderNumber}
            </h1>
            <p className="text-sm text-gray-500">
              Placed on {new Date(order.createdAt).toLocaleString()}
            </p>
          </div>
          <button
            onClick={() => window.print()}
            className="flex items-center space-x-2 px-4 py-2 bg-white border rounded-lg hover:bg-gray-50"
          >
            <Printer className="h-5 w-5" />
            <span>Print Order</span>
          </button>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Main Content */}
          <div className="lg:col-span-2 space-y-6">
            <OrderDetails order={order} />
            <OrderTimeline order={order} />
            <OrderNotes order={order} />
          </div>

          {/* Sidebar */}
          <div className="space-y-6">
            <OrderActions order={order} />
          </div>
        </div>
      </div>
    </AdminLayout>
  );
}
import React, { useState } from 'react';
import { Download, Upload, AlertCircle } from 'lucide-react';
import { importProducts, exportProducts } from '../../utils/productManagement';
import { useAuth } from '../../context/AuthContext';

export default function ProductImportExport() {
  const { userProfile } = useAuth();
  const [importing, setImporting] = useState(false);
  const [importResult, setImportResult] = useState<{
    success: boolean;
    message: string;
    errors?: string[];
  } | null>(null);

  const handleImport = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setImporting(true);
    setImportResult(null);

    try {
      const result = await importProducts(file);
      setImportResult(result);
    } catch (error) {
      setImportResult({
        success: false,
        message: `Import failed: ${error.message}`
      });
    } finally {
      setImporting(false);
      event.target.value = '';
    }
  };

  const handleExport = async () => {
    setImportResult(null);
    try {
      await exportProducts(userProfile);
      setImportResult({
        success: true,
        message: 'Products exported successfully'
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Export failed';
      console.error('Export failed:', errorMessage);
      setImportResult({
        success: false,
        message: errorMessage
      });
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6">
      <h2 className="text-xl font-bold mb-4">Import/Export Products</h2>
      
      <div className="flex flex-wrap gap-4">
        <div className="relative">
          <input
            type="file"
            onChange={handleImport}
            accept=".xlsx,.xls"
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            disabled={importing}
          />
          <button
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
              importing
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-yellow-400 hover:bg-yellow-500'
            }`}
          >
            <Upload className="h-5 w-5" />
            <span>{importing ? 'Importing...' : 'Import Products'}</span>
          </button>
        </div>

        <button
          onClick={handleExport}
          className="px-4 py-2 bg-yellow-400 hover:bg-yellow-500 rounded-lg flex items-center space-x-2"
        >
          <Download className="h-5 w-5" />
          <span>Export Products</span>
        </button>
      </div>

      {importResult && (
        <div className={`mt-4 p-4 rounded-lg ${
          importResult.success ? 'bg-green-100' : 'bg-red-100'
        }`}>
          <div className="flex items-start space-x-2">
            <AlertCircle className={`h-5 w-5 mt-0.5 ${
              importResult.success ? 'text-green-600' : 'text-red-600'
            }`} />
            <div>
              <p className={`font-medium ${
                importResult.success ? 'text-green-800' : 'text-red-800'
              }`}>
                {importResult.message}
              </p>
              {importResult.errors && importResult.errors.length > 0 && (
                <div className="mt-2 text-sm text-red-600">
                  <p className="font-medium">Errors:</p>
                  <ul className="list-disc list-inside">
                    {importResult.errors.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
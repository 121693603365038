import React, { useState } from 'react';
import { MessageSquare, Send } from 'lucide-react';
import { db } from '../../../config/firebase';
import { doc, updateDoc } from 'firebase/firestore';
import { Order, OrderNote } from '../../../types/order';
import { useAuth } from '../../../context/AuthContext';
import { format } from 'date-fns';

interface OrderNotesProps {
  order: Order;
}

export default function OrderNotes({ order }: OrderNotesProps) {
  const { userProfile } = useAuth();
  const [newNote, setNewNote] = useState('');
  const [isInternal, setIsInternal] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const addNote = async () => {
    if (!userProfile || !newNote.trim()) return;

    setSubmitting(true);
    try {
      const note: OrderNote = {
        id: crypto.randomUUID(),
        content: newNote.trim(),
        createdAt: new Date().toISOString(),
        createdBy: userProfile.email,
        isInternal
      };

      const orderRef = doc(db, 'orders', order.id);
      await updateDoc(orderRef, {
        notes: [...(order.notes || []), note],
        updatedAt: new Date().toISOString()
      });

      setNewNote('');
    } catch (error) {
      console.error('Error adding note:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h3 className="text-lg font-semibold mb-4">Order Notes</h3>

      <div className="space-y-6">
        {/* Notes List */}
        <div className="space-y-4">
          {order.notes?.map((note) => (
            <div
              key={note.id}
              className={`p-4 rounded-lg ${
                note.isInternal
                  ? 'bg-gray-50 border border-gray-200'
                  : 'bg-blue-50 border border-blue-200'
              }`}
            >
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm font-medium">
                  {note.createdBy}
                </span>
                <span className="text-xs text-gray-500">
                  {format(new Date(note.createdAt), 'PPp')}
                </span>
              </div>
              <p className="text-sm text-gray-700">{note.content}</p>
              {note.isInternal && (
                <span className="mt-2 inline-block px-2 py-1 text-xs font-medium bg-gray-200 text-gray-800 rounded">
                  Internal Note
                </span>
              )}
            </div>
          ))}
        </div>

        {/* Add Note Form */}
        <div className="space-y-4">
          <div>
            <label className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={isInternal}
                onChange={(e) => setIsInternal(e.target.checked)}
                className="rounded text-yellow-400 focus:ring-yellow-400"
              />
              <span className="text-sm text-gray-600">Internal note</span>
            </label>
          </div>

          <div className="flex space-x-4">
            <div className="flex-grow">
              <textarea
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                placeholder="Add a note..."
                rows={3}
                className="w-full p-3 border rounded-lg focus:ring-2 ring-yellow-400"
              />
            </div>
            <button
              onClick={addNote}
              disabled={submitting || !newNote.trim()}
              className="flex-shrink-0 flex items-center px-4 bg-yellow-400 hover:bg-yellow-500 text-black rounded-lg disabled:opacity-50"
            >
              <Send className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
import { checkNetworkConnectivity } from './auth/network';

interface RetryOptions {
  maxAttempts?: number;
  baseDelay?: number;
  maxDelay?: number;
  timeout?: number;
  onRetry?: (attempt: number, error: Error) => void;
}

const DEFAULT_OPTIONS: Required<RetryOptions> = {
  maxAttempts: 3,
  baseDelay: 1000,
  maxDelay: 10000,
  timeout: 10000,
  onRetry: () => {}
};

export async function withNetworkRetry<T>(
  operation: () => Promise<T>,
  options: RetryOptions = {}
): Promise<T> {
  const opts = { ...DEFAULT_OPTIONS, ...options };
  let attempt = 0;

  while (attempt < opts.maxAttempts) {
    try {
      // Check network connectivity first
      const isConnected = await checkNetworkConnectivity();
      if (!isConnected) {
        throw new Error('No network connection - please check your internet connection and try again');
      }

      // Execute operation with timeout
      const result = await Promise.race([
        operation(),
        new Promise<never>((_, reject) => {
          setTimeout(() => reject(new Error('Operation timed out')), opts.timeout);
        })
      ]);

      return result;
    } catch (error) {
      attempt++;
      const isLastAttempt = attempt === opts.maxAttempts;

      if (isLastAttempt) {
        throw error;
      }

      // Calculate delay with exponential backoff and jitter
      const jitter = Math.random() * 0.3 * opts.baseDelay;
      const delay = Math.min(
        opts.baseDelay * Math.pow(2, attempt - 1) + jitter,
        opts.maxDelay
      );

      // Notify of retry
      opts.onRetry(attempt, error instanceof Error ? error : new Error('Unknown error'));

      // Wait before retrying
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }

  throw new Error('Operation failed after multiple attempts - please try again');
}
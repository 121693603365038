import React from 'react';
import { Check, AlertCircle } from 'lucide-react';

interface ImageUsageIndicatorProps {
  usage: string[] | undefined;
}

export default function ImageUsageIndicator({ usage }: ImageUsageIndicatorProps) {
  if (!usage?.length) {
    return (
      <div className="flex items-center space-x-1 text-gray-500 text-xs">
        <AlertCircle className="h-3 w-3" />
        <span>Unused</span>
      </div>
    );
  }

  return (
    <div className="flex items-center space-x-1 text-green-600 text-xs">
      <Check className="h-3 w-3" />
      <span>
        {usage.length === 1 
          ? usage[0]
          : `Used in ${usage.length} places`}
      </span>
    </div>
  );
}
import React, { useState } from 'react';
import { format, subDays, subMonths, subYears, startOfMonth, endOfMonth, startOfYear, endOfYear, isWithinInterval } from 'date-fns';
import { formatPrice, calculateTax } from '../../../utils/currency';
import { useSite } from '../../../context/SiteContext';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Order } from '../../../types/order';

type ViewType = 'daily' | 'monthly' | 'yearly';

interface OrderAnalyticsProps {
  orders: Order[];
}

interface DailyStats {
  date: string;
  orders: number;
  cancellations: number;
  refunds: number;
  returns: number;
}

export default function OrderAnalytics({ orders }: OrderAnalyticsProps) {
  const { currency } = useSite();
  const [viewType, setViewType] = useState<ViewType>('daily');
  
  const getStats = () => {
    switch (viewType) {
      case 'monthly':
        return Array.from({ length: 12 }, (_, i) => {
          const date = subMonths(new Date(), i);
          const start = startOfMonth(date);
          const end = endOfMonth(date);
          
          const ordersInPeriod = orders.filter(order => {
            const orderDate = new Date(order.createdAt);
            return isWithinInterval(orderDate, { start, end });
          });
          
          return {
            date: format(date, 'MMM yyyy'),
            orders: ordersInPeriod.filter(order => 
              !['cancelled', 'refunded', 'returned'].includes(order.status)
            ).length,
            cancellations: ordersInPeriod.filter(order => 
              order.status === 'cancelled'
            ).length,
            refunds: ordersInPeriod.filter(order => 
              order.status === 'refunded'
            ).length,
            returns: ordersInPeriod.filter(order => 
              order.status === 'returned'
            ).length
          };
        }).reverse();

      case 'yearly':
        return Array.from({ length: 5 }, (_, i) => {
          const date = subYears(new Date(), i);
          const start = startOfYear(date);
          const end = endOfYear(date);
          
          const ordersInPeriod = orders.filter(order => {
            const orderDate = new Date(order.createdAt);
            return isWithinInterval(orderDate, { start, end });
          });
          
          return {
            date: format(date, 'yyyy'),
            orders: ordersInPeriod.filter(order => 
              !['cancelled', 'refunded', 'returned'].includes(order.status)
            ).length,
            cancellations: ordersInPeriod.filter(order => 
              order.status === 'cancelled'
            ).length,
            refunds: ordersInPeriod.filter(order => 
              order.status === 'refunded'
            ).length,
            returns: ordersInPeriod.filter(order => 
              order.status === 'returned'
            ).length
          };
        }).reverse();

      default: // daily
        return Array.from({ length: 30 }, (_, i) => {
          const date = subDays(new Date(), i);
          const ordersForDay = orders.filter(order => 
            format(new Date(order.createdAt), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd')
          );
          
          return {
            date: format(date, 'MMM d'),
            orders: ordersForDay.filter(order => 
              !['cancelled', 'refunded', 'returned'].includes(order.status)
            ).length,
            cancellations: ordersForDay.filter(order => 
              order.status === 'cancelled'
            ).length,
            refunds: ordersForDay.filter(order => 
              order.status === 'refunded'
            ).length,
            returns: ordersForDay.filter(order => 
              order.status === 'returned'
            ).length
          };
        }).reverse();
    }
  };

  const stats = getStats();

  // Calculate payment method distribution
  const paymentMethods = orders.reduce((acc, order) => {
    const method = order.payment.method;
    acc[method] = (acc[method] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  const paymentMethodData = Object.entries(paymentMethods).map(([name, count]) => ({
    name,
    count
  }));

  // Calculate key metrics
  const last30Days = {
    start: subDays(new Date(), 30),
    end: new Date()
  };

  const recentOrders = orders.filter(order => 
    isWithinInterval(new Date(order.createdAt), last30Days)
  );

  const metrics = {
    totalOrders: recentOrders.length,
    totalValue: recentOrders.reduce((sum, order) => sum + order.total, 0),
    averageOrder: recentOrders.length > 0 
      ? (recentOrders.reduce((sum, order) => sum + order.total, 0) / recentOrders.length)
      : 0, 
    refundRate: recentOrders.length > 0
      ? (recentOrders.filter(order => order.status === 'refunded').length / recentOrders.length) * 100
      : 0
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <div className="space-y-6">
      {/* Key Metrics */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-sm font-medium text-gray-500">Total Orders</h3>
          <p className="mt-2 text-3xl font-bold text-gray-900">
            {metrics.totalOrders}
          </p>
          <p className="mt-1 text-sm text-gray-500">Last 30 days</p>
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-sm font-medium text-gray-500">Total Value</h3>
          <p className="mt-2 text-3xl font-bold text-gray-900">
            {formatPrice(metrics.totalValue, currency)}
          </p>
          <p className="mt-1 text-sm text-gray-500">Last 30 days</p>
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-sm font-medium text-gray-500">Average Order</h3>
          <p className="mt-2 text-3xl font-bold text-gray-900">
            {formatPrice(metrics.averageOrder, currency)}
          </p>
          <p className="mt-1 text-sm text-gray-500">Last 30 days</p>
        </div>
        
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-sm font-medium text-gray-500">Refund Rate</h3>
          <p className="mt-2 text-3xl font-bold text-gray-900">
            {metrics.refundRate.toFixed(1)}%
          </p>
          <p className="mt-1 text-sm text-gray-500">Last 30 days</p>
        </div>
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        {/* Daily Orders Chart */}
        <div className="lg:col-span-2 bg-white p-6 rounded-lg shadow-sm">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg font-medium text-gray-900">Order Statistics</h3>
            <div className="flex space-x-2">
              <button
                onClick={() => setViewType('daily')}
                className={`px-3 py-1 rounded-lg text-sm ${
                  viewType === 'daily'
                    ? 'bg-yellow-400 text-black'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                Daily
              </button>
              <button
                onClick={() => setViewType('monthly')}
                className={`px-3 py-1 rounded-lg text-sm ${
                  viewType === 'monthly'
                    ? 'bg-yellow-400 text-black'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                Monthly
              </button>
              <button
                onClick={() => setViewType('yearly')}
                className={`px-3 py-1 rounded-lg text-sm ${
                  viewType === 'yearly'
                    ? 'bg-yellow-400 text-black'
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                Yearly
              </button>
            </div>
          </div>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={stats} stackOffset="sign">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="orders"
                  fill="#22c55e"
                  name="Orders"
                  stackId="stack"
                />
                <Bar
                  dataKey="cancellations"
                  fill="#ef4444"
                  name="Cancellations"
                  stackId="stack"
                />
                <Bar
                  dataKey="refunds"
                  fill="#ec4899"
                  name="Refunds"
                  stackId="stack"
                />
                <Bar
                  dataKey="returns"
                  fill="#3b82f6"
                  name="Returns"
                  stackId="stack"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {/* Payment Methods Chart */}
        <div className="bg-white p-6 rounded-lg shadow-sm">
          <h3 className="text-lg font-medium text-gray-900 mb-4">
            Payment Methods
          </h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={paymentMethodData} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" />
                <Tooltip />
                <Bar dataKey="count" fill="#f59e0b" name="Count" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}
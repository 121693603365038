import React, { useState, useEffect } from 'react';
import { Save, AlertCircle } from 'lucide-react';
import { db } from '../../config/firebase';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import { getSiteSettings, updateSiteName, updateCurrency, updateTaxRate, updateShippingThreshold } from '../../utils/settings';
import type { SiteSettings as Settings } from '../../types/settings';

export default function SiteSettings() {
  const { userProfile } = useAuth();
  const [settings, setSettings] = useState<Settings | null>(null);
  const [formState, setFormState] = useState({
    siteName: '',
    currency: 'USD' as const,
    taxRate: 20,
    shippingThreshold: 100,
    cartEnabled: true,
    cartOptions: {
      showPrices: true,
      showStock: true,
      showAddToCart: true,
      showBuyNow: true,
      showShipping: true,
      showRatings: true,
      showProductLink: true,
      productLinkText: 'View Details'
    }
  });
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    async function fetchSettings() {
      try {
        const settingsRef = doc(db, 'settings', 'site_settings');
        const settingsSnap = await getDoc(settingsRef);

        if (settingsSnap.exists()) {
          const data = settingsSnap.data();
          const settingsData = {
            siteName: data.site_name || 'Blackjack Productions Limited',
            currency: data.currency || 'USD',
            tax: {
              rate: data.tax_rate || 20,
              inclusive: data.tax_inclusive || true,
              lastModified: data.last_modified,
              modifiedBy: data.modified_by
            },
            shipping: {
              freeThreshold: data.shipping_free_threshold || 100,
              lastModified: data.last_modified,
              modifiedBy: data.modified_by
            },
            cartSystem: {
              enabled: data.cart_enabled ?? true,
              options: data.cart_options || {
                showPrices: true,
                showStock: true,
                showAddToCart: true,
                showBuyNow: true,
                showShipping: true,
                showRatings: true,
                showProductLink: true,
                productLinkText: 'View Details'
              },
              lastModified: data.last_modified,
              modifiedBy: data.modified_by
            },
            lastModified: data.last_modified,
            modifiedBy: data.modified_by
          };
          
          setSettings(settingsData);
          setFormState({
            siteName: settingsData.siteName,
            currency: settingsData.currency,
            taxRate: settingsData.tax.rate,
            shippingThreshold: settingsData.shipping.freeThreshold,
            cartEnabled: settingsData.cartSystem.enabled,
            cartOptions: settingsData.cartSystem.options
          });
        }
      } catch (err) {
        console.error('Error fetching settings:', err);
        setError('Failed to load settings');
      }
    }
    fetchSettings();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!userProfile) return;

    setIsSaving(true);
    setError(null);
    setSuccess(false);

    try {
      const settingsRef = doc(db, 'settings', 'site_settings');
      await updateDoc(settingsRef, {
        site_name: formState.siteName,
        currency: formState.currency,
        tax_rate: formState.taxRate,
        shipping_free_threshold: formState.shippingThreshold,
        cart_enabled: formState.cartEnabled,
        cart_options: formState.cartOptions,
        last_modified: new Date().toISOString(),
        modified_by: userProfile.email
      });

      setSuccess(true);
      setTimeout(() => setSuccess(false), 3000);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update settings');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="max-w-2xl">
      <h2 className="text-xl font-bold mb-6">Site Settings</h2>
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Site Name
          </label>
          <input
            type="text"
            value={formState.siteName}
            onChange={(e) => setFormState({ ...formState, siteName: e.target.value })}
            className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
            maxLength={50}
            required
          />
          <p className="text-sm text-gray-500 mt-1">
            This name will be displayed across all pages
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Currency
          </label>
          <select
            value={formState.currency}
            onChange={(e) => setFormState({ ...formState, currency: e.target.value as 'USD' | 'GBP' | 'EUR' })}
            className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
          >
            <option value="USD">US Dollar ($)</option>
            <option value="GBP">British Pound (£)</option>
            <option value="EUR">Euro (€)</option>
          </select>
          <p className="text-sm text-gray-500 mt-1">
            Select the currency to display across the site
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            VAT/Tax Rate (%)
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="number"
              value={formState.taxRate}
              onChange={(e) => setFormState({ ...formState, taxRate: Math.min(100, Math.max(0, Number(e.target.value))) })}
              className="w-32 p-2 border rounded-lg focus:ring-2 ring-yellow-400"
              min="0"
              max="100"
              step="0.1"
            />
            <span className="text-sm text-gray-500">%</span>
          </div>
          <p className="text-sm text-gray-500 mt-1">
            All prices in the system include VAT/Tax at this rate
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Free Shipping Threshold ({formState.currency})
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="number"
              value={formState.shippingThreshold}
              onChange={(e) => setFormState({ ...formState, shippingThreshold: Math.max(0, Number(e.target.value)) })}
              className="w-32 p-2 border rounded-lg focus:ring-2 ring-yellow-400"
              min="0"
              step="0.01"
            />
          </div>
          <p className="text-sm text-gray-500 mt-1">
            Orders above this amount will qualify for free shipping
          </p>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Cart System
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              checked={formState.cartEnabled}
              onChange={(e) => setFormState({
                ...formState,
                cartEnabled: e.target.checked,
                cartOptions: e.target.checked ? formState.cartOptions : {
                  showPrices: false,
                  showStock: false,
                  showAddToCart: false,
                  showBuyNow: false,
                  showShipping: false,
                  showRatings: false,
                  showProductLink: false,
                  productLinkText: 'View Details'
                }
              })}
              className="h-4 w-4 text-yellow-400 focus:ring-yellow-400 border-gray-300 rounded"
            />
            <span className="text-sm text-gray-600">Enable shopping cart and checkout</span>
          </div>
          <p className="text-sm text-gray-500 mt-1">
            When disabled, products will be displayed in catalog mode without purchase options
          </p>
          
          {formState.cartEnabled && (
            <div className="mt-4 pl-6 space-y-2">
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formState.cartOptions.showPrices}
                  onChange={(e) => setFormState({
                    ...formState,
                    cartOptions: { ...formState.cartOptions, showPrices: e.target.checked }
                  })}
                  className="h-4 w-4 text-yellow-400 focus:ring-yellow-400 border-gray-300 rounded"
                />
                <span className="text-sm text-gray-600">Show product prices</span>
              </div>
              
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formState.cartOptions.showStock}
                  onChange={(e) => setFormState({
                    ...formState,
                    cartOptions: { ...formState.cartOptions, showStock: e.target.checked }
                  })}
                  className="h-4 w-4 text-yellow-400 focus:ring-yellow-400 border-gray-300 rounded"
                />
                <span className="text-sm text-gray-600">Show stock status</span>
              </div>
              
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formState.cartOptions.showAddToCart}
                  onChange={(e) => setFormState({
                    ...formState,
                    cartOptions: { ...formState.cartOptions, showAddToCart: e.target.checked }
                  })}
                  className="h-4 w-4 text-yellow-400 focus:ring-yellow-400 border-gray-300 rounded"
                />
                <span className="text-sm text-gray-600">Show "Add to Cart" button</span>
              </div>
              
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formState.cartOptions.showBuyNow}
                  onChange={(e) => setFormState({
                    ...formState,
                    cartOptions: { ...formState.cartOptions, showBuyNow: e.target.checked }
                  })}
                  className="h-4 w-4 text-yellow-400 focus:ring-yellow-400 border-gray-300 rounded"
                />
                <span className="text-sm text-gray-600">Show "Buy Now" button</span>
              </div>
              
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formState.cartOptions.showShipping}
                  onChange={(e) => setFormState({
                    ...formState,
                    cartOptions: { ...formState.cartOptions, showShipping: e.target.checked }
                  })}
                  className="h-4 w-4 text-yellow-400 focus:ring-yellow-400 border-gray-300 rounded"
                />
                <span className="text-sm text-gray-600">Show shipping information</span>
              </div>
              
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formState.cartOptions.showRatings}
                  onChange={(e) => setFormState({
                    ...formState,
                    cartOptions: { ...formState.cartOptions, showRatings: e.target.checked }
                  })}
                  className="h-4 w-4 text-yellow-400 focus:ring-yellow-400 border-gray-300 rounded"
                />
                <span className="text-sm text-gray-600">Show product ratings</span>
              </div>
              
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formState.cartOptions.showProductLink}
                  onChange={(e) => setFormState({
                    ...formState,
                    cartOptions: { ...formState.cartOptions, showProductLink: e.target.checked }
                  })}
                  className="h-4 w-4 text-yellow-400 focus:ring-yellow-400 border-gray-300 rounded"
                />
                <span className="text-sm text-gray-600">Show product link button</span>
              </div>

              {formState.cartOptions.showProductLink && (
                <div className="mt-2 pl-6">
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Product Link Button Text
                  </label>
                  <input
                    type="text"
                    value={formState.cartOptions.productLinkText}
                    onChange={(e) => setFormState({
                      ...formState,
                      cartOptions: { ...formState.cartOptions, productLinkText: e.target.value }
                    })}
                    className="w-full p-2 border rounded-lg focus:ring-2 ring-yellow-400"
                    placeholder="e.g., View Details"
                  />
                </div>
              )}
            </div>
          )}
        </div>

        <div className="flex items-center justify-between">
          <div className="text-sm text-gray-500">
            Last modified: {settings?.lastModified ? new Date(settings.lastModified).toLocaleString() : 'Never'}
            {settings?.modifiedBy && (
              <span className="ml-1">by {settings.modifiedBy}</span>
            )}
          </div>
          
          <button
            type="submit"
            disabled={isSaving || (
              formState.siteName === settings?.siteName &&
              formState.currency === settings?.currency &&
              formState.taxRate === settings?.tax?.rate &&
              formState.shippingThreshold === settings?.shipping?.freeThreshold &&
              formState.cartEnabled === settings?.cartSystem?.enabled &&
              JSON.stringify(formState.cartOptions) === JSON.stringify(settings?.cartSystem?.options)
            )}
            className={`px-4 py-2 rounded-lg flex items-center space-x-2 ${
              isSaving || (
                formState.siteName === settings?.siteName && 
                formState.currency === settings?.currency &&
                formState.taxRate === settings?.tax?.rate &&
                formState.shippingThreshold === settings?.shipping?.freeThreshold &&
                formState.cartEnabled === settings?.cartSystem?.enabled &&
                JSON.stringify(formState.cartOptions) === JSON.stringify(settings?.cartSystem?.options)
              )
                ? 'bg-gray-300 cursor-not-allowed'
                : 'bg-yellow-400 hover:bg-yellow-500'
            }`}
          >
            <Save className="h-5 w-5" />
            <span>{isSaving ? 'Saving...' : 'Save Changes'}</span>
          </button>
        </div>

        {error && (
          <div className="bg-red-50 text-red-700 p-4 rounded-lg flex items-start space-x-2">
            <AlertCircle className="h-5 w-5 mt-0.5" />
            <span>{error}</span>
          </div>
        )}

        {success && (
          <div className="bg-green-50 text-green-700 p-4 rounded-lg flex items-start space-x-2">
            <AlertCircle className="h-5 w-5 mt-0.5" />
            <span>Settings updated successfully</span>
          </div>
        )}
      </form>
    </div>
  );
}
import { auth } from '../config/firebase';

interface RetryConfig {
  maxAttempts: number;
  baseDelay: number;
  maxDelay: number;
  timeout?: number;
}

const DEFAULT_CONFIG: RetryConfig = {
  maxAttempts: 3,
  baseDelay: 1000,
  maxDelay: 5000,
  timeout: 10000
};

export async function withRetry<T>(
  operation: () => Promise<T>,
  config: Partial<RetryConfig> = {}
): Promise<T> {
  const finalConfig = { ...DEFAULT_CONFIG, ...config };
  let attempt = 0;
  let lastError: Error | null = null;

  while (attempt < finalConfig.maxAttempts) {
    try {
      if (auth.currentUser) {
        await auth.currentUser.getIdToken(true);
      }

      const controller = new AbortController();
      const timeoutId = setTimeout(() => {
        controller.abort();
      }, finalConfig.timeout);

      try {
        const result = await operation();
        clearTimeout(timeoutId);
        return result;
      } catch (error) {
        clearTimeout(timeoutId);
        throw error;
      }
    } catch (error) {
      attempt++;
      lastError = error instanceof Error ? error : new Error('Unknown error');

      if (attempt === finalConfig.maxAttempts) {
        throw lastError;
      }

      // Calculate delay with exponential backoff and jitter
      const jitter = Math.random() * 0.3 * finalConfig.baseDelay;
      const delay = Math.min(
        finalConfig.baseDelay * Math.pow(2, attempt - 1) + jitter,
        finalConfig.maxDelay
      );

      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }

  throw lastError || new Error('Max retry attempts exceeded');
}
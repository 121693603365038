import React from 'react';
import { db } from '../../../config/firebase';
import { doc, getDoc } from 'firebase/firestore';
import { Order } from '../../../types/order';
import { useSite } from '../../../context/SiteContext';
import { formatPrice } from '../../../utils/currency';
import { calculateTaxBreakdown, getTaxRate } from '../../../utils/tax';
import PaymentDetails from './PaymentDetails';

interface OrderDetailsProps {
  order: Order;
}

export default function OrderDetails({ order }: OrderDetailsProps) {
  const { currency, settings } = useSite();
  const taxRate = getTaxRate(settings);
  // Use the order's stored values directly
  const baseAmount = order.subtotal;
  const taxAmount = order.tax;
  const shippingAmount = order.shippingCost;
  const totalAmount = order.total; 
  
  const getStatusColor = (status: string) => {
    const colors = {
      pending: 'bg-yellow-100 text-yellow-800',
      processing: 'bg-blue-100 text-blue-800',
      shipped: 'bg-purple-100 text-purple-800',
      delivered: 'bg-green-100 text-green-800',
      cancelled: 'bg-red-100 text-red-800',
      refunded: 'bg-gray-100 text-gray-800'
    };
    return colors[status as keyof typeof colors] || 'bg-gray-100 text-gray-800';
  };

  return (
    <div className="bg-white rounded-lg shadow-sm">
      {/* Order Status */}
      <div className="p-6 border-b">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold">Order Status</h2>
          <span className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusColor(order.status)}`}>
            {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
          </span>
        </div>
      </div>

      {/* Order Items */}
      <div className="p-6 border-b">
        <h3 className="text-lg font-semibold mb-4">Order Items</h3>
        <div className="space-y-4">
          {order.items.map((item) => (
            <div key={item.id} className="flex items-center space-x-4">
              <div className="flex-shrink-0">
                <Package className="h-10 w-10 text-gray-400" />
              </div>
              <div className="flex-grow">
                <h4 className="font-medium">{item.title}</h4>
                <p className="text-sm text-gray-500">
                  Quantity: {item.quantity} × {formatPrice(item.price, currency)}
                </p>
              </div>
              <div className="text-right">
                <p className="font-medium">
                  {formatPrice(item.subtotal, currency)}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Order Summary */}
      <div className="p-6">
        <div className="space-y-2">
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Subtotal</span>
            <span>{formatPrice(baseAmount, currency)}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">VAT/Tax ({taxRate}%)</span>
            <span>{formatPrice(taxAmount, currency)}</span>
          </div>
          <div className="flex justify-between text-sm">
            <span className="text-gray-600">Shipping</span>
            {shippingAmount === 0 ? (
              <span className="text-green-600">Free Shipping Applied</span>
            ) : (
              <span>{formatPrice(shippingAmount, currency)}</span>
            )}
          </div>
          <div className="border-t pt-2 mt-2">
            <div className="flex justify-between font-medium">
              <span>Total</span>
              <span>{formatPrice(totalAmount, currency)}</span>
            </div>
          </div>
        </div>
      </div>

      {/* Payment Details */}
      <div className="p-6 border-t">
        <PaymentDetails order={order} />
      </div>

      {/* Customer Information */}
      <div className="p-6 border-t">
        <h3 className="text-lg font-semibold mb-4">Customer Information</h3>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <h4 className="font-medium mb-2">Shipping Address</h4>
            <p className="text-sm text-gray-600">
              {order.shippingAddress.firstName} {order.shippingAddress.lastName}<br />
              {order.shippingAddress.address}
              {order.shippingAddress.address2 && (
                <><br />{order.shippingAddress.address2}</>
              )}
              {order.shippingAddress.address3 && (
                <><br />{order.shippingAddress.address3}</>
              )}<br />
              {order.shippingAddress.postcode}<br />
              {order.shippingAddress.country}
            </p>
          </div>
          <div>
            <h4 className="font-medium mb-2">Contact Information</h4>
            <p className="text-sm text-gray-600">
              {order.customerName}<br />
              {order.customerEmail}<br />
              Tel: {order.shippingAddress.telephone || 'Not provided'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}